import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Box, CircularProgress, Typography } from '@mui/material';
import SearchBar from "material-ui-search-bar";
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { updateSnackbar } from "../../_redux/SnackbarSlice";
import Pagination from '../../components/DynamicTable/Pagination';
import './AssociateCards.css';



const AssociateCards = ({ data, selectedItems, onReceiveHandler, componentType, navigateTo, config, searchKeyWord }) => {

  const [jobData, setjobData] = useState();
  const [searchValue, setSearchValue] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const jobRawData = JSON.stringify(data);
  const [aiSearchData, setAiSearchData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 8;
  const [totalRecords, setTotalRecords] = useState(data.length);
  const [isNoData, setIsNoData] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedItems.length > 0) {
      let Ids = []
      selectedItems.forEach(element => {
        if (element) {
          Ids.push(parseInt(element))
        }
      });
      setSelectedIds(Ids);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (data && data.length > 0) {
      setjobData(data.slice(0, (currentPage * usersPerPage)));
      setTotalRecords(data.length);
    } else {
      setjobData("");
      if (searchValue) {
        setIsNoData(true);
      }
    }
  }, [data])


  const handleActionClick = (obj) => {
    let DataNew
    if (aiSearchData) {
      DataNew = aiSearchData;
    } else {
      DataNew = JSON.parse(jobRawData);
    }
    let currentpage = (obj - 1);
    let Data = DataNew.slice(((currentpage * usersPerPage)), ((obj * usersPerPage)))
    setjobData(Data);
    setCurrentPage(obj);
  };

  const onSelectHandler = (item) => {
    let dataArray = JSON.parse(JSON.stringify(selectedIds));
    let findIndex = dataArray.findIndex(x => x === item.id);
    if (findIndex !== -1) {
      dataArray.splice(findIndex, 1);
      if (componentType === "AssociatedJobs") {
        let obj = { id: parseInt(item.id), jobCode: item.jobCode }
        onReceiveHandler(obj, 'delete');
      }
      else if (componentType === "AssociatedApplicants") {
        let obj = { id: parseInt(item.id), applicantCode: item.applicantCode }
        onReceiveHandler(obj, 'delete');
      }
    } else {
      dataArray.push(parseInt(item.id));
      if (componentType === "AssociatedJobs") {
        let obj = { id: parseInt(item.id), jobCode: item.jobCode }
        onReceiveHandler(obj, 'add');
      }
      else if (componentType === "AssociatedApplicants") {
        let obj = { id: parseInt(item.id), applicantCode: item.applicantCode }
        onReceiveHandler(obj, 'add');
      }
    }
    setSelectedIds(dataArray);
  }

  const onSearchhandler = (value, type) => {
    value = value.trimStart();
    if (value.length < 2 && type === "onRequestSearch") {
      dispatch(updateSnackbar({
        type: "error",
        message: "Required Minimum 2 Characters"
      }));
    }
    if (config.isSearchServer === false) {
      const rawData = JSON.parse(jobRawData);
      if (value !== "") {
        value = value.trimStart();
        setSearchValue(value);
        if (rawData) {
          if (value.length > 1) {
            let data1 = rawData.filter((x) =>
              Object.keys(x).some((k) => String(x[k]).toLowerCase().includes(value.toLowerCase()))
            );
            if (data1.length > 0) {
              // setjobData(data1);
              setAiSearchData(data1)
              setIsNoData(false);
              setTotalRecords(data1.length);
              setjobData(data1.slice(0, (usersPerPage)));
            } else {
              setjobData("");
              setIsNoData(true);
            }
          }
          else {
            setAiSearchData();
            setjobData(rawData.slice(0, (usersPerPage)));  // setjobData(rawData);
            setTotalRecords(rawData.length);
            setIsNoData(true);
          }
          setCurrentPage(1);
        }
      } else if (value === "" && type === "onCancelSearch") {
        setCurrentPage(1);
        setAiSearchData();
        setjobData(rawData.slice(0, (usersPerPage)));  // setjobData(rawData);
        setTotalRecords(rawData.length);
        setIsNoData(false);
      }
    } else if (config.isSearchServer === true) {
      if (value !== "" && value.length >= 2) {
        value = value.trimStart();
        setSearchValue(value);
        setCurrentPage(1);
        setIsNoData(false);
        onReceiveHandler({ keyword: value }, 'searchResult');
      } else if (value === "") {
        setIsNoData(false);
        setjobData([]);
        setCurrentPage(1);
      }
    }
  }

  useEffect(() => {
    console.log('jobData ++++++++++++++++++++++', JSON.stringify(jobData))
  }, [jobData])

  
  const onNavigeteInNew = (object) => {
    console.log("object ++++++++++++++++++++=", object);
    let Name = "", type = '';
    if(object.applicantCode){
      Name = object.firstName + " " + object.lastName;
      type = "Applicant Code";
    }
    else if(object.jobCode){
      Name = `${object.jobTitle} (${object.jobCode})`;
      type = "Job Code";
    }
      let newobj = { id: object.id, name : Name, type : type };
      let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
      if(subPathObj && subPathObj.length > 0){
        let index = subPathObj.findIndex(x => x.id === newobj.id);
        if(index !== -1){
          subPathObj[index] = newobj;
        } else {
          subPathObj.push(newobj);
        }
      } else {
        subPathObj = [newobj];
      }
      localStorage.setItem("subpathObj", JSON.stringify(subPathObj));
      window.open(navigateTo + object.id, '_blank')
  }

  return (
    <>
      <div className="resultHeader">
        <SearchBar
          className="rightnavsearchbar"
          placeholder=
          {searchKeyWord}
          //Search by JobCode, Title
          // value={searchValue !== null ? searchValue : ''}
          onChange={(newValue) => onSearchhandler(newValue, "search")}
          onRequestSearch={(newValue) =>
            // onSearchhandler(newValue.slice(0, 8), "onRequestSearch")
            onSearchhandler(newValue, "onRequestSearch")
          }
          onCancelSearch={() => onSearchhandler("", "onCancelSearch")}
        />
      </div>
      {jobData !== null && jobData !== undefined && jobData.length > 0 ?
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ color: "#3B4046", fontWeight: "700", fontSize: "20px" }}>Results</p>
          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={usersPerPage}
            onPageChange={(page) => handleActionClick(page)}
          />
        </div>
        : ''}
      <div className="innerdiv">
        {jobData !== null && jobData !== undefined && jobData.length > 0 && (
          <>
            {jobData.map((elem) => {
              return (
                <>
                  <aside style={{ position: "relative" }} className={selectedIds.includes(parseInt(elem.id)) ? "active" : ''}>
                    {componentType === "AssociatedJobs" ?
                      <>
                        {/* <div title={elem.jobTitle} onClick={() => onSelectHandler(elem)}>{elem.jobTitle}</div>
                        <div onClick={() => onSelectHandler(elem)} className={componentType === "AssociatedJobs" ? "cardsforassjobs" : " "}>
                          <p title={elem.clientName}><PersonIcon />{elem.clientName}</p>
                          <p title={elem.jobCode}><CodeIcon /> {elem.jobCode}</p>
                          <p title={elem.location}><LocationOnIcon />{elem.location}</p>
                          <div style={{ display: "flex", justifyContent: "end", gap: "5px", marginTop: "-85px", position: "absolute", right: "9px", zIndex: 1, flexDirection: "column" }}>
                            <Link to={navigateTo + elem.id} target='_blank' style={{ marginTop: "-4px" }} type="button">
                              <OpenInNewIcon />
                            </Link>
                            {selectedIds.includes(elem.id) ? <CheckCircleIcon /> : <PanoramaFishEyeIcon />}
                          </div>
                        </div> */}
                        <div title={elem.jobTitle} onClick={() => onSelectHandler(elem)}>{elem.jobTitle}</div>
                        <div onClick={() => onSelectHandler(elem)} className={componentType === "AssociatedJobs" ? "cardsforassjobs" : " "}>
                          <p title={elem.clientName}>{elem.clientName}</p>
                          <p title={elem.jobCode}> {elem.jobCode}</p>
                          <p title={elem.location}>{elem.location}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-32px", height: "15px", alignItems: "baseline" }}>
                          <Link onClick={(e) => { onNavigeteInNew(elem) }} style={{ marginLeft: "20px", marginTop: "-4px" }} type="button">
                            <OpenInNewIcon />
                          </Link>
                          <div onClick={() => onSelectHandler(elem)}>
                            {selectedIds.includes(parseInt(elem.id)) ? <CheckCircleIcon /> : <PanoramaFishEyeIcon />}
                          </div>
                        </div>
                      </>
                      : componentType === "AssociatedApplicants" ? <>
                        {elem.firstName && (<div className="cardbgcolor" title={`${elem.firstName} ${elem.lastName}`}>{elem.firstName} {elem.lastName}
                        </div>)}
                        <div>
                          {elem.jobTitle && (<div title={elem.jobTitle}>{elem.jobTitle}</div>)}
                          {elem.applicantCode && (<p onClick={() => onSelectHandler(elem)} title={elem.applicantCode}> {elem.applicantCode}
                            <div style={{ marginTop: "12px", alignItems: "flex-end" }}>
                              {elem.score ?
                                <div className="mathCards">
                                  <Box position="relative" display="inline-flex">
                                    <CircularProgress variant="determinate" value={elem.score * 100} />
                                    <Box
                                      top={0}
                                      left={0}
                                      bottom={0}
                                      right={0}
                                      position="absolute"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Typography variant="caption">{`${Math.round(elem.score * 100)}%`}</Typography>
                                    </Box>
                                  </Box>

                                </div>
                                : ''}

                            </div>
                          </p>)}
                          <div onClick={() => onSelectHandler(elem)}>
                            {elem.contactNumber && (<p title={elem.contactNumber}> {elem.contactNumber}</p>)}
                            {elem.emailId && (<p title={elem.emailId}> {elem.emailId}
                            </p>)}

                          </div>

                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Link onClick={(e) => { onNavigeteInNew(elem) }} style={{ marginLeft: "10px" }} type="button">
                              <OpenInNewIcon />
                            </Link>
                            <div onClick={() => onSelectHandler(elem)}>

                              {selectedIds.includes(parseInt(elem.id))
                                ? <CheckCircleIcon /> : <PanoramaFishEyeIcon />}
                            </div>
                          </div>
                        </div>
                      </> : ''
                    }
                  </aside>
                </>
              );
            })
            }
          </>)}
      </div>
      {jobData === "" && isNoData && (
        <div className="nodatasection nodataSection">
          <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
          No search results found
        </div>
      )
      }
    </>
  )
}

export default AssociateCards;