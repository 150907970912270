import React, { useEffect, useState } from "react";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import GetJobId from "../../../_utilities/GetJobId";
import { useDispatch, useSelector } from "react-redux";
import RightSideBar from "../../../components/Rightsidebar/RightSideBar";
import Button from "@mui/material/Button";
import "./ApplicantDocuments.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { GetTimeZone } from '../../../_utilities/GetTimeZone'
import * as _ser from '../../../_redux/_services/ApplicantsSlice';
import { getUserDetails } from '../../../_redux/CognitoSlice';
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import { DocumentViewer } from 'react-documents';

const initalState = { data: null, message: '', isloader: true }

const ApplicantDocuments = () => {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);
  const [HandlerType, setHandlerType] = useState("");
  const [previewType, setPreviewType] = useState("");
  const [actionType, setActionType] = useState("");
  const [isValidUser,setIsValidUser ] = useState(false);

  const [isLoadDoc, setIsLoadDoc] = useState(false);
  const [config, setConfig] = useState(configuration);

  const JobId = GetJobId();

  useEffect(() => {
    if (JobId !== null) {
      FetchDoc();
    }
  }, [JobId]);

  useEffect(() => {
    if (isLoadDoc === true && JobId !== null) {
      FetchDoc();
      setIsLoadDoc(false);
    }
  }, [isLoadDoc]);

  const GetDocumentsdata = useSelector(state => state.GetDocuments.data);
  const GetDocumentscode = useSelector(state => state.GetDocuments.code);

  useEffect(()=>{
  if(GetDocumentscode === 404) {
    setIsValidUser(true);
  }
  },[GetDocumentscode])

  const FetchDoc = async () => {
    setDocuments([]);
    dispatch(_ser.getDocumentsAsync(JobId));
  };

  useEffect(() => {
    if (GetDocumentsdata !== null) {
      let docData = JSON.parse(JSON.stringify(GetDocumentsdata));
      docData.forEach((list) => {
        if (list.docs !== "") {
          list.docs = JSON.parse(list.docs);
        } else { return; }
      });
      let dataArray = [];
      if (docData.length > 0) {
        docData.forEach((e) => {
          if (typeof e.docs === "string") {
            e.docs = JSON.parse(e.docs);
          }
          const { docs } = e;
          dataArray.push(...docs);
        });
        dataArray.forEach((list) => {
          list.name = list.name.replaceAll("-", " ");
          list.date = GetTimeZone(list.date);
          list["total_count"] = dataArray.length;
        });
        let configure = JSON.parse(JSON.stringify(config))
        configure.totalRecords = dataArray.length;
        configure.currentPage = 1;
        setConfig(configure)
        setDocuments(dataArray.reverse());
        dispatch(_ser.getDocumentsStartReducer(initalState))
      } else {
        setDocuments([]);
      }
    }
  }, [GetDocumentsdata])

  const onReceiveActionHandler = async (type, object) => {
    if (type === "confirmDel") {
      DeleteDocuments(object);
    } else if (type === "Download" || type === "download") {
      DownloadFileFromS3(object, type)
    } else if (type === "view") {
      DownloadFileFromS3(object, type);
      setHandlerType(type);
      setIsRightSidePannel(true);
    }
  };

  const code = useSelector(state => state.DeleteDocuments.data);

  const DeleteDocuments = async (object) => {
    let obj = { file_ids: [object.id.toString()] };
    dispatch(_ser.deleteDocumentsAsync(JobId, obj));
  }

  useEffect(() => {
    if (code === 200) {
      FetchDoc();
      dispatch(_ser.deleteDocumentsStartReducer(initalState));
    }
  }, [code])

  const docData = useSelector(state => state.Downloaddocument.data);
  const [DocName, setDocName] = useState("")

  const DownloadFileFromS3 = async (object, type) => {
    type = (type === "view") ? "preview" : "download";
    dispatch(_ser.downloaddocumentAsync(JobId, object.docUrl, type));
    setActionType(type);
    setDocName(object.docUrl);
  }

  useEffect(() => {
    if (docData) {
      if (actionType === "Download" || actionType === "download") {
        const link = document.createElement('a');
        link.href = docData;
        link.download = DocName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setPreviewType(docData)
      }
      dispatch(_ser.downloaddocumentStartReducer(initalState));
    }
  }, [docData])

  useEffect(() => {
    console.log("documents", documents);
  }, [documents])

  const onReceivePropsHandler = (pannel, load, type) => {
    if(type !== ""){
      setIsRightSidePannel(pannel);
      setIsLoadDoc(load);
    } else {
      DownloadFileFromS3(pannel, load);
    }
  };

  return (
    <>
    { !isValidUser &&
      <div
        className="adddocumentsection"
        style={{ display: "flex", height: "34px", gap: "10px" }}

        onClick={() => {
          setIsRightSidePannel(true);
          setHandlerType("New document");
        }}
      >
        <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" />
        <h4 className="addnewdocumenttext"> New Document</h4>
      </div>
    }
      <div className="contentSection" style={{marginTop:"50px"}}>
        {documents.length > 0 && (
          <DynamicTable
            config={config}
            data={documents}
            colmns={columnsData}
            actionDropdown={actionsDropdownData}
            actions={actionsList}
            addModel={addNewModel}
            noDataText={"No Documents Found"}
            searchKeyWord="Search by Document Name"
            onReceiveActionProps={(type, obj) =>
              onReceiveActionHandler(type, obj)
            }
          />
        )}
        {documents.length === 0 && (
          <DynamicTable
            config={config}
            data={documents}
            colmns={columnsData}
            actionDropdown={actionsDropdownData}
            actions={actionsList}
            addModel={addNewModel}
            noDataText={"No Documents Found"}
            searchKeyWord="Search by Document Name"
            onReceiveActionProps={(type, obj) =>
              onReceiveActionHandler(type, obj)
            }
          />
        )}
      </div>
      {isRightSidePannel ? (
        <div id={HandlerType === "view" ? "documentViewer" : 'uploadviewer'}>
          <RightSideBar
            componentData={
              <ComponentRenderData
                JobId={JobId}
                handler={HandlerType}
                previewType={previewType}
                DocName={DocName}
                onReceivechildProps={onReceivePropsHandler}
              />

            }
            componentLayout={HandlerType === "view" ? pannelobjview : pannelobj}
            onReceiveProps={onReceivePropsHandler}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const ComponentRenderData = (props) => {
  const [imgList, setImgList] = useState([]);
  const [imgData, setImgData] = useState([]);
  const [isUploaded, setIsUploaded] = useState(true);
  const [disableSubmitBtn, setDisableSubmitBtn] = React.useState(false);
  const [docUrl, setDocUrl] = useState(null);

  const fileType = "jpg not supported";
  const UploadDocCode = useSelector(state => state.UploadDocuments.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.previewType !== "") {
      // let spliturl = props.previewType.split("?");
      // console.log("/////////////////", spliturl);
      setDocUrl(props.previewType)
    }
  }, [props]);

  useEffect(() => {
    if (imgData.length === 0 && imgList.length === 0) {
      setIsUploaded(true);
    }
  }, [imgData, imgList]);

  const uploadImgHandler = (files) => {
    let imgNames = JSON.parse(JSON.stringify(imgList));
    let imgObj = JSON.parse(JSON.stringify(imgData));
    let tempImgNames = [],
      tempImgObj = [];
    for (var i = 0; i < files.length; i++) {
      let obj = { id: i, name: files[i].name };
      tempImgNames.push(obj);
      tempImgObj.push(files[i]);
    }
    imgNames.push(...tempImgNames);
    imgObj.push(...tempImgObj);
    setImgList(imgNames);
    setIsUploaded(false);
    setImgData(imgObj);
  };

  const onRemoveItem = (item) => {
    let imgNames = JSON.parse(JSON.stringify(imgList));
    let findIndex = imgNames.findIndex((x) => x.id === item.id);
    if (findIndex !== -1) {
      imgNames.splice(findIndex, 1);
      imgData.splice(findIndex, 1);
      setImgList(imgNames);
    }
    imgData.splice(parseInt(item.id), 1);
    setImgData(imgData);
  };
  const fileImgHandler = (event) => {
    let files = event.target.files;
    let imgNames = JSON.parse(JSON.stringify(imgList));
    let imgObj = JSON.parse(JSON.stringify(imgData));
    var fileInput = document.getElementById('fileId');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;
    if (!allowedExtensions.exec(filePath)) {
      dispatch(updateSnackbar({ type: 'error', message: "Invalid file format. Please upload a Doc, Pdf, or Docx file" }));
      fileInput.value = '';
      return false;
    }
    else {
      let tempImgNames = [],
        tempImgObj = [];
      for (var i = 0; i < files.length; i++) {
        let obj = { id: i, name: files[i].name };
        tempImgNames.push(obj);
        tempImgObj.push(files[i]);
      }
      imgNames.push(...tempImgNames);
      imgObj.push(...tempImgObj);
      setImgList(imgNames);
      setIsUploaded(false);
      setImgData(imgObj);
    }
    event.target.value = '';
  };


  useEffect(() => {
    console.log("imgList", imgList);
  }, [imgList]);

  useEffect(() => {
    console.log("imgData", imgData);
  }, [imgData]);

  const uploadDocuments = async () => {
    setDisableSubmitBtn(true)

    const userInfo = await getUserDetails();
    let formData = new FormData();
    let checkFileType = imgData.filter((elem) => elem.name.match(/jpg/) || elem.name.match(/png/) || elem.name.match(/jpeg/) || elem.name.match(/JPEG/) || elem.name.match(/SVG/) || elem.name.match(/gif/));
    if (checkFileType.length > 0) {
      alert("file does not support");
    } else {
      for (let i = 0; i < imgData.length; i++) {
        formData.append("applicantFiles", imgData[i]);
      }
      formData.append("id", props.JobId);
      formData.append("userId", userInfo.userId);
      dispatch(_ser.uploadDocumentsAsync(props.JobId, formData, true)).then(() => {
        setDisableSubmitBtn(false)
      }).catch(() => {
        setDisableSubmitBtn(false)
      });
    }
  };

  useEffect(() => {
    if (UploadDocCode === 200) {
      dispatch(_ser.uploadDocumentsStartReducer(initalState))
      props.onReceivechildProps(false, true, null);
    }
  }, [UploadDocCode])

  const downloadHandler = () => {
    props.onReceivechildProps({docUrl:props.DocName}, "Download", "");
  }

  if (props.handler === "New document") {
    return (
      <>
        {isUploaded ? (
          <>
            <div className='uploadfileicon'>
              <img src={process.env.PUBLIC_URL + "/assests/img/uploadBgIcon.svg"} alt="" />
            </div>
            <Button
              variant="contained"
              component="label"
              className="appuploadresume"
              disabled={imgList.length > 0}
            >
              <span>
                <b style={{ color: "#FFD800" }}>Upload</b>
                <b style={{ color: "#9E9E9E" }}> browse file to upload </b></span>
              <input
                hidden
                accept="file/*"
                type="file"
                id="fileId"
                onChange={(e) => fileImgHandler(e)} />
            </Button>
            <p className="docsupporttext">Supported file formats: Doc, Pdf, Docx </p>
          </>
        ) : (
          ""
        )}
        {imgList.length > 0 ? (
          <ul className="listofUploads">
            {imgList &&
              imgList.map((list) => {
                return (
                  <li key={list.id}>
                    <p>{list.name}</p>
                    <p>{list.name.match(".jpg") || list.name.match(".png") ||
                      list.name.match(/jpg/) || list.name.match(/png/) || list.name.match(/jpeg/) ||
                      list.name.match(/JPEG/) || list.name.match(/SVG/)
                      ? fileType : ""}</p>

                    <span
                      className="closeImgbtn"
                      onClick={() => onRemoveItem(list)}
                    >
                      <DeleteOutlineIcon />
                    </span>
                  </li>
                );
              })}
          </ul>
        ) : (
          ""
        )}
        {/* {!isUploaded ? ( */}
        <div className="actionBtns">
          <Button
            type="button"
            className="cancelBtn"
            onClick={() => {
              props.onReceivechildProps(false, false, null);
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            className="saveBtn"
            onClick={uploadDocuments}
            disabled={imgList.length > 0 && !disableSubmitBtn ? false : true}
          >
            Save
          </Button>
        </div>
        {/* ) : (
        ""
      )} */}
      </>
    );
  } else if (props.handler === "view") {
    return (
      <>
        <div style={{ textAlign: "center" }}>
          {docUrl !== null ?
            <>
              <DocumentViewer
                url={docUrl}
                // viewerUrl={docUrl}
                style={{ width: "100%", height: "calc(100vh - 120px)" }}
              >
              </DocumentViewer>
              <div className="floatBtn" onClick={downloadHandler}>
                <svg id="a852fdaa-156c-46c0-a3b6-364630b4c7fc" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 637.22 637.22">
                  <g>
                    <circle cx="318.61" cy="318.61" r="318.61" style={{ fill: "#404040" }} />
                    <g>
                      <path d="M363.11,176.87c0,19.08,0,38.16,0,57.24.05,22.81,16.39,39.29,39.21,39.45,16.08.12,32.15,0,48.23,0h4.55v74.13c-4.44,0-8.83-.12-13.22,0C414.6,348.65,390.4,358,369.14,375a15.67,15.67,0,0,1-10.62,3.64c-34.63-.15-69.26-.08-103.89-.08h-2.75c-6.49,0-10.1,2.62-10.11,7.28s3.6,7.29,10.1,7.29q46.85,0,93.7,0h4.81c-1.05,1.64-1.77,2.67-2.4,3.75-4.75,8.23-9.63,16.4-14.14,24.76-1.23,2.29-2.35,3.15-5,3.14-24.7-.11-49.4-.07-74.1-.07h-3.53c-5.92,0-9.33,2.59-9.45,7.09s3.36,7.35,9.54,7.38c9.15,0,18.29,0,27.44,0h48.67c-6.34,27.06-4.53,53.06,5.91,78.74H226.65c-11.18,0-20.73-3.61-27.76-12.68-4.94-6.37-6.73-13.71-6.73-21.63q0-55.68,0-111.34,0-80.37,0-160.75c0-14,5.62-24.78,18.32-31.26,3.07-1.57,6.59-2.27,9.91-3.37Zm-68.61,171q21.74,0,43.48,0a18.39,18.39,0,0,0,4.27-.34,6.94,6.94,0,0,0,5.4-7.19,6.76,6.76,0,0,0-6-6.64,26.72,26.72,0,0,0-4.29-.26h-85a29.08,29.08,0,0,0-4.67.3A6.49,6.49,0,0,0,242.1,339c-1.2,4.9,2.27,8.79,8.13,8.81C265,347.86,279.74,347.82,294.5,347.82Z" transform="translate(-43.92 -47.78)" style={{ fill: "#fff" }} />
                      <path d="M438.39,578.35c-6-1.08-12.13-1.8-18-3.3-44.26-11.19-76.92-48.47-81.52-92.81-5.67-54.73,28.63-103.38,82.56-117.09,39.89-10.14,85.49,6.15,110.29,39.15,17.35,23.07,25.37,49,21.79,77.52-5.32,42.52-28.34,72.51-68,88.79-9.86,4-20.88,5.24-31.36,7.74Zm16.36-65.57-1.36-.9v-4.12q0-47.22,0-94.46a33.32,33.32,0,0,0-.17-4.69,7.08,7.08,0,0,0-14-.23,28.14,28.14,0,0,0-.21,4.69q0,47.42,0,94.85v4l-1.16.55a36,36,0,0,0-2.33-3.11q-17.49-18.79-35-37.54a19.07,19.07,0,0,0-3.46-3.15A7.07,7.07,0,0,0,387,478.15a18.15,18.15,0,0,0,2.4,3.07q24.68,26.56,49.38,53.1c5.41,5.8,9,5.8,14.36,0q24.47-26.22,48.91-52.46a19.6,19.6,0,0,0,3.13-4c1.63-3.1,1.26-6.17-1.4-8.54a6.63,6.63,0,0,0-8.64-.63,19.83,19.83,0,0,0-3.43,3.18q-17.5,18.78-35,37.62A19.83,19.83,0,0,0,454.75,512.78Z" transform="translate(-43.92 -47.78)" style={{ fill: "#fff" }} />
                      <path d="M445.4,259.17c-15.7,0-30.2.26-44.68-.08-13.26-.32-22.61-9.85-23.05-23.82-.47-15.13-.09-30.29-.07-45.45,0-.22.19-.45.59-1.32Z" transform="translate(-43.92 -47.78)" style={{ fill: "#fff" }} />
                    </g>
                  </g>
                </svg>
              </div>
            </>
            : ""}
        </div>
      </>
    )
  }
};
export default ApplicantDocuments;

// for dynamic Table config Object
const configuration = {
  isSearchbar: false,
  isPaging: true,
  isDropdownActions: false,
  isCheckbox: false,
  isActions: true,
  recordperPage: 10,
  currentPage: 1,
  totalRecords: 0,
  limitData: 10,
  isPaginationTop: false,
  ischeckAlignRight: true,
  isSearchServer: false,
  isPagingServer: false,
  isPageRange:false,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["Delete"];

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isPositingsView: true,
  isPostingsDownload: true,
  isDelete: true,
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "Document Name",
    mappingName: "name",
    class: "",
    theadClass: " text-left dynatableheadtext",
    tbodyClass: " dynatablebodytext",
    isEdit: false,
    nagigate: null,
    width: "400px",
  },
  {
    id: 2,
    displayName: "Document Type",
    mappingName: "docType",
    theadClass: " text-center dynatableheadtext",
    tbodyClass: " text-center dynatablebodytext",
    isEdit: false,
    nagigate: null,
    width: "200px",
  },
  {
    id: 3,
    displayName: "Date and Time",
    mappingName: "date",
    theadClass: " text-center dynatableheadtext",
    tbodyClass: " text-center documentposttime",
    isEdit: false,
    nagigate: null,
    width: "200px",
  },
  {
    id: 4,
    displayName: "Action",
    mappingName: "Actions",
    theadClass: " text-center dynatableheadtext",
    tbodyClass: " text-center documentposttime",
    isEdit: false,
    width: "150px",
    nagigate: null,
  },
];

const pannelobj = {
  title: "Add documents",
  description: "Browse files to upload",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/AddDocumentsIcon.svg`,
};

const addNewModel = {
  url: "",
  displayName: ""
}

const pannelobjview = {
  title: "Document view",
  description: "",
  bgImage: "",
};