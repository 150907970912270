import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNextPath } from '../../../_redux/SelectedNextPath';
import * as _ser from "../../../_redux/_services/ApplicantsSlice";
import * as _sercloud from "../../../_redux/_services/CloudSourcingSlice";
import * as _props from '../../../_redux/_services/JobPostingSlice';
import GetJobId from '../../../_utilities/GetJobId';
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import AssociateCards from '../../../components/AssociateCards/AssociateCards';
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import RightSideBar from "../../../components/Rightsidebar/RightSideBar";
import PostingsView from '../../JobPostings/PostingsView/PostingsView';
import './ApplicantAssociatedJobs.css';


const initalState = { data: null, message: '', isloader: true }

const ApplicantAssociatedApplicants = () => {
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);

  const [showTable, setShowTable] = useState(false);
  const [AccociatedJobSeekerList, setAccociatedJobSeekerList] = useState([]);
  const [qnsCount, setQnsCount] = useState(0);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [getData, setGetData] = useState({});
  const [questionsData, setQuestionsData] = useState([]);
  const [config, setConfig] = useState(configuration);
  const getJobCode = GetJobId();
  const dispatch = useDispatch();
  const [componentType, setComponentType] = useState("AssociatedJobs");
  const [isValidUser,setIsValidUser ] = useState(false);

  const nextPathValue = useSelector((state) => state.SeletedNextPath.value);

  useEffect(() => {
    if (getJobCode !== null) {
      FetchData();
    }
    // eslint-disable-next-line
  }, [getJobCode]);

  useEffect(() => {
    if (nextPathValue === "null") {
      setShowJobDetails(false);
      localStorage.setItem("AssociatedMenu", "false");
      window.dispatchEvent(new Event('storage'))
    }
  }, [nextPathValue]);

  const GetApplicantJobsData = useSelector((state) => state.GetApplicantJobs.data);
  const GetApplicantJobsCode = useSelector((state) => state.GetApplicantJobs.code);

  useEffect(()=>{
    if(GetApplicantJobsCode === 404) {
      setIsValidUser(true);
    }
    },[GetApplicantJobsCode])

  const FetchData = async () => {
    setAccociatedJobSeekerList([]);
    dispatch(_ser.getApplicantJobsAsync(getJobCode));
  };

  useEffect(() => {
    if (GetApplicantJobsData !== null && GetApplicantJobsData !== undefined) {
      if (GetApplicantJobsData.length > 0) {
        let myData = JSON.parse(JSON.stringify(GetApplicantJobsData));
        let newArray = [];
        myData.forEach((e) => {
          e["isChecked"] = false;
          if (e.sourcedFrom === "Cloudsourcing" || e.sourcedFrom === "Employee Portal") {
            e["screeningquestions"] = `${e.anscount}/${e.qnscount}`;
          } else {
            e["screeningquestions"] = '';
          }
          newArray.push(e);
        });
        setAccociatedJobSeekerList(newArray);
        let configure = JSON.parse(JSON.stringify(config))
        configure.totalRecords = newArray.length - 1;
        configure.currentPage = 1;
        setConfig(configure)
        setComponentType("AssociatedJobs");
        dispatch(_ser.getApplicantJobsStartReducer(initalState))
      } else {
        setAccociatedJobSeekerList([]);
      }
    }
  }, [GetApplicantJobsData])

  const onReceiveActionHandler = async (type, object) => {
    setShowTable(false);
    if (type === "pagination") {
      FetchData(object);
    } else if (type === "searchResult") {
    } else if (type === "cancelSearchResult") {
      setFilterValue("");
      let obj1 = {
        current: config.currentPage,
        offset: config.currentPage !== 1 ? (config.currentPage - 1) * 20 + 1 : config.currentPage,
      };
      FetchData(obj1);
    } else if (type === "confirmDel") {
      DeleteRecords(object);
    } else if (type === "detailspage") {
      dispatch(updateNextPath(object.jobTitle));
      setGetData(object);
      setShowJobDetails(true);
      sessionStorage.setItem("actualId", getJobCode);
      sessionStorage.setItem("AssociatedId", object.jobopeningID);
      localStorage.setItem("AssociatedMenu", object.jobTitle);
      window.dispatchEvent(new Event('storage'))
    } else if (type === "viewAnswers") {
      let Obj = {
        cs_id: object.cs_id,
        job_id: object.jobopeningID,
        applicant_id: object.applicantID,  //TODO
      };
      dispatch(_sercloud.getQuestionsAnswersReducerAsync(Obj));
    }
    setTimeout(() => { setShowTable(true) }, 100);
  }

  const delApplicantJobsCode = useSelector((state) => state.DeleteApplicantJobs.data);

  const responseOfQuestionAnswer = useSelector((state) => state.GetQuestionsAnswers.data);

  const DeleteRecords = async (object) => {
    if (object.sourcedFrom === "Cloudsourcing") {
      object["portalassociatedJobs"] = "csassociatedJobs";
    } else if (object.sourcedFrom === "Employee Portal") {
      object["portalassociatedJobs"] = "eassociatedJobs";
    } else if (object.sourcedFrom === "Vendorsourcing") {
      object["portalassociatedJobs"] = "vsassociatedJobs";
    } else {
      object["portalassociatedJobs"] = "associatedJobs";
    }
    object["id"] = object.jobopeningID;
    dispatch(_ser.deleteApplicantJobsAsync(getJobCode, object));
  };

  useEffect(() => {
    if (delApplicantJobsCode === 200) {
      let obj1 = {
        current: config.currentPage,
        offset: config.currentPage !== 1 ? (config.currentPage - 1) * 20 + 1 : config.currentPage,
      };
      FetchData(obj1);
      dispatch(_ser.deleteApplicantJobsStartReducer(initalState))
    }
  }, [delApplicantJobsCode])

  useEffect(() => {
    if (responseOfQuestionAnswer && responseOfQuestionAnswer.length > 0) {
      let newObj = JSON.parse(JSON.stringify(responseOfQuestionAnswer));
      newObj.forEach(item => {
        item.answer = JSON.parse(item.answer);
      })
      setQuestionsData(newObj);
      setIsRightSidePannel(true);
      setComponentType("Questions&Answers");
      dispatch(_sercloud.getQuestionsAnswersStartReducer({}));
    }
  }, [responseOfQuestionAnswer]);

  useEffect(() => {
    console.log(AccociatedJobSeekerList.length > 0);
  }, [showTable, AccociatedJobSeekerList])

  const onReceivePropsHandler = (pannel, load) => {
    if (load === "getFetch") {
      setIsRightSidePannel(pannel);
      FetchData();
    } else if (load === "close") {
      setIsRightSidePannel(pannel);
      setComponentType("AssociatedJobs");
    }
  };

  return (
    <>
      {!showJobDetails && !isValidUser ?
        <>
          <div
            className="adddocumentsection"
            style={{ display: "flex", height: "34px", gap: "10px" }}
            onClick={() => setIsRightSidePannel(true)}
          >
            <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" />
            <h4 className="addnewdocumenttext" > Associate Jobs</h4>
          </div>
        </>
        : ''}
      {showJobDetails ?
        <div id="InnerComponents">
          <PostingsView getData={getData} />
        </div>
        : <div className="contentSection">
          {AccociatedJobSeekerList.length > 0 && (
            <DynamicTable
              config={config}
              data={AccociatedJobSeekerList}
              colmns={columnsData}
              actionDropdown={actionsDropdownData}
              actions={actionsList}
              searchKeyWord="Search by Job code, Title"
              noDataText="No Associated Job"
              filterValue={filterValue}
              addModel={addNewModel}
              onReceiveActionProps={(type, obj) =>
                onReceiveActionHandler(type, obj)
              }
            />
          )}
          {AccociatedJobSeekerList.length === 0 && (
            <DynamicTable
              config={config}
              data={AccociatedJobSeekerList}
              colmns={columnsData}
              actionDropdown={actionsDropdownData}
              actions={actionsList}
              searchKeyWord="Search by Job code, Title"
              noDataText="No Associated Job"
              filterValue={filterValue}
              addModel={addNewModel}
              onReceiveActionProps={(type, obj) =>
                onReceiveActionHandler(type, obj)
              }
            />
          )}
        </div>
      }

      <div className='rightsidebarbtn'>
        {isRightSidePannel ? (
          <RightSideBar
            componentData={
              <ComponentRenderData
                JobId={getJobCode}
                isLoad={isRightSidePannel}
                clientData={AccociatedJobSeekerList}
                componentType={componentType}
                onReceivechildProps={onReceivePropsHandler}
                questionsData={questionsData}
              />
            }
            componentLayout={componentType === "AssociatedJobs" ? ASAObj : componentType === "Questions&Answers" ? QAObj : ''}
            onReceiveProps={onReceivePropsHandler}
          />
        ) : (
          ""
        )}
      </div>
    </>
  )
}


const ComponentRenderData = ({ JobId, clientData, onReceivechildProps, isLoad, componentType, questionsData }) => {
  const [jobData, setjobData] = useState(null);
  const [AlljobData, setAlljobData] = useState([]);
  const [selectedIds, setSelectedIds] = useState(clientData.map(x => x.jobopeningID));
  const [findconfig, setFindconfig] = useState(findconfiguration);
  const [aiconfig, setaiconfig] = useState(aiconfiguration);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('1');
  const [totalRecords, setTotalRecords] = useState(null);


 


  const GetMatchAssociatedJobsData = useSelector((state) => state.GetMatchAssociatedJobs.data);

  const GetData = async () => {
    dispatch(_ser.getMatchAssociatedJobsAsync(JobId));
  };

  useEffect(() => {
    if (GetMatchAssociatedJobsData !== null && GetMatchAssociatedJobsData !== undefined) {
      setjobData(null);
      if (GetMatchAssociatedJobsData.length > 0) {
        let myData = JSON.parse(JSON.stringify(GetMatchAssociatedJobsData));
        setTimeout(() => { setjobData(myData) }, 200);
        dispatch(_ser.getMatchAssociatedJobsStartReducer(initalState))
      }
    }
  }, [GetMatchAssociatedJobsData])


  const onActionHandler = (id, type) => {
    if (type === "delete") {
      DeleteAssociatedjobs(id);
    } else if (type === "add") {
      AddAssociatedjobs(id);
    } else if (type === "searchResult") {
      setSearchValue(id);
      getListJobs(id);
    }
  }
  const DelAssociatedjobscode = useSelector((state) => state.DeleteAssociatedjobs.data);

  const DeleteAssociatedjobs = async (obj) => {
    obj["portalassociatedJobs"] = "associatedJobs";
    dispatch(_ser.deleteAssociatedjobsAsync(JobId, obj));
  }

  useEffect(() => {
    if (DelAssociatedjobscode === 200) {
      onReceivechildProps(true, "getFetch");
      dispatch(_ser.deleteAssociatedjobsStartReducer(initalState))
    }
  }, [DelAssociatedjobscode]);

  const AddAssociatedjobscode = useSelector((state) => state.AddAssociatedjobs.data);

  let userInfo = JSON.parse(decrypt('GetUserInfo'));
  let userId = JSON.parse(localStorage.getItem("getAttributes"));

  const AddAssociatedjobs = async (obj) => {
    let userInfo = JSON.parse(decrypt('GetUserInfo'));
    let userId = JSON.parse(localStorage.getItem("getAttributes"));
    obj["portalassociatedJobs"] = "associatedJobs";
    obj["status"] = "Associated";
    obj["source"] = "ATS";
    obj["submittedBy"] = userInfo[0].userId;
    obj["firstName"] = userId[0].firstName;
    obj["lastName"] = userId[0].lastName;
    dispatch(_ser.addAssociatedjobsAsync(JobId, obj, "posting"));
    // let obj1 = { id: JobId, portalassociatedApplicants: "associatedApplicants", applicantCode: obj.jobCode }
    // dispatch(_props.addAssociatedjobsAsync(obj.id, obj1, "posting"));
  }

  useEffect(() => {
    if (AddAssociatedjobscode === 200) {
      onReceivechildProps(true, "getFetch");
      dispatch(_ser.addAssociatedjobsStartReducer(initalState))
    }
  }, [AddAssociatedjobscode])

  useEffect(() => {
    if (isLoad === true) {
      GetData();
    }
    // eslint-disable-next-line
  }, [JobId, isLoad]);

  const responseData = useSelector((state) => state.Listjobpostings.data);

  const getListJobs = async (obj) => {
    dispatch(_props.listjobpostingsAsync(obj, 'searchWithoutPaging'));
  }

  const handleChange = (event, Value) => {
    setValue(Value);
    setAlljobData([]);
  };

  useEffect(() => {
    if (responseData && responseData.length > 0) {
      setAlljobData(responseData);
      setFindconfig({ ...findconfig, totalRecords: 0 });
      setTotalRecords(null);
      setSearchValue('');
      dispatch(_props.listjobpostingsStartReducer(initalState));
    } else if (responseData?.length === 0) {
      setAlljobData([])
    }
  }, [responseData])

  return (
    <>
      {componentType === "AssociatedJobs" && (
        <div className='tabs'>
          <Box sx={{ width: '100%', typography: 'body1', color: "#3B4046", marginTop: "-35px" }} >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" className='tabsvalue' >
                  <Tab label="AI matched jobs" value="1" />
                  <Tab label="Find jobs" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="mt-5">
                  {jobData && jobData.length > 0 ?
                    <AssociateCards data={jobData} selectedItems={selectedIds} searchKeyWord="Search by Job code, Title" navigateTo="/ats/layout/jobpostings/view/" config={aiconfig} componentType="AssociatedJobs"
                      onReceiveHandler={(Item, obj) => onActionHandler(Item, obj)} /> :
                    <div>
                      <div className="nodatasection nodataSection">
                        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
                        No AI match Jobs
                      </div>
                    </div>
                  }
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="mt-5">
                  {AlljobData ?
                    <AssociateCards data={AlljobData} selectedItems={selectedIds} searchKeyWord="Search by Job code, Title" navigateTo="/ats/layout/jobpostings/view/" config={findconfig} componentType="AssociatedJobs"
                      onReceiveHandler={(Item, obj) => onActionHandler(Item, obj)} /> :
                    <div>
                      <div className="nodatasection nodataSection">
                        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
                        No Jobs
                      </div>
                    </div>
                  }
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      )}
      {componentType === "Questions&Answers" && (
        <div style={{ height: "calc(100vh - 459px)", overflowY: "auto" }}>
          {questionsData.length > 0 && questionsData.map((item) => {
            return (
              <div className='questionsarea'>
                <h3>{item.question}</h3>
                <p>
                  {item.answer.join(", ")}
                </p>
              </div>
            )
          })
          }
        </div>
      )}
    </>
  );
};

export default ApplicantAssociatedApplicants;

const configuration = {
  isSearchbar: true,
  isPaging: true,
  isDropdownActions: false,
  isCheckbox: false,
  isActions: true,
  recordperPage: 10,
  currentPage: 1,
  totalRecords: 0,
  limitData: 10,
  ischeckAlignRight: false,
  isPaginationTop: false,
  isSearchServer: false,
  isPagingServer: false,
  isPageRange:false,
};

const aiconfiguration = {
  recordperPage: 8,
  currentPage: 1,
  totalRecords: 0,
  limitData: 8,
  isSearchServer: false,
};

const findconfiguration = {
  recordperPage: 8,
  currentPage: 1,
  totalRecords: 0,
  limitData: 8,
  isSearchServer: true,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["", ""];

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isDelete: true,
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "Job Code",
    mappingName: "jobCode",
    theadClass: "text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "0px",
    isEdit: true,
    nagigate: 'detailspage'
  },
  {
    id: 2,
    displayName: "Job Title",
    mappingName: "jobTitle",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "400px",
    isEdit: true,
    nagigate: 'detailspage'
  },
  {
    id: 3,
    displayName: "Location",
    mappingName: "location",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: 'detailspage'
  },

  {
    id: 5,
    displayName: "Job Status",
    mappingName: "jobStatus",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: 'detailspage'
  },
  {
    id: 6,
    displayName: "Client",
    mappingName: "clientName",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: 'detailspage'
  },
  {
    id: 7,
    displayName: "Candidate Status",
    mappingName: "status",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext td-flex",
    width: "",
    isEdit: false,
    nagigate: null
  },
  {
    id: 8,
    displayName: "Action",
    mappingName: "actionsList",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: null
  },
];

const addNewModel = {
  url: "",
  displayName: ""
}

const ASAObj = {
  title: " Associate Jobs ",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/associate background.svg`,
};
const QAObj = {
  title: "Questions and Answers",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/associate background.svg`,
};


