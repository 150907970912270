import React, { useState, useEffect } from "react";
import "./applicantsdashboard.css"

const Widgets = ({ data }) => {

    return (
        <aside>
            <label>{data.title}</label>
            <div className="d-flex-around">
                <h1>{data.currentValue}</h1>
                <img src={data.img} alt="" />
            </div>
            <p>{data.currentTitle}</p>
            <div className="progressbarSection">
                <section className="d-flex-around">
                    <div className="progressbar progressbar1-top">
                        <div style={{ width: data.avgPercentage }} className="progressbar-inner"></div>
                    </div>
                    <h3>{data.avgValue}</h3>
                </section>
                <p>{data.avgTitle}</p>
            </div>
            <div className="progressbarSection">
                <section className="d-flex-around">
                    <div className="progressbar progressbar1-bottom">
                        <div style={{ width: data.highestPercentage }} className="progressbar-inner"></div>
                    </div>
                    <h3>{data.highestValue}</h3>
                </section>
                <p>{data.highestTitle}</p>
            </div>
        </aside>
    )

}

export default Widgets;