import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateNextPath } from "../../../_redux/SelectedNextPath";
import * as _ser1 from '../../../_redux/_services/ApplicantsSlice';
import * as _serapplicant from "../../../_redux/_services/ApplicantsSlice";
import * as _sercloud from "../../../_redux/_services/CloudSourcingSlice";
import * as _ser from "../../../_redux/_services/JobPostingSlice";
import { customFieldsAsync } from "../../../_redux/_services/UserManagerSlice";
import GetJobId from "../../../_utilities/GetJobId";
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import AssociateCards from "../../../components/AssociateCards/AssociateCards";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import RightSideBar from "../../../components/Rightsidebar/RightSideBar";
import ApplicantsManualUploadPopup from '../../Applicants/ApplicantsTable/ApplicantsManualUploadPopup';
import { ApplicantsRenderData } from "../../Applicants/ApplicantsTable/ApplicantsRenderData";
import DuplicateApplicantsManualUploadPopup from '../../Applicants/ApplicantsTable/DuplicateApplicantsManualUploadPopup';
import ApplicantsView from "../../Applicants/ApplicantsView/ApplicantsView";
import "./JobPostingAssociate.css";

const initalState = { data: null, message: "", isloader: true };

const JobpostingsAssociatedApplicants = () => {
  const [showTable, setShowTable] = useState(false);
  const [AccociatedApplicants, setAccociatedApplicants] = useState([]);
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);
  const [ componentType, setComponentType ] = useState("AssociatedApplicants");
  const [ questionsData, setQuestionsData ] = useState([]);
  const dispatch = useDispatch();
  const getJobCode = GetJobId();
  const [showApplicantDetails, setShowApplicantDetails] = useState(false);
  const [getData, setGetData] = useState({});
  const [qnsCount, setQnsCount] = useState(0);
  const nextPathValue = useSelector((state) => state.SeletedNextPath.value);
  const responseOfQuestionAnswer = useSelector(
    (state) => state.GetQuestionsAnswers.data
  );

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [isOtherPortals, setIsOtherPortals] = useState(false);
  const [portalAccociatedApplicants, setPortalAccociatedApplicants] = useState([]);
  const [candidateStatusValues, setCandidateStatusValues] = useState([]);
  const [config, setConfig] = useState(configuration);

  const customFieldsData = useSelector(state => state.CustomFields.data);
  
  useEffect(() => {
      let userId = JSON.parse(decrypt('GetUserId'))
      dispatch(customFieldsAsync(userId));
  }, []);

  useEffect(() => {
    if (customFieldsData && customFieldsData.length > 0) {
      const data = JSON.parse(JSON.stringify(customFieldsData));
      let customStatusFieldsData = data.filter(x => x.moduleId.toString() === "2" && x.fieldType === "default" && x.fieldName === "candidateStatus");
      console.log(customStatusFieldsData);
      if (customStatusFieldsData.length > 0) {
        // customStatusFieldsData.forEach(list => {
          // if (list.fieldName === "candidateStatus") {
          //   setCandidateStatusValues(JSON.parse(list.customValues));
          // }
          let index = customStatusFieldsData.length - 1;
          let array = JSON.parse(customStatusFieldsData[index].customValues)
          setCandidateStatusValues(array);
          
        // })
      }
    }
  },[customFieldsData])

  useEffect(() => {
    if (getJobCode !== null) {
      FetchData();
    }
  }, [getJobCode]);
  
  useEffect(() => {
    console.log(candidateStatusValues);
  }, [candidateStatusValues]);


  useEffect(() => {
    if (nextPathValue === "null") {
      setShowApplicantDetails(false);
      localStorage.setItem("AssociatedMenu", "false");
      window.dispatchEvent(new Event("storage"));
    }
  }, [nextPathValue]);

  const AccociatedApplicantsData = useSelector(
    (state) => state.GetAssociatedApplicants.data
  );

  const FetchData = async () => {
    setAccociatedApplicants([]);
    setPortalAccociatedApplicants([]);
    dispatch(_ser.getAssociatedApplicantsAsync(getJobCode));
  };

  useEffect(() => {
    if (
      AccociatedApplicantsData !== null &&
      AccociatedApplicantsData !== undefined
    ) {
      if (AccociatedApplicantsData.length > 0) {
        const myData = JSON.parse(JSON.stringify(AccociatedApplicantsData));
        setQnsCount(myData[0].qnscount);
        let newArray = [];
        let newPortalArray = [];
        if (myData !== undefined) {
          myData.forEach((e) => {
            // e.firstName = e.firstName + " " + e.lastName;
            e.firstName = e.firstName ? e.firstName : " ";
            e.lastName = e.lastName ? e.lastName : " ";
            e["createdBy"] = e.firstName + " " + e.lastName;
            if (e.SourcedFrom === "Cloud Sourcing" || e.SourcedFrom === "Employee Portal" || e.SourcedFrom === "Vendor Sourcing" || e.SourcedFrom === "JobBoard Portal") {
              e["screeningquestions"] = `${e.anscount}/${myData[0].qnscount}`;
              newPortalArray.push(e);
              setPortalAccociatedApplicants(newPortalArray);
            } else {
              e["screeningquestions"] = "";
              newArray.push(e);
              setAccociatedApplicants(newArray);
            }
          });
            let configure = JSON.parse(JSON.stringify(config));
            configure.totalRecords = newArray.length - 1;
            configure.currentPage = 1;
            setConfig(configure);
            // setAccociatedApplicants(newArray);
          
          setComponentType("AssociatedApplicants");
          setShowTable(true);
        }
        dispatch(_ser.getAssociatedApplicantsStartReducer(initalState));
      } else {
        setAccociatedApplicants([]);
        setPortalAccociatedApplicants([]);
      }
    }
  }, [AccociatedApplicantsData]);

  useEffect(()=>{
    if (isOtherPortals) {
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = portalAccociatedApplicants.length - 1;
      configure.currentPage = 1;
      setConfig(configure);
      setPortalAccociatedApplicants(portalAccociatedApplicants);
    } else {
      let configure = JSON.parse(JSON.stringify(config));
            configure.totalRecords = AccociatedApplicants.length - 1;
            configure.currentPage = 1;
            setConfig(configure);
            setAccociatedApplicants(AccociatedApplicants);
    }
  },[isOtherPortals])
  

  // for dynamic component received props
  const onReceiveActionHandler = async (type, object) => {
    if (type === "detailspage") {
      dispatch(updateNextPath(object.Applicantname));
      setGetData(object);
      setShowApplicantDetails(true);
      sessionStorage.setItem("actualId", getJobCode);
      sessionStorage.setItem("AssociatedId", object.applicantID); //TODO
      localStorage.setItem("AssociatedMenu", object.Applicantname);
      window.dispatchEvent(new Event("storage"));
    } else if (type === "confirmDel") {
      DeleteAssociatedJobseeker(object);
    } else if (type === "viewAnswers") {
      let Obj = {
        cs_id: object.cs_id ? object.cs_id : 0,
        job_id: object.job_id,
        applicant_id: object.applicantID, //TODO
      };
      dispatch(_sercloud.getQuestionsAnswersReducerAsync(Obj));
    }
  };

  useEffect(() => {
    if (responseOfQuestionAnswer && responseOfQuestionAnswer.length > 0) {
      let newObj = JSON.parse(JSON.stringify(responseOfQuestionAnswer));
      newObj.forEach(item => {
        item.answer = JSON.parse(item.answer);
      })
      setQuestionsData(newObj);
      setIsRightSidePannel(true);
      setComponentType("Questions&Answers");
      dispatch(_sercloud.getQuestionsAnswersStartReducer({}));
    }
  }, [responseOfQuestionAnswer]);

  const DeleteAssociatedJobseekercode = useSelector(
    (state) => state.DeleteAssociatedJobseeker.data
  );

  let userId = JSON.parse(decrypt('GetUserId'));

  const DeleteAssociatedJobseeker = async (object) => {
    if(object.SourcedFrom === "Cloud Sourcing"){
      object["portalassociatedApplicants"] = "csassociatedApplicants";
    } else if(object.SourcedFrom === "Employee Portal") {
      object["portalassociatedApplicants"] = "eassociatedApplicants";
    } else if(object.SourcedFrom === "Vendorsourcing") {
      object["portalassociatedApplicants"] = "vsassociatedApplicants";
    } else {
      object["portalassociatedApplicants"] = "associatedApplicants";
    }
    // object["id"] = object.asscoId;
    // dispatch(_ser.deleteAssociatedJobAsync(getJobCode, object));
    dispatch(_ser.deleteAssociatedJobseekerAsync(getJobCode, object));
  };

  useEffect(() => {
    if (DeleteAssociatedJobseekercode === 200) {
      setShowTable(false);
      FetchData();
      dispatch(_ser.deleteAssociatedJobseekerStartReducer(initalState));
    }
  }, [DeleteAssociatedJobseekercode]);

  useEffect(() => {
    console.log("AccociatedApplicants", AccociatedApplicants);
  }, [showTable, AccociatedApplicants]);

  const onReceivePropsHandler = (pannel, load) => {
    if (load == "getFetch") {
      setIsRightSidePannel(pannel);
      FetchData();
    } else if (load === "close") {
      setIsRightSidePannel(pannel);
      setComponentType("AssociatedApplicants");
    }
  };

  const onChangePlatformHandler = (e) => {
    if (isOtherPortals === false) {
    setIsOtherPortals(true)
  } else {
    setIsOtherPortals(false)
  } }

  return (
    <>
      {!showApplicantDetails ? (
        <div
          className="adddocumentsection"
          style={{ display: "flex", height: "34px", gap: "10px" }}
          onClick={() => setIsRightSidePannel(true)}
        >
          <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png" }alt="" />
          <h4 className="addnewdocumenttext">Associate Applicants</h4>
        </div>
      ) : (
        ""
      )}
      {showApplicantDetails ? (
        <div id="InnerComponents">
          <ApplicantsView getData={getData} />
        </div>
      ) : (
        <div className="contentSection">
          <p className="togglebtn">
        ATS
        <span style={{ marginLeft: "0px", marginRight:"0px" }}>
          <Switch
            {...label}
            checked={isOtherPortals}
            // disabled={componentType !== "View" ? false : true}
            onChange={(e) => onChangePlatformHandler(e, "item")}
          />
        </span> <span className={isOtherPortals === true ? "portalActive": ""}>Other Portals</span>
      </p>
        {isOtherPortals !== true ? (
        <>
        {AccociatedApplicants.length > 0  && (
            <DynamicTable
              config={config}
              data={AccociatedApplicants}
              colmns={columnsData}
              actionDropdown={actionsDropdownData}
              inLineDropdownData={candidateStatusValues}
              actions={actionsList}
              addModel={addNewModel}
              searchKeyWord="Search by ID, Name, Contact Number"
              noDataText="No Associated Applicant"
              onReceiveActionProps={(type, obj) =>
                onReceiveActionHandler(type, obj)
              }
            />
            )}
            {AccociatedApplicants.length === 0  && (
              <DynamicTable
                config={configuration}
                data={AccociatedApplicants}
                colmns={columnsData}
                actionDropdown={actionsDropdownData}
                inLineDropdownData={candidateStatusValues}
                actions={actionsList}
                addModel={addNewModel}
                searchKeyWord="Search by ID, Name, Contact Number"
                noDataText="No Associated Applicant"
                onReceiveActionProps={(type, obj) =>
                  onReceiveActionHandler(type, obj)
                }
              />
            )}
          </>
          ) : (
            <>
            {portalAccociatedApplicants.length > 0  && (
            <DynamicTable
            config={config}
            data={portalAccociatedApplicants}
            colmns={portalsColumnsData}
            actionDropdown={actionsDropdownData}
            actions={actionsList}
            addModel={addNewModel}
            searchKeyWord="Search by ID, Name, Contact Number"
            noDataText="No Associated Applicant"
            onReceiveActionProps={(type, obj) =>
              onReceiveActionHandler(type, obj)
            }
          />
          )}
          {portalAccociatedApplicants.length === 0  && (
            <DynamicTable
            config={config}
            data={portalAccociatedApplicants}
            colmns={portalsColumnsData}
            actionDropdown={actionsDropdownData}
            actions={actionsList}
            addModel={addNewModel}
            searchKeyWord="Search by ID, Name, Contact Number"
            noDataText="No Associated Applicant"
            onReceiveActionProps={(type, obj) =>
              onReceiveActionHandler(type, obj)
            }
          />
          )}
          </>

            ) }
            
          
        </div>
      )}
      <div className="rightsidebarbtn">
        {isRightSidePannel && getJobCode ? (
          <RightSideBar
            componentData={
              <ComponentRenderData
                JobId={getJobCode}
                dispatch={dispatch}
                isLoad={isRightSidePannel}
                clientData={AccociatedApplicants}
                componentType={componentType}
                onReceivechildProps={onReceivePropsHandler}
                questionsData={questionsData}
              />
            }
            componentLayout={componentType === "AssociatedApplicants" ? ASAObj : componentType === "Questions&Answers" ? QAObj : '' }
            onReceiveProps={onReceivePropsHandler}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
const ComponentRenderData = ({
  dispatch,
  JobId,
  clientData,
  onReceivechildProps,
  componentType,
  isLoad, questionsData,
}) => {
  const [jobData, setjobData] = useState(null);
  const [AlljobData, setAlljobData] = useState([]);
  const [selectedIds, setSelectedIds] = useState(clientData.map(x => x.applicantID)); //TODO
  const [findconfig, setFindconfig] = useState(findconfiguration);
  const [aiconfig, setaiconfig] = useState(aiconfiguration);
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = React.useState("3");
  const [config, setConfig] = useState(configuration1);
  const [totalRecords, setTotalRecords] = useState(null);
  const SearchAffinda = useSelector((state) => state.SearchAffinda.data);
  const navigate = useNavigate();
  const [manualApplicatsConfirmPopup, setManualApplicatsConfirmPopup] = useState(false);
  const [ updateProfileWithNewResume, setUpdateProfileWithNewResume ] = useState(null)
  const [sourceId, setSourceId] = useState(0);
  const [defaultFile, setDefaultFile] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [duplicateApplicatsConfirmPopup, setDuplicateApplicatsConfirmPopup] = useState(false);
  const [twoDuplicateApplicatsConfirmPopup, setTwoDuplicateApplicatsConfirmPopup] = useState(false);
  const UploadDocCode = useSelector(state => state.UploadDocuments.data);
  const [ skipId, setSkipId ] = useState(null);
  const [ skipName, setSkipName ] = useState(null);


  const GetData = async () => {
    let params = [], generateURL = '';
    if(keyword !== ""){
      params.push(`keyword=${keyword}`)
    }
    generateURL = params.join("&");
    dispatch(_ser.searchAffindaAsync(generateURL));
  };

  useEffect(() => {
    if (SearchAffinda !== null && SearchAffinda !== undefined) {
      setjobData(null);
      if (SearchAffinda.length > 0) {
        const myData = [];
        let applicantsData = JSON.parse(JSON.stringify(SearchAffinda));
        applicantsData.forEach((e) => {
          if (e.length > 0) {
            myData.push(...e);
          } else if (typeof e === "object" && e.id) {
            myData.push(e);
          }
        });
        setTimeout(() => {
          setjobData(myData);
        }, 100);
        // let Ids = []
        // myData.forEach(list => {
        //   let Array = clientData.filter(x => x.id === list.id);
        //   if (Array.length > 0) {
        //     Ids.push(Array[0].id);
        //   }
        // });
        // setSelectedIds(Ids);
        dispatch(_ser.searchAffindaStartReducer(initalState));
      } else {
        setjobData([]);
      }
    }
  }, [SearchAffinda]);

  const onActionHandler = (id, type) => {
    if (type === "delete") {
      DeleteAssociatedjobs(id);
    } else if (type === "add") {
      AddAssociatedjobs(id);
    } else if (type === "searchResult") {
      setSearchValue(id);
      getListApplicants(id);
    }
  };

  const DelAssociatedJobcode = useSelector(
    (state) => state.DeleteAssociatedJob.data
  );

  

  const DeleteAssociatedjobs = async (obj) => {
    // obj["portalassociatedApplicants"] = "associatedApplicants";
    // obj["submittedBy"] = userId;
    dispatch(_ser.deleteAssociatedJobAsync(JobId, obj));
  };
  const AllowDuplicateResume = useSelector(store => store.AddApplicants.updatedata);

  useEffect(() => {
    if (DelAssociatedJobcode === 200) {
      onReceivechildProps(true, "getFetch");
      dispatch(_ser.deleteAssociatedStartReducer(initalState));
    }
  }, [DelAssociatedJobcode]);

  const AddAssociatedjobsCode = useSelector(
    (state) => state.AddAssociatedjobs.data
  );

  let userInfo = JSON.parse(decrypt('GetUserInfo'));
  let userId = JSON.parse(localStorage.getItem("getAttributes"));

  const AddAssociatedjobs = async (obj) => {
    // obj["portalassociatedApplicants"] = "associatedApplicants";
    obj["source"] = "ATS";
    obj["submittedBy"] = userInfo[0].userId;
    obj["firstName"] = userId[0].firstName;
    obj["lastName"] = userId[0].lastName;
    obj["status"] = "Assigned";
    dispatch(_ser.addAssociatedjobsAsync(JobId, obj, "applicant"));
    let obj1 = { id: JobId, portalassociatedJobs : "associatedJobs", jobCode:obj.applicantCode }
    // dispatch(_serapplicant.addAssociatedjobsAsync(obj.id, obj1, "applicant"));
  };

  useEffect(() => {
      if (AddAssociatedjobsCode === 200) {
        dispatch(_ser.addAssociatedjobsStartReducer(initalState))
        onReceivechildProps(true, "getFetch");
      }
  }, [AddAssociatedjobsCode]);

  useEffect(() => {
    if (isLoad === true) {
      // ai matched related code comented
      // GetData();
      const url = window.location.pathname;
      const path = url.substring(url.lastIndexOf("/") + 1);
      if(Number.isInteger(parseInt(path))){
        let subpathObj = JSON.parse(localStorage.getItem("subpathObj"));
        let filterSubObj = subpathObj.filter(x => x.id === parseInt(path) && x.type === "Job Code");
        if(filterSubObj.length > 0){
          setKeyword(filterSubObj[0].name);
        }
      }
    }
  }, [JobId, isLoad]);

  const GetListApplicants = useSelector(
    (state) => state.SearchApplicantsWithoutPaging.data
  );

  const getListApplicants = (obj) => {
    // setAlljobData([]);
    dispatch(_serapplicant.searchApplicantsWithoutPagingAsync(obj));
  };

  useEffect(() => {
    if (GetListApplicants && GetListApplicants.length > 0) {
      setAlljobData(GetListApplicants);
      setFindconfig({ ...findconfig, totalRecords: 0 });
      setTotalRecords(null);
      setSearchValue("");
      dispatch(
        _serapplicant.searchApplicantsWithoutPagingStartReducer(initalState)
      );
    } else if (GetListApplicants?.length === 0) {
      setAlljobData([]);
    }
  }, [GetListApplicants]);

  const handleChange = (event, Value) => {
    setAlljobData([]);
    setValue(Value);
    if (parseInt(Value) === 1) {
      GetData();
      // if (Value === 3) setAddNewApplicant(true);
    }
  };

  const onReceivePropsHandler = (pannel, load) => {
    // setIsRightSidePannel(pannel);
    onReceivechildProps(pannel, load);
    // setIsLoadDoc(load);
  };

  const onManualuploadHandler = () => {
    let json = {
      Applicants: { firstName: "", lastName: "", phone: "", emailId: "", AlternateContactNumber: '', resumesourceId: sourceId },
      totalExperience: null,
      educationDetails: [],
      workExp: [],
      skillDetails: [],
      certification: [],
      identifier: "",
      customFields: JSON.stringify([]),
      jobDescription:'',
      source: sourceId,
      id:0,
    }
    navigate('/ats/layout/applicants/create', { state: { applicant: json, sourceId: sourceId, uploadfile: defaultFile, isAssociate: JobId, isduplicate: false, isManual: true, associateAnyway: true } });
  }

  const onManualPopup = (isPopup, sourceId, file, obj) => {
    if(sourceId !== null && file !== null){
    setManualApplicatsConfirmPopup(isPopup);
    setSourceId(sourceId);
    setDefaultFile(file);
    if(obj !== null){
      setSkipId(obj.id);
      let json = {
        Applicants: obj.data.Applicants,
          totalExperience: obj.data.totalExperience ? obj.data.totalExperience : null,
          educationDetails: obj.data.educationDetails ? obj.data.educationDetails : [],
          workExp: obj.data.workExperiences ? obj.data.workExperiences : [],
          skillDetails: obj.data.skillDetails ? obj.data.skillDetails : [],
          certification: obj.data.certifications ? obj.data.certifications : [],
          identifier: obj.data.identifier ? obj.data.identifier : '',
          jobDescription:'',
          customFields: JSON.stringify([]),
          source: sourceId,
          id:obj.id,
          oldApplicantName:obj.oldApplicantName ? obj.oldApplicantName : '',
          applicantFiles:file,
      }
      setUpdateProfileWithNewResume(json);
      setDuplicateApplicatsConfirmPopup(true)
    }
  }
    else {
      setTwoDuplicateApplicatsConfirmPopup(true);
      setSkipId(obj.data.id);
      setSkipName(obj.data.name);
    }
  }
  
  useEffect(() => {
    if (UploadDocCode === 200) {
      navigate(`/ats/layout/applicants/documents/${skipId}`);
      dispatch(_serapplicant.uploadDocumentsStartReducer(initalState))
    }
  }, [UploadDocCode,skipId])

  return (
    <>
      { componentType === "AssociatedApplicants" && (
        <div className="tabs">
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              color: "#3B4046",
              marginTop: "-35px",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  className="tabsvalue"
                >
                  <Tab label="Add new applicant" value="3" />
                  <Tab label="AI matched applicants" value="1" />
                  <Tab label="Find applicants" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="mt-5">
                  {jobData && jobData.length > 0 ? (
                    <AssociateCards
                      data={jobData}
                      selectedItems={selectedIds}
                      searchKeyWord="Search by Applicant code, Title"
                      navigateTo="/ats/layout/applicants/view/"
                      config={aiconfig}
                      componentType="AssociatedApplicants"
                      onReceiveHandler={(Item, obj) =>
                        onActionHandler(Item, obj)
                      }
                    />
                  ) : (
                    <div>
                      <div className="nodatasection nodataSection">
                        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
                        No AI match applicants
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="mt-5">
                  {AlljobData ? (
                    <AssociateCards
                      data={AlljobData}
                      selectedItems={selectedIds}
                      searchKeyWord="Search by Applicant code, Title"
                      navigateTo="/ats/layout/applicants/view/"
                      config={findconfig}
                      componentType="AssociatedApplicants"
                      onReceiveHandler={(Item, obj) =>
                        onActionHandler(Item, obj)
                      }
                    />
                  ) : (
                    <div>
                      <div className="nodatasection nodataSection">
                        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
                        No Find Applicants
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="3">
                {/* className="pannelContent" */}
                <div>
                  <ApplicantsRenderData
                    onReceivechildProps={onReceivePropsHandler}
                    onManualPopup={onManualPopup}
                    isAssociate={JobId}
                  />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      )}
      { componentType === "Questions&Answers" && (
        <div style={{height: "calc(100vh - 459px)", overflowY: "auto"}}>
          {questionsData.length > 0 && questionsData.map((item) => {
            return (
              <div className='questionsarea'>
                <h3>{item.question}</h3>
                <p>
                  {item.answer.join(", ")}
                </p>
              </div>
            )
          })
          }
        </div>
      )}

      {/* Manual Upload popup */}
      {manualApplicatsConfirmPopup === true && (
          <section className="customModal">
            <div className="customModalDiv">
              <h1 className="modal-header">Error</h1>
              <div className="modal-body">
                <p>We are currently facing a technical glitch. Please select to upload resume manually.</p>
                <div className="modalActionBtns">
                  <Button type="button" className="cancelBtn" style={{ marginRight: "20px" }} onClick={() => { setManualApplicatsConfirmPopup(false) }}>Cancel</Button>
                  <Button type="button" variant="contained" className="saveBtn" onClick={onManualuploadHandler}>Upload Manually</Button>
                </div>
              </div>
            </div>
          </section>
        )}
          {duplicateApplicatsConfirmPopup === true && (
          <ApplicantsManualUploadPopup
            skipId={skipId}
            isAssociatedApplicant={true}
            updateProfileWithNewResume={updateProfileWithNewResume}
            sourceId={sourceId}
            defaultFile={defaultFile}
            setDuplicateApplicatsConfirmPopup={() => {
              setDuplicateApplicatsConfirmPopup(false);
              // setSkipId(null);
            }}
          />
        )}

        {twoDuplicateApplicatsConfirmPopup === true && (
          <DuplicateApplicantsManualUploadPopup
            skipId={skipId}
            skipName={skipName}
            setTwoDuplicateApplicatsConfirmPopup={() => {
              setTwoDuplicateApplicatsConfirmPopup(false);
              dispatch(_ser1.uploadResumeStartReducer(initalState))
              // setSkipId(null);
              setSkipName(null);
            }}
          />
        )}
    </>
  );
};

export default JobpostingsAssociatedApplicants;

const configuration1 = {
  recordperPage: 8,
  currentPage: 1,
  totalRecords: 0,
  limitData: 8,
};

const aiconfiguration = {
  recordperPage: 8,
  currentPage: 1,
  totalRecords: 0,
  limitData: 8,
  isSearchServer: false,
};

const findconfiguration = {
  recordperPage: 8,
  currentPage: 1,
  totalRecords: 0,
  limitData: 8,
  isSearchServer: true,
};

const configuration = {
  isSearchbar: true,
  isPaging: true,
  isDropdownActions: false,
  isCheckbox: false,
  isActions: true,
  recordperPage: 10,
  currentPage: 1,
  totalRecords: 0,
  limitData: 10,
  isCheckAlignRight: true,
  isPaginationTop: false,
  isSearchServer: false,
  isPagingServer: false,
  isPageRange:false,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["Associate Applicants", "Remove Applicants"];

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isDelete: true,
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "Applicant ID",
    mappingName: "applicantCode",
    theadClass: "text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "120px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 2,
    displayName: "Applicant Name",
    mappingName: "Applicantname",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext NameCapitalise",
    width: "270px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 3,
    displayName: "Contact Number",
    mappingName: "ContactNumber",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 5,
    displayName: "Screening Questions",
    mappingName: "screeningquestions",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext td-flex",
    width: "",
    isEdit: true,
    nagigate: null,
  },
  {
    id: 6,
    displayName: "Sourced By",
    mappingName: "createdBy",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 7,
    displayName: "Sourced From",
    mappingName: "SourcedFrom",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: "detailspage",
  },
  // {
  //   id: 8,
  //   displayName: "Last Updated",
  //   mappingName: "lastUpdated",
  //   theadClass: "text-center text-center dynatableheadtext",
  //   tbodyClass: "text-center dynatablebodytext",
  //   width: "",
  //   isEdit: true,
  //   nagigate: "detailspage",
  // },
  // {
  //   id: 9,
  //   displayName: "Candidate Status",
  //   mappingName: "candidateStatus",
  //   theadClass: "text-center text-center dynatableheadtext",
  //   tbodyClass: "text-center dynatablebodytext",
  //   width: "",
  //   isEdit: true,
  //   nagigate: "detailspage",
  // },
  {
    id: 10,
    displayName: "Action",
    mappingName: "actionsList",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: null,
  },
];

const portalsColumnsData = [
  {
    id: 1,
    displayName: "Applicant ID",
    mappingName: "applicantCode",
    theadClass: "text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "120px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 2,
    displayName: "Applicant Name",
    mappingName: "Applicantname",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext NameCapitalise",
    width: "270px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 3,
    displayName: "Contact Number",
    mappingName: "ContactNumber",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: "detailspage",
  },
  // {
  //   id: 4,
  //   displayName: "Email Id",
  //   mappingName: "emailId",
  //   theadClass: "text-center  dynatableheadtext",
  //   tbodyClass: "text-center dynatablebodytext",
  //   width: "",
  //   isEdit: true,
  //   nagigate: "detailspage",
  // },
  {
    id: 4,
    displayName: "Screening Questions",
    mappingName: "screeningquestions",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext td-flex",
    width: "",
    isEdit: true,
    nagigate: null,
  },
  {
    id: 5,
    displayName: "Sourced By",
    mappingName: "createdBy",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 6,
    displayName: "Sourced From",
    mappingName: "SourcedFrom",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: "detailspage",
  },
  // {
  //   id: 7,
  //   displayName: "Approval",
  //   mappingName: "approval",
  //   theadClass: "text-center text-center dynatableheadtext",
  //   tbodyClass: "text-center dynatablebodytext td-flex",
  //   width: "",
  //   isEdit: true,
  //   nagigate: null,
  // },
  {
    id: 8,
    displayName: "Action",
    mappingName: "actionsList",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: null,
  },
];

const ASAObj = {
  title: "Associated Applicants",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/associate background.svg`,
};

const QAObj = {
  title: "Questions and Answers",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/associate background.svg`,
};

const addNewModel = {
  url: "",
  displayName: "",
};