import CachedIcon from '@mui/icons-material/Cached';
import { Button, Grid, TextField } from "@mui/material";
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DateRangePicker } from "materialui-daterange-picker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _appSer from '../../../_redux/_services/ApplicantsSlice';
import { decrypt } from "../../../_utilities/_encryptDecryptHelper.js";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import "./applicantsdashboard.css";
import { widgetsmokeData } from "./applicantsdashboardmockdata.js";
import Widgets from "./widgets.jsx";
// import { Divider } from 'material-ui';
import { Divider } from "@material-ui/core";


const initalState = { data: null, message: '', isloader: true }

const ApplicantsDashboard = () => {

    const [startDate, setStartDate] = useState(new Date().toLocaleDateString())
    const [endDate, setEndDate] = useState(new Date().toLocaleDateString())
    const [open, setOpen] = React.useState(false);
    const [dateRange, setDateRange] = React.useState({startDate: new Date(), endDate: new Date()});
    // const [selectedDate, setSelectedDate] = useState(new Date().toLocaleDateString());
    const toggle = () => setOpen(!open);
    const today = new Date();

    const [users, setUsers] = useState([]);
    const [GetUserId, setGetUserId] = useState(null);
    const [getAttributes, setGetAttributes] = useState(null);
    const [selectCountryId, setselectCountryId] = useState(null);
    const [widgetsData, setWidgetData] = useState(JSON.parse(JSON.stringify(widgetsmokeData)));

    // for charts
    const [dateForPieChart, setDateForPieChart] = useState(new Date());
    const [dateForBarChart, setDateForBarChart] = useState(new Date());
    const [pieChartData, setPieChartData] = useState(null);
    const [barChartData, setBarChartData] = useState(null);

    const dispatch = useDispatch();
    const responseData = useSelector((state) => state.GetRequirementAssigned.data);
    const pieChartResponse = useSelector((state) => state.GetPieChartData.data);
    const barChartResponse = useSelector((state) => state.GetBarChartData.data);
    const widgetsResponse = useSelector((state) => state.GetResumeCount.data);


    useEffect(() => {
        if (GetUserId && selectCountryId && getAttributes) {
            fetchRequirementAssigned()
            onLoadWidgetHandler();
            // onPieChartHandler(dateForPieChart);
            // onBarChartHandler(dateForBarChart);
        } else {
            setGetUserId(JSON.parse(decrypt('GetUserId')));
            setselectCountryId(JSON.parse(decrypt('selectCountryId')))
            setGetAttributes(JSON.parse(localStorage.getItem('getAttributes')))
        }
    }, [GetUserId, selectCountryId, getAttributes]);

    useEffect(() => {
        if (responseData !== null && responseData !== undefined) {
            setUsers([]);
            if (responseData.length > 0) {
                let myData = JSON.parse(JSON.stringify(responseData));
                dispatch(_appSer.getRequirementAssignedStartReducer(initalState));
                setUsers(myData);
            }
        }
        else if (responseData !== null && responseData !== undefined && responseData.length === 0) {
            setUsers('null');
        }
    }, [responseData])

    useEffect(() => {
        const lastWeekStart = new Date(today);
        lastWeekStart.setDate(today.getDate() - 7);
        setStartDate(lastWeekStart.toLocaleDateString());
        setEndDate(today.toLocaleDateString());
    }, [])

    const handleDateChange = (range) => {
        setStartDate(range.startDate.toLocaleDateString())
        setEndDate(range.endDate.toLocaleDateString())
        setDateRange(range);
        // setSelectedDate(date);
        setOpen(false)
    }


    // const onPieChartHandler = (date) => {
    //     let formatDate = dayjs(date).format("YYYY-MM-DD");
    //     setDateForPieChart(formatDate);
    //     dispatch(_appSer.getPieChartDataAsync(formatDate));
    // }

    useEffect(() => {
        if (pieChartResponse !== null) {
            let data = JSON.parse(JSON.stringify(pieChartResponse))
            let newArray = [];
            data.forEach(list => {
                let obj = { value: list.resumecount, label: list.resumesource_name }
                newArray.push(obj);
            })
            setPieChartData(newArray);
        }
        else {
            setPieChartData(null);
        }
    }, [pieChartResponse])

    // const onBarChartHandler = (date) => {
    //     let formatDate = dayjs(date).format("YYYY-MM-DD");
    //     setDateForBarChart(formatDate);
    //     dispatch(_appSer.getBarChartDataAsync(formatDate));
    // }

    useEffect(() => {
        if (barChartResponse !== null) {
            let data = JSON.parse(JSON.stringify(barChartResponse))
            let newArray = [];
            data.forEach(list => {
                let obj = { value: list.statusCount, title: list.candidateStatus }
                newArray.push(obj);
            })
            setBarChartData(newArray);
        }
        else {
            setPieChartData(null);
        }
    }, [barChartResponse])

    const fetchRequirementAssigned = () => {
        setUsers([]);
        let data = {
            limit: 10,
            offSet: 1,
            userId: getAttributes[0].userId
        }
        dispatch(_appSer.getRequirementAssignedAsync(data));
    }

    const onLoadWidgetHandler = () => {
        let formatStartDate = dayjs(startDate).format("YYYY-MM-DD");
        let formatEndDate = dayjs(dateRange.endDate).format("YYYY-MM-DD");
        let Object = { 
            startDate: formatStartDate,
             endDate: formatEndDate, userId: getAttributes[0].userId }
        dispatch(_appSer.getResumeCountAsync(Object));
        dispatch(_appSer.getPieChartDataAsync(Object));
        dispatch(_appSer.getBarChartDataAsync(Object));

    }

    useEffect(() => {
        if(widgetsResponse !== null){
            let response = JSON.parse(JSON.stringify(widgetsResponse));
            if(response ){
                let widgetsObjectData = JSON.parse(JSON.stringify(widgetsmokeData));
                widgetsObjectData[0].currentValue = response.resumeData.resumesForCurrentUser;
                widgetsObjectData[0].avgValue = response.resumeData.averageResumes.toFixed(0);
                widgetsObjectData[0].highestValue = response.resumeData.highestResumes.toFixed(0);
                widgetsObjectData[1].currentValue = response.submissionData.submissionsForCurrentUser;
                widgetsObjectData[1].avgValue = response.submissionData.avgSubmissions.toFixed(0);
                widgetsObjectData[1].highestValue = response.submissionData.highestSubmissions.toFixed(0);
                widgetsObjectData[2].currentValue = response.interviewData.submissionsinInterviewForCurrentUser;
                widgetsObjectData[2].avgValue = response.interviewData.averageSubmissionsinInterview.toFixed(0);
                widgetsObjectData[2].highestValue = response.interviewData.highestSubmissionsinInterview.toFixed(0);
                widgetsObjectData[3].currentValue = response.selectedData.selectedForCurrentUser;
                widgetsObjectData[3].avgValue = response.selectedData.averageSelected.toFixed(0);
                widgetsObjectData[3].highestValue = response.selectedData.highestSelected.toFixed(0);
                // let widgetsObjectData = JSON.parse(JSON.stringify(widgetsmokeData));
                // widgetsObjectData[0].currentValue = response[0].resumesforCurrentUser;
                // widgetsObjectData[0].avgValue = response[0].averageResumes.toFixed(0);
                // widgetsObjectData[0].avgPercentage = response[0].averageResumes.toFixed(0) + "%";
                // widgetsObjectData[0].highestValue = response[0].highestResumes.toFixed(0);
                // object[0].highestPercentage = response[0].highestResumes.toFixed(0) + "%";
                setWidgetData(widgetsObjectData);
            }
        } else {
            setWidgetData(JSON.parse(JSON.stringify(widgetsmokeData)));
        }
    }, [widgetsResponse])


    return (
        <>
            <div className="dashboardSection">
                {widgetsData && widgetsData.length > 0 && (
                    <div className="dashboard_widgets">
                        <Grid container style={{ marginBottom: "20px" }} spacing={3}>
                            <Grid item xs={3}>
                                <div className="educationTermRangePicker" >
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        autoComplete="off"
                                        label="Start Date"
                                        placeholder="Start Date"
                                        style={{ width: "50%" }}
                                        name="startdate"
                                        value={startDate}
                                        // selected={selectedDate}
                                        onClick={(e) => { setOpen(true) }}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        autoComplete="off"
                                        label="End Date"
                                        placeholder="End Date"
                                        style={{ width: "50%" }}
                                        name="enddate"
                                        value={endDate}
                                        // selected={selectedDate}
                                        onClick={(e) => { setOpen(true) }}
                                    />
                                </div>
                                <DateRangePicker
                                    open={open}
                                    toggle={toggle}
                                    autoComplete="off"
                                    maxDate={today}
                                    onChange={(range) => { handleDateChange(range) }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    variant="contained"
                                    className="onHover"
                                    style={{
                                        backgroundColor: "#ebc700",
                                        color: "#3b4046",
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                        marginTop: "10px"
                                    }}
                                    onClick={onLoadWidgetHandler}
                                >
                                    Reload
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            {widgetsData.map(list => (
                                <Grid item xs={3}>
                                    <Widgets data={list} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>)}
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <div className="dashboard_widgets">
                            <div className="d-flex-around">
                                <h4 style={{ margin: 0 }}>Breakdown of Source of resumes</h4>
                                {/* <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker className="customPicker1"
                                            name="onBoardingDate*"
                                            variant="Standard"
                                            autoComplete="off"
                                            format="YYYY-MM-DD"
                                            value={dayjs(dateForPieChart)}
                                            onChange={(date) => { onPieChartHandler(date) }}
                                            style={{ width: "100%", height: "3.5rem", border: "none" }}
                                        />
                                    </LocalizationProvider>
                                    <CachedIcon style={{ color: "#757575" }} onClick={() => { onPieChartHandler(dateForPieChart) }} />
                                </div> */}
                            </div>
                            <br />

                            <Divider/>
                            <br />
                            <div style={{marginLeft:"100px"}} className='piechart'>
                            <PieChart
                                height={310}
                                width={560}
                                // colors={["#BFBFBF", "#606060", "#37323E", "#6D6A75", "#DFDFDF", "#000000", "#808080", "#404040", "#9F9F9F", "#202020"]}
                                // colors={["#89a54e", "#e96769", "#4298af", "#d88541", "#705a8b", "#4573a7", "#808080", "#95a8d0", "#9F9F9F", "#202020"]}
                                colors={["#9c9494","#8ba3bc","#9fbb7d","#dcc882","#e76f51", "#2a9d8f","#c88d68","#6e6a52", "#5c6c74", "#6c5444", "#c9c9c9"]}

                                series={[
                                    {
                                        data: pieChartData ? pieChartData : [],
                                        innerRadius: 2.5,
                                        outerRadius: 150,
                                        paddingAngle: 0.5,
                                        cornerRadius: 2,
                                        startAngle: -180,
                                        endAngle: 180,
                                        cx: 150,
                                        cy: 150,
                                    }
                                ]}
                            />
                            </div>
                        </div>
                        <div className="dashboard_widgets">
                            <div className="d-flex-around">
                                <h4 style={{ margin: 0 }}>Total Resumes uploaded vs Status</h4>
                                {/* <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker className="customPicker1"
                                            name="onBoardingDate*"
                                            variant="Standard"
                                            autoComplete="off"
                                            format="YYYY-MM-DD"
                                            value={dayjs(dateForBarChart)}
                                            onChange={(date) => { onBarChartHandler(date) }}
                                            style={{ width: "100%", height: "3.5rem", border: "none" }}
                                        />
                                    </LocalizationProvider>
                                    <CachedIcon style={{ color: "#757575" }} onClick={() => { onBarChartHandler(dateForBarChart) }} />
                                </div> */}
                            </div>
                            <br />

                            <Divider/>
                            <br />
                            <div className='barchart' >
                            <BarChart
                                dataset={barChartData ? barChartData : []}
                                xAxis={[{ scaleType: 'band', dataKey: 'title',  
                                    tickLabelStyle:{ angle: -25, textAnchor: 'end', fontSize: 12,} 
                                }]}
                                series={[{ dataKey: 'value', valueFormatter }]}
                                width={820}
                                height={300}
                                colors={["#BFBFBF", "#606060", "#37323E", "#6D6A75", "#DFDFDF", "#000000", "#808080", "#404040", "#9F9F9F", "#202020"]}
                                

                            // layout="vertical"
                            />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="dashboard_widgetslist">
                            <div className="d-flex-around">
                                <h4 style={{ margin: 0 }}>List of requirements assigned to recruiters</h4>
                                <CachedIcon style={{ color: "#757575" }} onClick={fetchRequirementAssigned} />
                            </div>
                            <br />
                            {users && users.length > 0 && (
                                <DynamicTable
                                    config={configuration}
                                    data={users}
                                    colmns={columnsData}
                                    actionDropdown={[]}
                                    actions={[]}
                                    filterValue={''}
                                    noDataText={"No Postings found"}
                                    addModel={{}}
                                    searchKeyWord=""
                                    onReceiveActionProps={(type, obj) => console.log(type, obj)}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div >
        </>
    )

}

export default ApplicantsDashboard;


// for dynamic Table config Object
const configuration = {
    isSearchbar: false,
    isPaging: false,
    isDropdownActions: false,
    isCheckbox: false,
    isActions: false,
    recordperPage: 10,
    currentPage: 1,
    totalRecords: 0,
    limitData: 20,
    isCheckAlignRight: true,
    isPaginationTop: true,
    isSearchServer: false,
    isPagingServer: false,
    isPageRange:false,
};

// for dynamic Table Headers and columns
const columnsData = [
    {
        id: 1,
        displayName: "Job Code",
        type: "Job Code",
        mappingName: "jobCode",
        theadClass: "dynatableheadtext text-center",
        tbodyClass: "dynatablebodytext text-center ",
        width: "",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    },
    {
        id: 2,
        displayName: "Job Title",
        type: "Job Code",
        mappingName: "jobTitle",
        theadClass: "dynatableheadtext text-center ",
        tbodyClass: "dynatablebodytext text-center NameCapitalise",
        width: "250px",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    },
    {
        id: 3,
        displayName: "Location",
        type: "Job Code",
        mappingName: "location",
        theadClass: "dynatableheadtext text-center",
        tbodyClass: "dynatablebodytext text-center NameCapitalise",
        width: "",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    },
    {
        id: 4,
        displayName: "Job Status",
        type: "Job Code",
        mappingName: "jobStatus",
        theadClass: "dynatableheadtext text-center",
        tbodyClass: "dynatablebodytext text-center",
        width: "",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    },
    {
        id: 5,
        displayName: "Client",
        type: "Job Code",
        mappingName: "clientName",
        theadClass: "dynatableheadtext text-center",
        tbodyClass: "dynatablebodytext text-center",
        width: "",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    }
];

const valueFormatter = (value) => `${value} uploaded`;