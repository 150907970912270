import React, { useState, useEffect } from 'react'
import { IconButton, FormControl, Input, InputAdornment, FormHelperText } from "@mui/material"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import Loader from '../../../components/Loader/Loader'
import MessageBar from '../../../components/SnackBar/MessageBar'
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import * as Yup from "yup";
import * as _ser from '../../../_redux/_services/UserManagerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { signInWithEmailAsync, getUserDetails, completePasswordChallenge, signOut } from '../../../_redux/CognitoSlice';
import { updateAuth } from '../../../_redux/AuthSlice';
import Cookies from 'js-cookie';
import './Login.css';
import { updateLoader } from '../../../_redux/LoaderSlice';
import { Encrypt, decrypt } from '../../../_utilities/_encryptDecryptHelper';

const Login = () => {
  const [remember, setRemember] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const showLoader = useSelector((state) => state.Loader.value);
  const showMessage = useSelector((state) => state.Snackbar.message);
  const showMsgType = useSelector((state) => state.Snackbar.type);
  const [serviceErrorMsg, setServiceErrorMsg] = useState('');
  const [isRemember, setIsRemember] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { data, code } = useSelector((state) => state.LoginPage);
  const GetCustDetailsObj = useSelector((state) => state.GetCustDetails.data);
  const location = useLocation();

  const [GetUserInfo, setgetUserInfo] = useState("");

  const [userDetails, setUserDetails] = useState({
    username: "",
    password: "",
  })
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [resetPasswordState, setResetPasswordState] = useState({
    isPromptOpen: false,
    userDetails: null,
    newPassword: "",
    confirmPassword: "",
    passwordPattern:"",
    error: ""
  })

  useEffect(() => {
    // localStorage.clear(); sessionStorage.clear();
    const userName = Cookies.get('userName');
    const userPassword = Cookies.get('userPassword');
      if (userName && userPassword) {
        setIsRemember(true);
        dispatch(signInWithEmailAsync(userName, userPassword,
          (userAttr) => { handleResetPasswordOpen() }
          // (userAttr) => { }  //navigate('/ats/reset-password')
        ));
      } else {
        setIsRemember(false);
      }
      setgetUserInfo(JSON.parse(decrypt("GetUserInfo")));
  }, [location])

  const handleResetPasswordOpen = () => {
    dispatch(updateLoader(false));
    setResetPasswordState({ ...resetPasswordState, isPromptOpen: true })
  };

  const handleResetPasswordClose = () => {
    setUserDetails({...userDetails, username: '', password : '' });
    setResetPasswordState({ ...resetPasswordState, isPromptOpen: false })
  };

  const updateResetPasswordError = (error) => {
    setResetPasswordState({ ...resetPasswordState, error })
  }

  // const handleResetPassword = async () => {
  //   const userInfo = await getUserDetails()
  //   try {
  //     if (resetPasswordState.newPassword === resetPasswordState.confirmPassword) {
  //       dispatch(completePasswordChallenge(resetPasswordState.newPassword, resetPasswordState.userDetails)).then(res => {
  //         dispatch(updateSnackbar({ type: 'success', message: "Password updated successfully !!!" }));
  //         handleResetPasswordClose()
  //         dispatch(updateAuth(userInfo))
  //         handleResetPasswordClose();
  //       })
  //       // navigate("/ats/layout/jobpostings/landing");
  //     } else {
  //       updateResetPasswordError("Password doesn't match !!!")
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     updateResetPasswordError(error.message)
  //   }
  // }

    const handleResetPassword = async (e) => {
      e.preventDefault();
    try {
      if (resetPasswordState.passwordPattern === "" && resetPasswordState.error === ""
          && resetPasswordState.confirmPassword !== "" && resetPasswordState.newPassword !== "") {
        await completePasswordChallenge(resetPasswordState.newPassword, resetPasswordState.userDetails)
        dispatch(updateSnackbar({ type: 'success', message: "Password updated successfully" }));
        handleResetPasswordClose();
      }
      else if(resetPasswordState.confirmPassword !== "" && resetPasswordState.newPassword !== ""){
        dispatch(updateSnackbar({ type: 'error', message: "Password doesn't match" }));
      }
      else {
        dispatch(updateSnackbar({ type: 'error', message: "Password is Required" }));
      }
    } catch (error) {
      console.log(error);
      // updateResetPasswordError(error.message)
    }
  }

  let emailRegex = /^[a-zA-Z0-9_!#$%&*+/=?`{}~^.-]+@[a-zA-Z0-9.-]+$/;

  const validationSchema = Yup.object({
    username: Yup.string().email("Please enter a valid email address").required('Email address is required').matches(emailRegex,"Invalid Email format"),
    password: Yup.string().required('Please enter a valid passsword')
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/, " "),
  });

  const formik = useFormik({
    initialValues: userDetails,
    validationSchema,
    onSubmit: async (values) => {
      handleSubmit()
      // dispatch(_ser.loginPageAsync(values));
    },
  });

  useEffect(() => {
    if (code === 200) {
      setServiceErrorMsg('');
    }
  }, [data, code])

  const onConfirmpasswordHandler = (value, name, obj) => {
    if(name === "newPassword"){
      // let passwordRegex = /^[a-zA-Z0-9_!#$%&*+/=?`{}~^.-]+@[a-zA-Z0-9.-]+$/;
      let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
      if(value.match(passwordRegex)){
        obj.passwordPattern = "";
      } else {
        obj.passwordPattern = "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      }
    } else if(name === "confirmPassword"){
      if(obj.newPassword === value){
        obj.error = "";
      } else {
        obj.error = "Password doesn't match";
      }
    }
    obj[name] = value;
    setResetPasswordState({ ...resetPasswordState, obj })
  }


  const handleSubmit = async () => {
    const { username, password } = userDetails;
    if (remember === true) {
      Cookies.set('userName', userDetails.username, { expires: 3 });
      Cookies.set('userPassword', userDetails.password, { expires: 3 });
    }
    dispatch(signInWithEmailAsync(username, password,
      (userAttr) => { handleResetPasswordOpen() }
    ));
  }

  useEffect(() => {
    if (GetCustDetailsObj && GetCustDetailsObj.length > 0) {
      dispatch(_ser.getCustDetailsStartReducer({}));
      navigate("/ats/layout/dashboard", {state: { isCognitoToken : true }});
    } else if(GetUserInfo && GetUserInfo.length > 0) {
      dispatch(_ser.getCustDetailsStartReducer({}));
      navigate("/ats/layout/dashboard", {state: { isCognitoToken : true }});
    }
  }, [GetCustDetailsObj, GetUserInfo])

  const handleChange = (e) => {
    let value = e.target.value;
    // if(e.target.name === "username"){
    //   value = value.trim()
    // }
    setUserDetails((userDetails) => ({ ...userDetails, [e.target.name]: value.trim()}));
    // setResetPasswordState((userDetails) => ({ ...resetPasswordState, [e.target.name]: e.target.value }))
    formik.values[e.target.name] = value.trim();
  }

  return (
    <>
      {isRemember === true && (
        <div className="autoLoginSection">
          <img src={process.env.PUBLIC_URL + "/assests/img/HirewingLogin.svg"} alt="hirewing logo" />
          <div><span></span></div>
        </div>
      )}
      {isRemember === false && (
        <div className='registerBackground1'>
          <div className='BGImg1'>
            <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.1.svg"} alt="" />

          </div>
          {showMessage !== "" ? <MessageBar message={showMessage} type={showMsgType} /> : ''}
          {showLoader ? <Loader /> : ''}
          <div className='BGImg2'>
            {/* <h1>LOGO</h1>
          <p>The word 'lorem' for example.</p> */}
            <img src={process.env.PUBLIC_URL + "/assests/img/BGImg2.svg"} alt="" />
          </div>
          {resetPasswordState.isPromptOpen === false ?
          <>
          <FocusError formik={formik} />
            <div className='registration-container1'>
              <div className='text-center'>
                <div> <img src={process.env.PUBLIC_URL + "/assests/img/HirewingLogin.svg"} alt="" className='hirewinglogologin' /></div>
                <br></br>
                <img src={process.env.PUBLIC_URL + "/assests/usericon1.svg"} alt="" className='usericon1' />
                <h3>Sign In</h3>
                <form onSubmit={formik.handleSubmit} className='d-flex justify-content-start flex-column text-left m-2' style={{ margin: "5% 10%" }}>
                  <div className='linewidth'>
                    <FormControl variant="standard" fullWidth>
                      <Input
                        placeholder='Email address'
                        id="input-with-icon-adornment"
                        // type="email"
                        name="username"
                        value={userDetails.username}
                        onBlur={formik.handleBlur}
                        error={formik.errors.username && formik.touched.username ? true : false}
                        onChange={handleChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={process.env.PUBLIC_URL + "/assests/usericon2.svg"} alt="user icon" />
                          </InputAdornment>
                        }
                      />
                      {formik.errors.username && formik.touched.username ? <FormHelperText className='errorHelperText'>{formik.errors.username}</FormHelperText> : ""}
                    </FormControl>
                  </div>
                  <div className='linewidth'>
                    <FormControl variant="standard" fullWidth>
                      <Input
                        placeholder='Password'
                        id="input-with-icon-adornment"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        // autoComplete="off"
                        value={userDetails.password}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.password && formik.touched.password ? true : false}
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={process.env.PUBLIC_URL + "/assests/lockicon.svg"} alt="lock icon" />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {formik.errors.password && formik.touched.password ? <FormHelperText className='errorHelperText'>{formik.errors.password}</FormHelperText> : ""}
                    </FormControl>
                  </div>
                  <div className='d-flex justify-content-between align-items-center' style={{ width: "100%" }}>
                    <div className='remforget'>
                      <input style={{ height: "17px", width: "17px" }} type="checkbox" checked={remember} onChange={(e) => { setRemember(e.target.checked) }}
                        id="rememberMe" /> <label className='rembtext' htmlFor='rememberMe' style={{ cursor: "pointer" }}>Remember me</label>
                    </div>
                    <Link to="/ats/forgotpassword" className='forgotlink'>Forgot password ?</Link>
                    {/* <Link to="/ats/reset-password" className='forgotlink'>reset Password ?</Link> */}
                  </div>
                  <div>
                         <p className='signtxt'>By signing in, I agree to the HireWing's </p>
                    <h4 ><a className='privacytxt' href="https://www.hirewing.com/general-4"  target="_blank">Privacy Policy</a></h4>
                    <p className='andtxt'>and </p>
                    <h4> <a className='termstxt' href="https://www.hirewing.com/copy-of-privacy-policy-cookies"  target="_blank">Terms & Conditions</a> </h4>
                    
                   
                  </div>
                  <div className="btnSection">
                    <button type="submit" className="btn-submitlogin m-2">
                      Sign in
                    </button>
                  </div>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <p className="errorHelperText text-center">{serviceErrorMsg}</p>
                  </div>
                </form>
              </div>
            </div>
          </> :
            <div className='registration-container1'>
              <div className='text-center'>
                <div>
                  <img src={process.env.PUBLIC_URL + "/assests/img/HirewingLogin.svg"} alt="" className='hirewinglogologin' />
                </div>
                <img src={process.env.PUBLIC_URL + "/assests/usericon1.svg"} alt="" className='usericon1' />
                <h2>New Password</h2>
                <form onSubmit={handleSubmit} className='d-flex justify-content-start flex-column text-left m-2' style={{ margin: "10%" }}>
                  <div className='linewidth'>
                    <FormControl variant="standard" fullWidth>
                      <Input
                        placeholder='New password'
                        id="input-with-icon-new-password"
                        type={showNewPassword ? "text" : "password"}
                        required
                        name="newpassword"
                        margin="dense"
                        value={resetPasswordState.newPassword}
                        onChange={(e) => onConfirmpasswordHandler(e.target.value, "newPassword", resetPasswordState)}
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={process.env.PUBLIC_URL + "/assests/lockicon.svg"} alt="lock icon" />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => setShowNewPassword(!showNewPassword)}
                            >
                              {showNewPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {resetPasswordState.passwordPattern ? <FormHelperText className='errorHelperText'>{resetPasswordState.passwordPattern}</FormHelperText> : ""}
                    </FormControl>
                  </div>
                  <div className='linewidth' style={{marginTop: "19px"}}>
                    <FormControl variant="standard" fullWidth>
                      <Input
                        placeholder='Confirm password'
                        margin="dense"
                        id="input-with-icon-confirm-password"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmpassword"
                        autoComplete="off"
                        value={resetPasswordState.confirmPassword}
                        onChange={(e) => onConfirmpasswordHandler(e.target.value, "confirmPassword", resetPasswordState)}
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={process.env.PUBLIC_URL + "/assests/lockicon.svg"} alt="lock icon" />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {resetPasswordState.error ? <FormHelperText className='errorHelperText'>{resetPasswordState.error}</FormHelperText> : ""}
                    </FormControl>
                  </div>
                  <div className="btnSection">
                    <button onClick={handleResetPassword} className="btn-submitlogin" style={{ marginTop: "15px" }}>
                      Submit
                    </button>
                    <Link type="button" onClick={handleResetPasswordClose} className='linktologin' style={{ marginTop: "30px", marginBottom: "10px" }}>
                      Back to Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          }
        </div>
      )}
    </>
  )
}

export default Login

