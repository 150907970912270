export const widgetsmokeData = [
    {
        id: 1,
        title : "Total Resume Contributed",
        currentValue: 0,
        currentTitle: "No.of profiles added by current USER",
        avgValue: "0",
        avgPercentage: "0%",
        avgTitle: "Company Avg. on No.of profiles added",
        highestValue: "0",
        highestPercentage: "0%",
        highestTitle: "Highest no.of profiles added by any individual",
        img: `${process.env.PUBLIC_URL + "/assests/TotalContributed.svg"}`
    },
    {
        id: 2,
        title : "Total submissions",
        currentValue: 0,
        currentTitle: "No.of profiles Submitted by current USER",
        avgValue: "0",
        avgPercentage: "0%",
        avgTitle: "Company Avg. on No.of profiles submitted",
        highestValue: "0",
        highestPercentage: "0%",
        highestTitle: "Highest no.of profiles submitted by any individual",
        img: `${process.env.PUBLIC_URL + "/assests/TotalSubmissions.svg"}`
    },
    {
        id: 3,
        title : "Total profiles in Interview",
        currentValue: 0,
        currentTitle: "No.of profiles in Interview Phase by current USER",
        avgValue: "0",
        avgPercentage: "0%",
        avgTitle: "Company Avg. on No.of profiles Interview Phase",
        highestValue: "0",
        highestPercentage: "0%",
        highestTitle: "Highest no.of profiles in the Interview by any individual",
        img: `${process.env.PUBLIC_URL + "/assests/TotalInterview.svg"}`
    },
    {
        id: 4,
        title : "Total Profiles selected",
        currentValue: 0,
        currentTitle: "No.of profiles Selected by current USER",
        avgValue: "0",
        avgTitle: "Company Avg. on No.of profiles Selected",
        highestValue: "0",
        avgPercentage: "0%",
        highestPercentage: "0%",
        highestTitle: "Highest no.of profiles Selected by any individual",
        img: `${process.env.PUBLIC_URL + "/assests/TotalSelected.svg"}`
    }
]