export const Labels = {
  MultipleChoice: "Multiple Choice",
  Text: "Text",
  Numeric: "Numeric",
  Integer: "Integer",
  MultiSelect: "Multi Select",
  SingleSelect: "Single Select",
  Question: "Question",
  Options: "Options",
  Type: "Type",
  LongText: "Long Text",
  ShortText: "Short Text",
}

export const Mode = {
  CREATE: "Create",
  EDIT: "Edit",
  VIEW:  "view"
}