import { Divider } from "@material-ui/core";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, ButtonGroup, FormControl, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../_redux/CognitoSlice";
import * as _ser1 from '../../../_redux/_services/ApplicantsSlice';
import * as _serapplicant from "../../../_redux/_services/ApplicantsSlice";
import * as _sercloud from "../../../_redux/_services/CloudSourcingSlice";
import * as _ser from "../../../_redux/_services/JobPostingSlice";
import { customFieldsAsync } from "../../../_redux/_services/UserManagerSlice";
import GetJobId from "../../../_utilities/GetJobId";
import { GetTimeZone } from '../../../_utilities/GetTimeZone';
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import { setBreadCrumb } from "../../../_utilities/breadCrumb";
import { defaultApplicantsData } from "../../../_utilities/defaultfieldsModel";
import AssociateCards from "../../../components/AssociateCards/AssociateCards";
import CkEditor from "../../../components/CkEditor/CkEditor";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import GenerateTemplate from "../../../components/GenerateTemplate/GenerateTemplate";
import RightSideBar from "../../../components/Rightsidebar/RightSideBar";
import ApplicantsManualUploadPopup from '../../Applicants/ApplicantsTable/ApplicantsManualUploadPopup';
import { ApplicantsRenderData } from "../../Applicants/ApplicantsTable/ApplicantsRenderData";
import DuplicateApplicantsManualUploadPopup from '../../Applicants/ApplicantsTable/DuplicateApplicantsManualUploadPopup';
import "./PostingsAssociatedApplicantsProfile.css";



const initalState = { data: null, message: "", isloader: true };

const JobpostingsAssociatedApplicantsProfile = () => {

  const [showTable, setShowTable] = useState(false);
  const [AccociatedApplicants, setAccociatedApplicants] = useState([]);
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);
  const [componentType, setComponentType] = useState("AssociatedApplicants");
  const [questionsData, setQuestionsData] = useState([]);
  const [showApplicantDetails, setShowApplicantDetails] = useState(false);
  const [getData, setGetData] = useState({});
  const [qnsCount, setQnsCount] = useState(0);

  const [portalAccociatedApplicants, setPortalAccociatedApplicants] = useState([]);
  const [config, setConfig] = useState(configuration);
  const [moduleType, setModuleType] = useState("Associated Applicants");
  const [rejectedApplicants, setRejectedApplicants] = useState([]);
  const [profileData, setProfileData] = useState("");
  const [applicantTemp, setApplicantTemp] = useState("");
  const [applicantCodes, setApplicantCodes] = useState([]);
  const [docUrl,setDocUrl] = useState("");
  const [pannelobj, setPannelobj] = useState({ title: "", description: "", bgImage: "", navigateTo:"" })
  const [isGenerateTemplate, setIsGenerateTemplate] = useState(false);
  const [jobCode, setJobCode] = useState(null);
  const [isBulkuploadResponse, setIsBulkuploadResponse] = useState(false);
  const [bulkUploadMessage, setBulkUploadMessage] = useState({});
  const [isValidUser,setIsValidUser ] = useState(false);

  const responseOfQuestionAnswer = useSelector((state) => state.GetQuestionsAnswers.data);
  const nextPathValue = useSelector((state) => state.SeletedNextPath.value);
  const bulkUploadResumeData = useSelector(store => store.BulkUploadResume.data);

  useEffect(()=>{
    if(bulkUploadResumeData){
      setIsBulkuploadResponse(true);
      setBulkUploadMessage(bulkUploadResumeData);
      console.log(bulkUploadResumeData);
    }

  },[bulkUploadResumeData])

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const dispatch = useDispatch();
  const getJobCode = GetJobId();

  useEffect(() => {
    if (getJobCode !== null) {
      FetchData();
    }
  }, [getJobCode]);

  useEffect(() => {
    if (nextPathValue === "null") {
      setShowApplicantDetails(false);
      localStorage.setItem("AssociatedMenu", "false");
      window.dispatchEvent(new Event("storage"));
    }
  }, [nextPathValue]);

  const AccociatedApplicantsData = useSelector(
    (state) => state.GetAssociatedApplicants.data
  );
  const AccociatedApplicantsDataCode = useSelector((state) => state.GetAssociatedApplicants.code);

  useEffect(() => {
    if(AccociatedApplicantsDataCode === 404){
      setIsValidUser(true)
    }
  }, [AccociatedApplicantsDataCode]);

  const FetchData = async () => {
    setAccociatedApplicants([]);
    setPortalAccociatedApplicants([]);
    setRejectedApplicants([]);
    dispatch(_ser.getAssociatedApplicantsAsync(getJobCode));
  };

  useEffect(() => {
    if (
      AccociatedApplicantsData !== null &&
      AccociatedApplicantsData !== undefined
    ) {
      if (AccociatedApplicantsData.length > 0) {
        const myData = JSON.parse(JSON.stringify(AccociatedApplicantsData));
        setQnsCount(myData[0].qnscount);
        let newArray = [];
        let newPortalArray = [];
        let newRejectedArray = [];
        if (myData !== undefined) {
          myData.forEach((e) => {
            e["isChecked"] = false;
            e["id"] = e.applicantID;
            e["percentage"] = "";
            e.updatedDate = GetTimeZone(e.updatedDate);
            e.firstName = e.firstName ? e.firstName : " ";
            e.lastName = e.lastName ? e.lastName : " ";
            e["createdBy"] = e.firstName + " " + e.lastName;
            // if (e.SourcedFrom === "Cloud Sourcing" || e.SourcedFrom === "Employee Portal" || e.SourcedFrom === "Vendor Sourcing" || e.SourcedFrom === "JobBoard Portal") {   
            if (e.status === "Pending") {   // TO BE UPDATED
              e["screeningquestions"] = `${e.anscount}/${myData[0].qnscount}`;
              newPortalArray.push(e);
              setPortalAccociatedApplicants(newPortalArray);
            }
            else if (e.status === "Rejected") {            // TO BE UPDATED
              e["screeningquestions"] = `${e.anscount}/${myData[0].qnscount}`;
              newRejectedArray.push(e);
              setRejectedApplicants(newRejectedArray);
            }
            else {
              e["screeningquestions"] = "";
              newArray.push(e);
              setAccociatedApplicants(newArray);
            }
          });
          let configure = JSON.parse(JSON.stringify(config));
          let records = moduleType === "Pending Applicants" ? newPortalArray.length : moduleType === "Rejected Applicants" ? newRejectedArray.length : newArray.length;
          configure.totalRecords =  records;
          configure.currentPage = 1;
          setConfig(configure);
          // setAccociatedApplicants(newArray);
          setComponentType("AssociatedApplicants");
          setShowTable(true);
        }
        dispatch(_ser.getAssociatedApplicantsStartReducer(initalState));
      } else {
        setAccociatedApplicants([]);
        setPortalAccociatedApplicants([]);
        setRejectedApplicants([]);
      }
    }
  }, [AccociatedApplicantsData]);

  useEffect(() => {
    if (moduleType === "Pending Applicants") {
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = portalAccociatedApplicants.length;
      configure.currentPage = 1;
      configure.isDropdownActions = true;
      setConfig(configure);
      setPortalAccociatedApplicants(portalAccociatedApplicants);
    } else if (moduleType === "Rejected Applicants") {
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = rejectedApplicants.length;
      configure.currentPage = 1;
      configure.isDropdownActions = false;
      setConfig(configure);
      setRejectedApplicants(rejectedApplicants);
    } else if (moduleType === "Associated Applicants") {
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = AccociatedApplicants.length;
      configure.currentPage = 1;
      configure.isDropdownActions = true;
      setConfig(configure);
      setAccociatedApplicants(AccociatedApplicants);
    }
  }, [moduleType])


  // for dynamic component received props
  const onReceiveActionHandler = async (type, object) => {
    if (type === "detailspage") {
      // dispatch(updateNextPath(object.Applicantname));
      // setGetData(object);
      // // setShowApplicantDetails(true);
      // sessionStorage.setItem("actualId", getJobCode);
      // sessionStorage.setItem("AssociatedId", object.applicantID); //TODO
      // localStorage.setItem("AssociatedMenu", object.Applicantname);
      // window.dispatchEvent(new Event("storage"));

      // if (moduleType === "Associated Applicants" || moduleType === "Pending Applicants") {
        setIsRightSidePannel(true);
        setComponentType("ApplicantProfile");
        setProfileData(object);
        setPannelobj({ ...pannelobj, title: `${object.Applicantname} (${object.applicantCode})`, navigateTo: `/ats/layout/applicants/view/${object.applicantID}` });
      // }
    } else if (type === "confirmDel") {
      DeleteAssociatedJobseeker(object);
    } else if (type === "viewAnswers") {
      let Obj = {
        cs_id: object.cs_id ? object.cs_id : 0,
        job_id: getJobCode,
        applicant_id: object.applicantID, //TODO
      };
      dispatch(_sercloud.getQuestionsAnswersReducerAsync(Obj));
    } else if (type === "Accept" || type === "Reject") {
      let newArray = object.map(obj => ({
        id: obj.asscoId,
        status: type === "Accept" ? "Associated" : "Rejected"
      }));
      let updateData = { data: newArray };
      dispatch(_ser.updateCandidateStatusAsync(getJobCode, updateData));
    } else if (type === "Download" || type === "download") {
      if (moduleType === "Pending Applicants") {
        setProfileData(object);
        DownloadFileFromS3(object, type);
      }
    } else if (type === "Send Email") {
      const url = window.location.pathname;
      const path = url.substring(url.lastIndexOf("/") + 1);
      if (Number.isInteger(parseInt(path))) {
        let subpathObj = JSON.parse(localStorage.getItem("subpathObj"));
        if (subpathObj && subpathObj.length > 0) {
          let filterSubObj = subpathObj.filter(x => x.id === parseInt(path) && x.type === "Job Code");
          if (filterSubObj.length > 0) {
            setJobCode(filterSubObj[0].name);
            if(object.length>1){
              // if(getData.length > 1){
                const applicantCodeArray = [];
                object.forEach((item)=>{
                 applicantCodeArray.push(item.applicantCode)
                })
              setApplicantCodes(applicantCodeArray);
              dispatch(_ser1.applicantsByIdAsync(object[0].applicantID));
            }else{

              dispatch(_ser1.applicantsByIdAsync(object[0].applicantID));
            }
            if(object[0].emailId){
              setIsGenerateTemplate(true);
            }
            // else{
            //   dispatch(updateSnackbar({ type: "error", message: "EmailId Doesn't Exists" }));
            // }
          }
        }
      }
    }else if(type === "cancelSearchResult"){
      FetchData()
    }
  };

  const ApplicantData = useSelector(state => state.ApplicantsById.data);

//   const GetData = async () => {
//     dispatch(_ser1.applicantsByIdAsync(tempApplicant));
//   };

useEffect(()=>{
  if(ApplicantData){

    setApplicantTemp(ApplicantData[0])
  }
},[ApplicantData]);

  const updateCandidateCode = useSelector((state) => state.UpdateCandidateStatus.data);
  useEffect(() => {
    if (updateCandidateCode === 200) {
      dispatch(_ser.updateCandidateStatusStartReducer(initalState))
      FetchData();
    }
  }, [updateCandidateCode]);

  useEffect(() => {
    if (responseOfQuestionAnswer && responseOfQuestionAnswer.length > 0) {
      let newObj = JSON.parse(JSON.stringify(responseOfQuestionAnswer));
      newObj.forEach(item => {
        item.answer = JSON.parse(item.answer);
      })
      setQuestionsData(newObj);
      setIsRightSidePannel(true);
      setComponentType("Questions&Answers");
      dispatch(_sercloud.getQuestionsAnswersStartReducer({}));
    }
  }, [responseOfQuestionAnswer]);

  const DeleteAssociatedJobseekercode = useSelector(
    (state) => state.DeleteAssociatedJobseeker.data
  );

  let userId = JSON.parse(decrypt('GetUserId'));

  const DeleteAssociatedJobseeker = async (object) => {
    if (object.SourcedFrom === "Cloudsourcing") {
      object["portalassociatedApplicants"] = "csassociatedApplicants";
    } else if (object.SourcedFrom === "Employee Portal") {
      object["portalassociatedApplicants"] = "eassociatedApplicants";
    } else if (object.SourcedFrom === "Vendorsourcing") {
      object["portalassociatedApplicants"] = "vsassociatedApplicants";
    } else {
      object["portalassociatedApplicants"] = "associatedApplicants";
    }
    let array = object.map((i => i.asscoId));
    let deleteIds = { ids: array};
    dispatch(_ser.deleteAssociatedJobseekerAsync(getJobCode,object[0].applicantID, deleteIds));
  };

  useEffect(() => {
    if (DeleteAssociatedJobseekercode === 200) {
      setShowTable(false);
      FetchData();
      dispatch(_ser.deleteAssociatedJobseekerStartReducer(initalState));
    }
  }, [DeleteAssociatedJobseekercode]);

  useEffect(() => {
  }, [showTable, AccociatedApplicants]);

  const onReceivePropsHandler = (pannel, load) => {
    if (load == "getFetch") {
      setIsRightSidePannel(pannel);
      FetchData();
    } else if (load === "close") {
      setIsBulkuploadResponse(pannel)
      setIsRightSidePannel(pannel);
      setComponentType("AssociatedApplicants");
      setIsGenerateTemplate(pannel);
      FetchData();
    }
  };

  const onChangeModule = (type) => {
    if (type == "Associated Applicants") {
      setModuleType("Associated Applicants")
    } else if (type == "Pending Applicants") {
      setModuleType("Pending Applicants")
    } else if (type == "Rejected Applicants") {
      setModuleType("Rejected Applicants")
    }
  }

  const docData = useSelector(state => state.Downloaddocument.data);


  const DownloadFileFromS3 = async (object, type) => {
    let docDetails = JSON.parse(object.docs);
    let Url = docDetails[0].docUrl;
    setDocUrl(Url);
    dispatch(_serapplicant.downloaddocumentAsync(object.applicantID, Url, type));
  }

  useEffect(() => {
    if (docData) {
      const link = document.createElement('a');
      link.href = docData;
      link.download = docUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(_serapplicant.downloaddocumentStartReducer(initalState));
    }
  }, [docData])



  return (
    <>
      {!showApplicantDetails && moduleType === "Associated Applicants" && !isValidUser ? (

        <div
          className="adddocumentsection"
          style={{ display: "flex", height: "34px", gap: "10px" }}
          onClick={() => setIsRightSidePannel(true)}
        >
          <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" />
          <h4 className="addnewdocumenttext">Associate Applicants</h4>
        </div>
      ) : (
        ""
      )}
      {showApplicantDetails ? (
        <div id="InnerComponents">
          {/* <ApplicantsView getData={getData} /> */}
        </div>
      ) : (
        <div className="contentSection">

          <div className="groupButtons">
            <ButtonGroup variant="contained">
              <Button className={moduleType === "Associated Applicants" ? "active" : ''} onClick={() => onChangeModule("Associated Applicants")}>Associated Applicants ({AccociatedApplicants.length})</Button>
              <Button className={moduleType === "Pending Applicants" ? "active" : ''} onClick={() => onChangeModule("Pending Applicants")}>Pending Applicants ({portalAccociatedApplicants.length})</Button>
              <Button className={moduleType === "Rejected Applicants" ? "active" : ''} onClick={() => onChangeModule("Rejected Applicants")}>Rejected Applicants ({rejectedApplicants.length})</Button>
            </ButtonGroup>
          </div>
          {moduleType === "Associated Applicants" ? (
            <>
              {AccociatedApplicants.length > 0 && (
                <DynamicTable
                  config={config}
                  data={AccociatedApplicants}
                  colmns={columnsData}
                  actionDropdown={actionsDropdownData}
                  actions={actionsList}
                  addModel={addNewModel}
                  searchKeyWord="Search by ID, Name, Contact Number"
                  noDataText="No Associated Applicant"
                  onReceiveActionProps={(type, obj) =>
                    onReceiveActionHandler(type, obj)
                  }
                />
              )}
              {AccociatedApplicants.length === 0 && (
                <DynamicTable
                  config={configuration}
                  data={AccociatedApplicants}
                  colmns={columnsData}
                  actionDropdown={actionsDropdownData}
                  actions={actionsList}
                  addModel={addNewModel}
                  searchKeyWord="Search by ID, Name, Contact Number"
                  noDataText="No Associated Applicant"
                  onReceiveActionProps={(type, obj) =>
                    onReceiveActionHandler(type, obj)
                  }
                />
              )}
            </>
          ) : moduleType === "Pending Applicants" ? (
            <>
              {portalAccociatedApplicants.length > 0 && (
                <DynamicTable
                  config={config}
                  data={portalAccociatedApplicants}
                  colmns={portalsColumnsData}
                  actionDropdown={actionsDropdownDataPending}
                  actions={actionsList}
                  addModel={addNewModel}
                  searchKeyWord="Search by ID, Name, Contact Number"
                  noDataText="No Pending Applicant"
                  onReceiveActionProps={(type, obj) =>
                    onReceiveActionHandler(type, obj)
                  }
                />
              )}
              {portalAccociatedApplicants.length === 0 && (
                <DynamicTable
                  config={config}
                  data={portalAccociatedApplicants}
                  colmns={portalsColumnsData}
                  actionDropdown={actionsDropdownDataPending}
                  actions={actionsList}
                  addModel={addNewModel}
                  searchKeyWord="Search by ID, Name, Contact Number"
                  noDataText="No Pending Applicant"
                  onReceiveActionProps={(type, obj) =>
                    onReceiveActionHandler(type, obj)
                  }
                />
              )}
            </>
          ) : moduleType === "Rejected Applicants" ? (
            <>
              {rejectedApplicants.length > 0 && (
                <DynamicTable
                  config={config}
                  data={rejectedApplicants}
                  colmns={rejectColumnsData}
                  actionDropdown={actionsDropdownDataPending}
                  actions={actionsList}
                  addModel={addNewModel}
                  searchKeyWord="Search by ID, Name, Contact Number"
                  noDataText="No Rejected Applicant"
                  onReceiveActionProps={(type, obj) =>
                    onReceiveActionHandler(type, obj)
                  }
                />
              )}
              {rejectedApplicants.length === 0 && (
                <DynamicTable
                  config={config}
                  data={rejectedApplicants}
                  colmns={rejectColumnsData}
                  actionDropdown={actionsDropdownDataPending}
                  actions={actionsList}
                  addModel={addNewModel}
                  searchKeyWord="Search by ID, Name, Contact Number"
                  noDataText="No Rejected Applicant"
                  onReceiveActionProps={(type, obj) =>
                    onReceiveActionHandler(type, obj)
                  }
                />
              )}
            </>
          ) : (" ")}

        </div>
      )}
      <div className="rightsidebarbtn">
        {isRightSidePannel && getJobCode ? (
          <RightSideBar
            componentData={
              <ComponentRenderData
                JobId={getJobCode}
                dispatch={dispatch}
                isLoad={isRightSidePannel}
                clientData={AccociatedApplicants}
                componentType={componentType}
                onReceivechildProps={onReceivePropsHandler}
                questionsData={questionsData}
                profileData={profileData}
              />
            }
            componentLayout={componentType === "AssociatedApplicants" ? ASAObj : componentType === "Questions&Answers" ? QAObj : componentType === "ApplicantProfile" ? pannelobj : ''}
            onReceiveProps={onReceivePropsHandler}
          />
        ) : (
          ""
        )}
      </div>
      {Boolean(isGenerateTemplate) && (
        <RightSideBar
          componentData={<GenerateTemplate isAssociatedProfile={true} applicantDetails={profileData} tempApplicant ={applicantTemp} applicantCodes={applicantCodes} jobCode={jobCode} onReceiveProps={onReceivePropsHandler} />}
          componentLayout={MailOBJ}
          onReceiveProps={onReceivePropsHandler}
        />
      )}
      {Boolean(isBulkuploadResponse) && (
        <div id="bulkUploadResults">
        <RightSideBar
          componentData={
            <BulkComponentRenderData
                data={bulkUploadMessage}
                onReceivechildProps={onReceivePropsHandler}
              />
             }
          componentLayout={bulkResponseObj}
          onReceiveProps={onReceivePropsHandler}
        />
        </div>
      )}
    </>
  );
};
const ComponentRenderData = ({
  dispatch,
  JobId,
  clientData,
  onReceivechildProps,
  componentType,
  isLoad,
  questionsData,
  profileData
}) => {
  const [jobData, setjobData] = useState(null);
  const [AlljobData, setAlljobData] = useState([]);
  const [selectedIds, setSelectedIds] = useState(clientData.map(x => x.applicantID)); //TODO
  const [findconfig, setFindconfig] = useState(findconfiguration);
  const [aiconfig, setaiconfig] = useState(aiconfiguration);
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = React.useState("3");
  const [config, setConfig] = useState(configuration1);
  const [totalRecords, setTotalRecords] = useState(null);
  const SearchAffinda = useSelector((state) => state.SearchAffinda.data);
  const navigate = useNavigate();
  const [manualApplicatsConfirmPopup, setManualApplicatsConfirmPopup] = useState(false);
  const [updateProfileWithNewResume, setUpdateProfileWithNewResume] = useState(null)
  const [sourceId, setSourceId] = useState(0);
  const [defaultFile, setDefaultFile] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [duplicateApplicatsConfirmPopup, setDuplicateApplicatsConfirmPopup] = useState(false);
  const [twoDuplicateApplicatsConfirmPopup, setTwoDuplicateApplicatsConfirmPopup] = useState(false);
  const UploadDocCode = useSelector(state => state.UploadDocuments.data);
  const [skipId, setSkipId] = useState(null);
  const [skipName, setSkipName] = useState(null);

  const [profileValue, setProfileValue] = useState("1");
  const [user, setUser] = useState([]);
  const [candidateStatusValues, setCandidateStatusValues] = useState([]);
  const [AuditlogData, setAuditlogData] = useState([]);
  const [profileUpdate, setProfileUpdate] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [noteType, setNoteType] = useState("Call");
  const [jobObj, setJobObj] = useState({});
  const getJobCode = GetJobId();

  const GetData = async () => {
    // let params = [], generateURL = '';
    // if (keyword !== "") {
    //   params.push(`keyword=${keyword}`)
    // }
    // generateURL = params.join("&");
    let jobIdentifier = localStorage.getItem("JobIdentifier");
    if(jobIdentifier !== "null"){
      dispatch(_ser.searchAffindaAsync(jobIdentifier));
    }
  };

  useEffect(() => {
    if (SearchAffinda !== null && SearchAffinda !== undefined) {
      setjobData(null);
      if (SearchAffinda.length > 0) {
        const myData = [];
        let applicantsData = JSON.parse(JSON.stringify(SearchAffinda));
        applicantsData.forEach((e) => {
          if (e.length > 0) {
            myData.push(...e);
          } else if (typeof e === "object" && e.id) {
            myData.push(e);
          }
        });
        setTimeout(() => {
          setjobData(myData);
        }, 100);
        // let Ids = []
        // myData.forEach(list => {
        //   let Array = clientData.filter(x => x.id === list.id);
        //   if (Array.length > 0) {
        //     Ids.push(Array[0].id);
        //   }
        // });
        // setSelectedIds(Ids);
        dispatch(_ser.searchAffindaStartReducer(initalState));
      } else {
        setjobData([]);
      }
    }
  }, [SearchAffinda]);

  const onActionHandler = (id, type) => {
    if (type === "delete") {
      DeleteAssociatedjobs(id);
    } else if (type === "add") {
      AddAssociatedjobs(id);
    } else if (type === "searchResult") {
      setSearchValue(id);
      getListApplicants(id);
    }
  };

  const DelAssociatedJobcode = useSelector(
    (state) => state.DeleteAssociatedJob.data
  );

  const DeleteAssociatedjobs = async (obj) => {
    let data = { };
    dispatch(_ser.deleteAssociatedJobAsync(JobId, obj,data));
  };
  const AllowDuplicateResume = useSelector(store => store.AddApplicants.updatedata);

  useEffect(() => {
    if (DelAssociatedJobcode === 200) {
      onReceivechildProps(true, "getFetch");
      dispatch(_ser.deleteAssociatedStartReducer(initalState));
    }
  }, [DelAssociatedJobcode]);

  const AddAssociatedjobsCode = useSelector(
    (state) => state.AddAssociatedjobs.data
  );

  let userInfo = JSON.parse(decrypt('GetUserInfo'));
  let userId = JSON.parse(localStorage.getItem("getAttributes"));

  const AddAssociatedjobs = async (obj) => {
    // obj["portalassociatedApplicants"] = "associatedApplicants";
    obj["source"] = "ATS";
    obj["submittedBy"] = userInfo[0].userId;
    obj["firstName"] = userId[0].firstName;
    obj["lastName"] = userId[0].lastName;
    obj["status"] = "Associated";
    dispatch(_ser.addAssociatedjobsAsync(JobId, obj, "applicant"));
    // let obj1 = { id: JobId, portalassociatedJobs : "associatedJobs", jobCode:obj.applicantCode }
    // dispatch(_serapplicant.addAssociatedjobsAsync(obj.id, obj1, "applicant"));
  };

  useEffect(() => {
    if (AddAssociatedjobsCode === 200) {
      dispatch(_ser.addAssociatedjobsStartReducer(initalState))
      onReceivechildProps(true, "getFetch");
    }
  }, [AddAssociatedjobsCode]);

  useEffect(() => {
    if (isLoad === true) {
      // ai matched related code comented
      // GetData();
      const url = window.location.pathname;
      const path = url.substring(url.lastIndexOf("/") + 1);
      if (Number.isInteger(parseInt(path))) {
        let subpathObj = JSON.parse(localStorage.getItem("subpathObj"));
        let filterSubObj = subpathObj.filter(x => x.id === parseInt(path) && x.type === "Job Code");
        if (filterSubObj.length > 0) {
          // setKeyword(filterSubObj[0].name);
       let jobIdentifier = localStorage.getItem("JobIdentifier");
          setKeyword(jobIdentifier);
        }
      }
    }
  }, [JobId, isLoad]);

  const GetListApplicants = useSelector(
    (state) => state.SearchApplicantsWithoutPaging.data
  );

  const getListApplicants = (obj) => {
    // setAlljobData([]);
    dispatch(_serapplicant.searchApplicantsWithoutPagingAsync(obj));
  };

  useEffect(() => {
    if (GetListApplicants && GetListApplicants.length > 0) {
      setAlljobData(GetListApplicants);
      setFindconfig({ ...findconfig, totalRecords: 0 });
      setTotalRecords(null);
      setSearchValue("");
      dispatch(
        _serapplicant.searchApplicantsWithoutPagingStartReducer(initalState)
      );
    } else if (GetListApplicants?.length === 0) {
      setAlljobData([]);
    }
  }, [GetListApplicants]);

  const handleChange = (event, Value) => {
    setAlljobData([]);
    setValue(Value);
    if (parseInt(Value) === 1) {
      GetData();
      // if (Value === 3) setAddNewApplicant(true);
    }
  };

  const onReceivePropsHandler = (pannel, load) => {
    // setIsRightSidePannel(pannel);
    onReceivechildProps(pannel, load);
    // setIsLoadDoc(load);
  };

  const onManualuploadHandler = () => {
    let json = {
      Applicants: { firstName: "", lastName: "", phone: "", emailId: "", AlternateContactNumber: '', resumesourceId: sourceId },
      totalExperience: null,
      educationDetails: [],
      workExp: [],
      skillDetails: [],
      certification: [],
      identifier: "",
      customFields: JSON.stringify([]),
      jobDescription: '',
      source: sourceId,
      id: 0,
    }
    navigate('/ats/layout/applicants/create', { state: { applicant: json, sourceId: sourceId, uploadfile: defaultFile, isAssociate: JobId, isduplicate: false, isManual: true, associateAnyway: true } });
  }

  const onManualPopup = (isPopup, sourceId, file, obj) => {
    if (sourceId !== null && file !== null) {
      setManualApplicatsConfirmPopup(isPopup);
      setSourceId(sourceId);
      setDefaultFile(file);
      if (obj !== null) {
        setSkipId(obj.id);
        let json = {
          Applicants: obj.data.Applicants,
          totalExperience: obj.data.totalExperience ? obj.data.totalExperience : null,
          educationDetails: obj.data.educationDetails ? obj.data.educationDetails : [],
          workExp: obj.data.workExperiences ? obj.data.workExperiences : [],
          skillDetails: obj.data.skillDetails ? obj.data.skillDetails : [],
          certification: obj.data.certifications ? obj.data.certifications : [],
          identifier: obj.data.identifier ? obj.data.identifier : '',
          jobDescription: '',
          customFields: JSON.stringify([]),
          source: sourceId,
          id: obj.id,
          oldApplicantName: obj.oldApplicantName ? obj.oldApplicantName : '',
          applicantFiles: file,
        }
        setUpdateProfileWithNewResume(json);
        setDuplicateApplicatsConfirmPopup(true)
      }
    }
    else {
      setTwoDuplicateApplicatsConfirmPopup(true);
      setSkipId(obj.data.id);
      setSkipName(obj.data.name);
    }
  }

  useEffect(() => {
    if(JobId){
    let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
    if (subPathObj && subPathObj.length > 0) {
      let index = subPathObj.findIndex(x => x.id === JobId);
      let jobCode = ""
      if (index !== -1) {
        jobCode = subPathObj[index].name  ;
      }
      if(jobCode){
        let splitCode = jobCode.split('(');
        let splitJobcode = splitCode[1].split(")");
        let jobCodeobj = {jobId: JobId, jobCode: splitJobcode[0]}
        setJobObj(jobCodeobj);
        console.log(splitJobcode[0]);
      }
    }
  }
  }, [JobId])

  useEffect(() => {
    if (UploadDocCode === 200) {
      navigate(`/ats/layout/applicants/documents/${skipId}`);
      dispatch(_serapplicant.uploadDocumentsStartReducer(initalState))
    }
  }, [UploadDocCode, skipId])

  ///////////////////////////////////////////////////// FOR APPLICANT PROFILE VIEW /////////////////////////////////////////


  useEffect(() => {
    let defaultPostingModules = defaultApplicantsData.filter(x => x.moduleId === "2");
    if (defaultPostingModules.length > 0) {
      defaultPostingModules.forEach(list => {
        if (list.fieldName === "Candidate status") {
          setCandidateStatusValues(list.customValues);
        }
      })
    }
  }, [])

  const customFieldsData = useSelector(state => state.CustomFields.data);

  useEffect(() => {
    let userId = JSON.parse(decrypt('GetUserId'))
    dispatch(customFieldsAsync(userId));
  }, []);

  useEffect(() => {
    if (customFieldsData && customFieldsData.length > 0) {
      const data = JSON.parse(JSON.stringify(customFieldsData));
      let customStatusFieldsData = data.filter(x => x.moduleId.toString() === "2" && x.fieldType === "default" && x.fieldName === "Candidate status");
      if (customStatusFieldsData.length > 0) {
        // customStatusFieldsData.forEach(list => {
        // if (list.fieldName === "candidateStatus") {
        //   setCandidateStatusValues(JSON.parse(list.customValues));
        // }
        let index = customStatusFieldsData.length - 1;
        let array = JSON.parse(customStatusFieldsData[index].customValues)
        setCandidateStatusValues(array);
        // })
      }
    }
  }, [customFieldsData])

  const handleChangeProfile = (event, Value) => {
    setProfileValue(Value);
  };

  useEffect(() => {
    if (profileData.applicantID) {
      Getdata();
      GetAuditdata();
      setBreadCrumb(profileData.applicantID,profileData.Applicantname, "Applicant Code");
    }
  }, [profileData])

  const Getdata = () => {
    dispatch(_serapplicant.listNotesAsync(profileData.applicantID));
  };

  const notesData = useSelector(state => state.ListNotes.data);

  useEffect(() => {
    if (notesData !== null) {
      let data = JSON.parse(JSON.stringify(notesData));
      setUser(data);
      // setIsLoadDoc(false);
      dispatch(_serapplicant.listNotesStartReducer(initalState))
    }
  }, [notesData])

  const AuditlogResponse = useSelector((state) => state.GetApplicantsComments.data);

  const GetAuditdata = async () => {
    let obj = {
      type: "Applicants",
      typeId: profileData.applicantID,
    };
    dispatch(_serapplicant.getApplicantsCommentsAsync(obj));
  };

  useEffect(() => {
    if (AuditlogResponse !== null && AuditlogResponse.length > 0) {
      let auditlog = JSON.parse(JSON.stringify(AuditlogResponse));
      auditlog.forEach(item => { item.createdAt = GetTimeZone(item.createdAt) })
      setAuditlogData(auditlog);
      dispatch(_serapplicant.getApplicantsCommentsStartReducer(initalState))
    }
  }, [AuditlogResponse])

  const onChangeHandler = (value, type) => {
    let obj = JSON.parse(JSON.stringify(initialData));
    if (type == "noteText" && value.data.trim().length !== 0) {
      obj[type] = value.data;
      localStorage.setItem("ApplicantText", JSON.stringify(obj.noteText));
    } else if (type === "noteType") {
      // obj[type] = noteType;
      setNoteType(value)
      obj.noteText = JSON.parse(localStorage.getItem("ApplicantText"));
    } else if (type === "candidateStatus") {
      setUpdateStatus(value);
    }
    setProfileUpdate(obj);
  };

  const HandleSubmit = async (event) => {
    let jobId = getJobCode;
    let updateData = {
      data: [{
          id: (profileData.asscoId).toString(),
          status: updateStatus,
          applicantCode: profileData.applicantCode,
          applicantId: profileData.applicantID.toString(),
          jobCode: jobObj.jobCode
        }]
    }
    dispatch(_ser.updateCandidateStatusAsync(jobId, updateData));
  }

  const updateCandidateCode = useSelector((state) => state.UpdateCandidateStatus.data);

  useEffect(() => {
    if (updateCandidateCode === 200) {
      dispatch(_ser.updateCandidateStatusStartReducer(initalState))
      onReceivechildProps(false, "getFetch");
    }
  }, [updateCandidateCode]);

  const HandleSubmitNote = async (event) => {
    const userInfo = await getUserDetails()
    let obj = {
      notes: profileUpdate.noteText,
      noteType: noteType,
      moduleType: "applicants",
      moduleId: profileData.applicantID,
      userId: userInfo.userId,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
    };
    dispatch(_serapplicant.addNotesAsync(obj))
    localStorage.removeItem("ApplicantText");
  };

  const code = useSelector((state) => state.AddApplicantNotes.data);

  useEffect(() => {
    if (code === 200) {
      dispatch(_serapplicant.addNotesStartReducer(initalState));
      onReceivechildProps(false, "getFetch");
      setProfileUpdate("");
    }
  }, [code])



  /////////////////////////////////////////////// FOR APPLICANT PROFILE VIEW END ////////////////////////////////////////

  return (
    <>
      {componentType === "AssociatedApplicants" && (
        <div className="tabs">
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              color: "#3B4046",
              marginTop: "-35px",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  className="tabsvalue"
                >
                  <Tab label="Add new applicant" value="3" />
                  <Tab label="AI matched applicants" value="1" />
                  <Tab label="Find applicants" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="mt-5">
                  {jobData && jobData.length > 0 ? (
                    <AssociateCards
                      data={jobData}
                      selectedItems={selectedIds}
                      searchKeyWord="Search by Applicant code, Title"
                      navigateTo="/ats/layout/applicants/view/"
                      config={aiconfig}
                      componentType="AssociatedApplicants"
                      onReceiveHandler={(Item, obj) =>
                        onActionHandler(Item, obj)
                      }
                    />
                  ) : (
                    <div>
                      <div className="nodatasection nodataSection">
                        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
                        No AI match applicants
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="mt-5">
                  {AlljobData ? (
                    <AssociateCards
                      data={AlljobData}
                      selectedItems={selectedIds}
                      searchKeyWord="Search by Applicant code, Title"
                      navigateTo="/ats/layout/applicants/view/"
                      config={findconfig}
                      componentType="AssociatedApplicants"
                      onReceiveHandler={(Item, obj) =>
                        onActionHandler(Item, obj)
                      }
                    />
                  ) : (
                    <div>
                      <div className="nodatasection nodataSection">
                        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
                        No Find Applicants
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="3">
                {/* className="pannelContent" */}
                <div>
                  <ApplicantsRenderData
                    onReceivechildProps={onReceivePropsHandler}
                    onManualPopup={onManualPopup}
                    isAssociate={jobObj}
                  />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      )}
      {/* ///////////////////////// FOR APPLICANT PROFILE VIEW //////////////////// */}
      {componentType === "ApplicantProfile" && (
        <div className="tabs">
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              color: "#3B4046",
              marginTop: "-35px",
            }}
          >
            <TabContext value={profileValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChangeProfile}
                  aria-label="lab API tabs example"
                  className="tabsvalue"
                >
                  <Tab label="Details" value="1" />
                  <Tab label="Notes" value="2" />
                  <Tab label="Audit Information" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">

                <div className='jobsdatacss'>
                  <tr>
                    <td>Applicant Name</td>
                    <td>:</td>
                    <td>{profileData.Applicantname}</td>
                  </tr>
                  <tr>
                    <td>Contact Number</td>
                    <td>:</td>
                    <td>{profileData.ContactNumber}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>{profileData.emailId}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>:</td>
                    <td>{profileData.status}</td>
                  </tr>
                  <Divider className="secline" />
                </div>

                {/* <FocusError formik={formik} /> */}
                <div className="statusdropdwn" >
                  <p>Update Status</p>
                  <div style={{ width: "25%" }}>
                    <FormControl
                      fullWidth
                    // error={
                    //   formik.errors.candidateStatus && formik.touched.candidateStatus ? true : false
                    // }
                    >
                      {/* <InputLabel className="boldSelectlabel">Candidate's Status</InputLabel> */}
                      <Select
                        value={updateStatus}
                        name="candidateStatus"
                        autoComplete="off"
                        // label="Candidate's Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        // disabled={updateStatus ? false : true}
                        onChange={(e) => onChangeHandler(e.target.value, "candidateStatus")}
                        style={{ width: "100%", height: "3rem", borderRadius: "4px" }}
                      // onFocus={formik.handleBlur}
                      >
                        {candidateStatusValues.map((name) => (
                          <MenuItem className="selectOptions" key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <FormHelperText>
                  {formik.errors.candidateStatus && formik.touched.candidateStatus
                    ? formik.errors.candidateStatus
                    : ""}
                </FormHelperText> */}
                    </FormControl>
                  </div>
                </div>
                <div className="updatebutton">
                  <Button
                    className="savebtn"
                    variant="contained"
                    type="submit"
                    disabled={updateStatus ? false : true}
                    onClick={HandleSubmit}
                  >
                    Update
                  </Button>
                </div>

                <Divider className="secline" />
                <div className="typeCss">
                  <span>Note Type</span>
                  <FormControl>
                    {/* <InputLabel  className="boldSelectlabel">Note Type</InputLabel> */}
                    <Select
                      className="typeselect"
                      label="Select Type"
                      value={noteType}
                      autoComplete="off"
                      onChange={(e) => onChangeHandler(e.target.value, "noteType")}
                    >
                      <MenuItem className="selectOptions" value={"Call"}>Call</MenuItem>
                      <MenuItem className="selectOptions" value={"Email"}>Email</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/* <div> */}
                <div id="jobDescForSMnew">
                  {/* {showEditor === true && ( */}
                  <CkEditor
                    data={profileUpdate.noteText}
                    initRawData={initialData.noteText}
                    autoComplete="off"
                    name="note"
                    isReadonly={false}
                    onUpdatehandler={(update) => onChangeHandler(update, "noteText")}
                  />
                  {/* )} */}
                </div>
                {/* </div> */}

                <div className="updatebutton1">
                  <Button
                    className="savebtn"
                    variant="contained"
                    type="submit"
                    disabled={profileUpdate.noteText && noteType ? false : true}
                    onClick={HandleSubmitNote}
                  >
                    Update
                  </Button>
                </div>

              </TabPanel>
              <TabPanel value="2">
                <div>
                  {user.length > 0 ? (
                    <>
                      {user.map((elem, i) => {
                        return (
                          <>
                            <div className="Applicantprofilearticle" style={{ position: "relative" }}>
                              <div className="usericon">
                                {elem.noteType === "Call" ? (
                                  <img src={process.env.PUBLIC_URL + "/assests/img/Notescall.svg"} alt="" />
                                ) : (
                                  <img src={process.env.PUBLIC_URL + "/assests/img/Notesemail.svg"} alt="" />
                                )}
                              </div>
                              <div className="jobnote">
                                <b>{elem.firstName + ' ' + elem.lastName}</b>
                                <div style={{ maxWidth: "calc(100% - 150px)" }} dangerouslySetInnerHTML={{ __html: elem.notes }}></div>
                                <span className="createdDate">{GetTimeZone(elem.createdAt)}</span>
                              </div>

                            </div>
                            <Divider className="secline" />
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <h1 style={{ fontWeight: 300, lineHeight: 1.2, textAlign: "center", margin: "60px 0", fontSize: "3rem" }}>No notes found</h1>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div>
                  {AuditlogData.length > 0 ? (
                    AuditlogData.map((e) => (

                      <div className='jobsdatacss'>
                        <tr>
                          <td>Name</td>
                          <td>:</td>
                          <td>{e.firstName}</td>
                        </tr>
                        <tr>
                          <td>Action Performed</td>
                          <td>:</td>
                          <td>{e.action}</td>
                        </tr>
                        <tr>
                          <td>Date and Time</td>
                          <td>:</td>
                          <td>{e.createdAt}</td>
                        </tr>
                        <tr>
                          <td>Comments</td>
                          <td>:</td>
                          <td>{e.comments}</td>
                        </tr>
                        <Divider className="secline" />
                      </div>
                    ))
                  ) : (
                    <h1 style={{ fontWeight: 300, lineHeight: 1.2, textAlign: "center", margin: "60px 0", fontSize: "3rem" }}>No auditlog found</h1>
                  )}
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      )}
      {/* ///////////////////////// FOR APPLICANT PROFILE VIEW END/////////////////////// */}
      {componentType === "Questions&Answers" && (
        <div style={{ height: "calc(100vh - 459px)", overflowY: "auto" }}>
          {questionsData.length > 0 && questionsData.map((item) => {
            return (
              <div className='questionsarea'>
                <h3>{item.question}</h3>
                <p>
                  {item.answer.join(", ")}
                </p>
              </div>
            )
          })
          }
        </div>
      )}

      {/* Manual Upload popup */}
      {manualApplicatsConfirmPopup === true && (
        <section className="customModal">
          <div className="customModalDiv">
            <h1 className="modal-header">Error</h1>
            <div className="modal-body">
              <p>We are currently facing a technical glitch. Please select to upload resume manually.</p>
              <div className="modalActionBtns">
                <Button type="button" className="cancelBtn" style={{ marginRight: "20px" }} onClick={() => { setManualApplicatsConfirmPopup(false) }}>Cancel</Button>
                <Button type="button" variant="contained" className="saveBtn" onClick={onManualuploadHandler}>Upload Manually</Button>
              </div>
            </div>
          </div>
        </section>
      )}
      {duplicateApplicatsConfirmPopup === true && (
        <ApplicantsManualUploadPopup
          skipId={skipId}
          isAssociatedApplicant={true}
          updateProfileWithNewResume={updateProfileWithNewResume}
          sourceId={sourceId}
          defaultFile={defaultFile}
          setDuplicateApplicatsConfirmPopup={(e) => {
            setDuplicateApplicatsConfirmPopup(e);
            // setSkipId(null);
          }}
        />
      )}
      {twoDuplicateApplicatsConfirmPopup === true && (
        <DuplicateApplicantsManualUploadPopup
          skipId={skipId}
          skipName={skipName}
          setTwoDuplicateApplicatsConfirmPopup={() => {
            setTwoDuplicateApplicatsConfirmPopup(false);
            dispatch(_ser1.uploadResumeStartReducer(initalState))
            // setSkipId(null);
            setSkipName(null);
          }}
        />
      )}
    </>
  );
};

const BulkComponentRenderData = ({data, onReceivechildProps}) => {
 

  return(
    <>
    <div className="tabs">
      <TabContext value={"1"}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={""}
                  aria-label="lab API tabs example"
                  className="tabsvalue"
                >
                  <Tab label="Bulk upload Results" value="1" />
                  
                </TabList>
              </Box>
              <TabPanel value="1">

              {data && (
                <div>
                <div className='jobsdatacss'>
                       <tr>
                          <td>Total Resumes</td>
                          <td>:</td>
                          <td>{data.totalResume}</td>
                        </tr>
                       <tr>
                          <td>Successful</td>
                          <td>:</td>
                          <td>{data.successful}</td>
                        </tr>
                       <tr>
                          <td>Failed</td>
                          <td>:</td>
                          <td>{data.failed}</td>
                        </tr>
                </div><br/>
                <Divider className="secline" />
            {data.data && data.data.map((msg)=>{
              return(
                <ul>
                  <li style={{textAlign: "left"}} dangerouslySetInnerHTML={{ __html: msg }}></li>
                </ul>
              )
            })}
            {/* <div style={{textAlign: "right"}}>
                <Button
                  type="button"
                  className="cancelBtn"
                  onClick={() => {
                  onReceivechildProps(false, "close");
                  }}
                    >
                      Close
                    </Button>
                  </div>                 */}
            </div>
            )}
    </TabPanel>
    </TabContext>
    </div>
    </>
  )
}

export default JobpostingsAssociatedApplicantsProfile;

const configuration1 = {
  recordperPage: 8,
  currentPage: 1,
  totalRecords: 0,
  limitData: 8,
};

const aiconfiguration = {
  recordperPage: 8,
  currentPage: 1,
  totalRecords: 0,
  limitData: 8,
  isSearchServer: false,
};

const findconfiguration = {
  recordperPage: 8,
  currentPage: 1,
  totalRecords: 0,
  limitData: 8,
  isSearchServer: true,
};

const configuration = {
  isSearchbar: true,
  isPaging: true,
  isDropdownActions: true,
  isCheckbox: true,
  isActions: false,
  recordperPage: 10,
  currentPage: 1,
  totalRecords: 0,
  limitData: 10,
  isCheckAlignRight: true,
  isPaginationTop: false,
  isSearchServer: false,
  isPagingServer: false,
  isPageRange:false,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["Delete", "Send Email" ];  //  ""
const actionsDropdownDataPending = ["Accept", "Reject"];

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isDelete: false,
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "Applicant ID",
    mappingName: "applicantCode",
    theadClass: "text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext textUnderline",
    width: "90px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 2,
    displayName: "Applicant Name",
    mappingName: "Applicantname",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext NameCapitalise",
    width: "230px",
    isEdit: false,
    nagigate: "detailspage",
  },
  {
    id: 3,
    displayName: "Contact Number",
    mappingName: "ContactNumber",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: "detailspage",
  },
  {
    id: 4,
    displayName: "Last Updated",
    mappingName: "updatedDate",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: "detailspage",
  },
  {
    id: 5,
    displayName: "Sourced From",
    mappingName: "SourcedFrom",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: "detailspage",
  },
  {
    id: 6,
    displayName: "Candidate Status",
    mappingName: "status",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: "detailspage",
  },
  // {
  //   id: 7,
  //   displayName: "Percentage",
  //   mappingName: "percentage",
  //   theadClass: "text-center text-center dynatableheadtext",
  //   tbodyClass: "text-center dynatablebodytext",
  //   width: "",
  //   isEdit: false,
  //   nagigate: "detailspage",
  // },
];

const portalsColumnsData = [
  {
    id: 1,
    displayName: "Applicant ID",
    mappingName: "applicantCode",
    theadClass: "text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext textUnderline",
    width: "90px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 2,
    displayName: "Applicant Name",
    mappingName: "Applicantname",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext NameCapitalise",
    width: "210px",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 3,
    displayName: "Contact Number",
    mappingName: "ContactNumber",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 4,
    displayName: "Last Updated",
    mappingName: "updatedDate",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 5,
    displayName: "Sourced By",
    mappingName: "createdBy",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 6,
    displayName: "Sourced From",
    mappingName: "SourcedFrom",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 7,
    displayName: "Screening Questions",
    mappingName: "screeningquestions",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext td-flex",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  // {
  //   id: 8,
  //   displayName: "Percentage",
  //   mappingName: "percentage",
  //   theadClass: "text-center text-center dynatableheadtext",
  //   tbodyClass: "text-center dynatablebodytext",
  //   width: "",
  //   isEdit: false,
  //   nagigate: null,
  // },
];
const rejectColumnsData = [
  {
    id: 1,
    displayName: "Applicant ID",
    mappingName: "applicantCode",
    theadClass: "text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext textUnderline",
    width: "90px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 2,
    displayName: "Applicant Name",
    mappingName: "Applicantname",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext NameCapitalise",
    width: "210px",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 3,
    displayName: "Contact Number",
    mappingName: "ContactNumber",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 4,
    displayName: "Last Updated",
    mappingName: "updatedDate",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 5,
    displayName: "Sourced By",
    mappingName: "createdBy",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 6,
    displayName: "Sourced From",
    mappingName: "SourcedFrom",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  {
    id: 7,
    displayName: "Screening Questions",
    mappingName: "screeningquestions",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext td-flex",
    width: "",
    isEdit: false,
    nagigate: null,
  },
  // {
  //   id: 8,
  //   displayName: "Percentage",
  //   mappingName: "percentage",
  //   theadClass: "text-center text-center dynatableheadtext",
  //   tbodyClass: "text-center dynatablebodytext",
  //   width: "",
  //   isEdit: false,
  //   nagigate: null,
  // },
];

const ASAObj = {
  title: "Associated Applicants",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/associate background.svg`,
};

const QAObj = {
  title: "Questions and Answers",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/associate background.svg`,
};

const bulkResponseObj = {
  title: "Bulk Upload",
  description: "",
  bgImage: ``,
};

const addNewModel = {
  url: "",
  displayName: "",
};

const initialData = {
  noteType: "",
  noteText: ""
}

const MailOBJ = {
  title: "Email",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/emailbackground.svg`,
};