import React from 'react';
import { useDispatch } from 'react-redux';
// pages
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../../_redux/CognitoSlice';
import * as _ser from '../../../_redux/_services/ApplicantsSlice';
import { setBreadCrumb } from '../../../_utilities/breadCrumb';
import './ApplicantsTable.css';

const initalState = { data: null, message: '', isloader: true }

const ApplicantsManualUploadPopup = ({ skipId, isAssociatedApplicant, updateProfileWithNewResume, sourceId, defaultFile, applicantCode, setDuplicateApplicatsConfirmPopup }) => {

    // const [updateProfileWithNewResume, setUpdateProfileWithNewResume] = useState(null)
    // const [duplicateApplicatsConfirmPopup, setDuplicateApplicatsConfirmPopup] = useState(false);
    // const [skipId, setSkipId] = useState(skipId);
    // const [sourceId, setSourceId] = useState(0);
    // const [defaultFile, setDefaultFile] = useState(null)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const onDuplicateUploadHandler = async (type) => {
        if (type === "update" && updateProfileWithNewResume !== null) {
            setDuplicateApplicatsConfirmPopup(false);
            let subpathName = `${updateProfileWithNewResume.Applicants.firstName} ${updateProfileWithNewResume.Applicants.lastName}`;
            setBreadCrumb(skipId, subpathName, "Applicant Code");
            let obj = updateProfileWithNewResume;
            obj["sourceId"] = sourceId;
            obj["applicantFiles"] = defaultFile;
            obj["applicantCode"] = applicantCode;
            delete obj.oldApplicantName;
            navigate(`/ats/layout/applicants/edit/${skipId}`, {
                state: { applicant: obj, isOverwriteEdit: true }
            });
        }
        else if (type === "add" && skipId !== null) {
            setDuplicateApplicatsConfirmPopup(false);
            let subpathName = `${updateProfileWithNewResume.Applicants.firstName} ${updateProfileWithNewResume.Applicants.lastName}`;
            // updateProfileWithNewResume.oldApplicantName ? updateProfileWithNewResume.oldApplicantName : 
            setBreadCrumb(skipId, subpathName, "Applicant Code");
            const userInfo = await getUserDetails();
            let formData = new FormData();
            formData.append("applicantFiles", defaultFile);
            formData.append("id", skipId);
            formData.append("userId", userInfo.userId);
            dispatch(_ser.uploadDocumentsAsync(skipId, formData, true));
        }
        else if (type === "skip" && skipId !== null) {
            setDuplicateApplicatsConfirmPopup(true);
            let subpathName = `${updateProfileWithNewResume.Applicants.firstName} ${updateProfileWithNewResume.Applicants.lastName}`;
            // updateProfileWithNewResume.oldApplicantName ? updateProfileWithNewResume.oldApplicantName : 
            setBreadCrumb(skipId, subpathName, "Applicant Code");
            // navigate(`/ats/layout/applicants/view/${skipId}`);
            window.open(`/ats/layout/applicants/view/${skipId}`, '_blank');
        }
        else if (type === "duplicate" && updateProfileWithNewResume !== null) {
            setDuplicateApplicatsConfirmPopup(false);
            dispatch(_ser.addApplicantsStartReducer());
            navigate('/ats/layout/applicants/create-duplicate', {
                state: {
                    applicant: updateProfileWithNewResume, associateAnyway: isAssociatedApplicant,
                    sourceId: sourceId, uploadfile: defaultFile, isAssociate: location.state.isAssociate, isduplicate: true
                }
            });
        }
    }

    return (
        <section className="duplicatecustomModal">
            <div className="duplicatecustomModalDiv">
                <h1 className="modal-header">Duplicate Upload
                    <span onClick={() => { setDuplicateApplicatsConfirmPopup(false) }}>
                        <CloseIcon style={{ color: "#ff0000" ,cursor:"pointer" }} />
                    </span>
                </h1>
                <div className="duplicatemodal-body">
                    <h4>Please select from the following</h4>
                    <div className='duplicateapplicanttabs'>
                        <div onClick={() => { onDuplicateUploadHandler("update") }}><p>Update Profile <br />with new resume</p></div>
                        <div onClick={() => { onDuplicateUploadHandler("add") }}><p>Add resume <br />on current profile</p></div>
                        <div onClick={() => { onDuplicateUploadHandler("duplicate") }}><p>Upload anyways<br />with duplicate profile</p></div>
                    </div>
                    <div className="modalActionBtns">
                        <Button type="button" className="cancelBtn" style={{ marginRight: "20px", width: "170px" }} onClick={() => { onDuplicateUploadHandler("skip") }}>
                            View existing profile
                        </Button>
                        {/* <Link to={ componentLayout.navigateTo } className="cancelBtn"  style={{ marginRight: "20px", width: "170px" }} target='_blank' type="button">
                        View existing profile
            </Link> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ApplicantsManualUploadPopup