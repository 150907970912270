import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as _props from "../../../_redux/_services/JobPostingSlice";
import GetJobId from "../../../_utilities/GetJobId";
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import PostingsPortals from "../PostingsPortals/PostingsPortals";

const initalState = { data: null, message: "", isloader: true };

const PostingsPortalsEdit = () => {
  const [listOfPF, setListOfPF] = useState(JSON.parse(JSON.stringify(listOfPlatform)));
  const [flatFee, setFlatFee] = useState("");
  const [flatFeeCountryId, setFlatFeeCountryId] = useState("");
  const [defaultflatFee, setDefaultFlatFee] = useState("0");
  const [defaultflatFeeCountryId, setDefaultFlatFeeCountryId] = useState("0");
  const [jobId, setJobId] = useState(null);
  const [ updateData, setUpdateData ] = useState([]);
  const [ GetUserInfo, setGetUserInfo ] = useState("");

  const getDetails = useSelector((state) => state.Jobpostingsbyid.data);
  const updateCode = useSelector((state) => state.Editjobpostings.data);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getJobCode = GetJobId();

  const updateHandler = (val) => {
    let finalData = JSON.parse(JSON.stringify(updateData));
    finalData.flatFee = val;
    setUpdateData(finalData);
    setFlatFee(val);
  };

  const updateCountryHandler = (val) => {
    let finalData = JSON.parse(JSON.stringify(updateData));
    finalData.flatFeeCountryId = val;
    setUpdateData(finalData);
    setFlatFeeCountryId(val);
  };



  useEffect(() => {
    if (GetUserInfo && GetUserInfo.length > 0) {
      let userDetails = JSON.parse(JSON.stringify(GetUserInfo))[0];
      let val = userDetails.flatFee !== null && userDetails.flatFee !== 0 ? userDetails.flatFee : "";
      let CountryVal = userDetails.flatFeeCountryId !== null && userDetails.flatFeeCountryId !== 0 ? userDetails.flatFeeCountryId : "";
      setDefaultFlatFee(val);
      setDefaultFlatFeeCountryId(CountryVal)
    } else {
      setGetUserInfo(JSON.parse(decrypt("GetUserInfo")))
    }
  }, [GetUserInfo]);

  const onChangePlatformHandler = (e, item) => {
    let data = JSON.parse(JSON.stringify(listOfPF));
    let finalData = JSON.parse(JSON.stringify(updateData));
    let Index = data.findIndex((x) => x.id === item.id);
    if (Index !== -1) {
      data[Index].checked = e.target.checked;
      data[Index].value = e.target.checked ? 1 : 0;
      setListOfPF(data);
    }
    finalData[item.name] = e.target.checked ? 1 : 0;
    setUpdateData(finalData);
  };

  // getData
  useEffect(() => {
    if (getJobCode) {
      dispatch(_props.jobpostingsbyidAsync(getJobCode));
    }
  }, [getJobCode]);

  useEffect(() => {
    if (getDetails !== null && getDetails !== undefined) {
      let postingData = JSON.parse(JSON.stringify(getDetails));
      let portals = JSON.parse(JSON.stringify(listOfPF));
      portals[0].value = postingData.CSPortal;
      portals[0].checked = (parseInt(postingData.CSPortal) === 1) ? true : false;
      portals[1].value = postingData.VSPortal;
      portals[1].checked = (parseInt(postingData.VSPortal) === 1) ? true : false;
      portals[2].value = postingData.EPortal;
      portals[2].checked = (parseInt(postingData.EPortal) === 1) ? true : false;
      portals[3].value = postingData.JBPortal;
      portals[3].checked = (parseInt(postingData.JBPortal) === 1) ? true : false;
      postingData.flatFee = postingData?.flatFee && (parseInt(postingData.CSPortal) === 1) ? postingData.flatFee : defaultflatFee;
      postingData.flatFeeCountryId = postingData?.flatFeeCountryId && (parseInt(postingData.CSPortal) === 1) ? postingData.flatFeeCountryId : defaultflatFeeCountryId;
      setJobId(postingData.id);
      setFlatFee(postingData?.flatFee && (parseInt(postingData.CSPortal) === 1) ? postingData.flatFee : defaultflatFee);
      setFlatFeeCountryId(postingData?.flatFeeCountryId && (parseInt(postingData.CSPortal) === 1) ? postingData.flatFeeCountryId : defaultflatFeeCountryId);
      setUpdateData(postingData);
      setListOfPF(portals);
      dispatch(_props.jobpostingsbyidStartReducer(initalState));
    }
  }, [getDetails]);

  const onSavePortalHandler = async () => {
      dispatch(_props.editjobpostingsAsync(jobId, updateData));
  };


  useEffect(() => {
    if (updateCode === 200) {
      dispatch(_props.editjobpostingsStartReducer(initalState));
      navigate(`/ats/layout/jobpostings/portals/view/${jobId}`)
    }
  }, [updateCode, dispatch]);

  return (
    <>
    <div className="actionBtnRtl1"> 
    <Button
        variant="text"
        className="buttonHover"
        style={{ color: "red", marginRight: "25px", fontWeight: "bolder" }}
        onClick={() => {navigate(`/ats/layout/jobpostings/portals/view/${jobId}`)}}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        className="buttonHover1"
        style={{ backgroundColor: "#ebc700 ", color: "black", borderRadius: "25px", width: "6rem", textTransform: "capitalize" }}
        onClick={onSavePortalHandler}
      >
        Save
      </Button>
      </div>
      <PostingsPortals
        componentType="Edit"
        onReceiveHandler={onChangePlatformHandler}
        data={listOfPF}
        flatFee={flatFee}
        flatFeeCountryId={flatFeeCountryId}
        updateHandler={updateHandler}
        updateCountryHandler={updateCountryHandler}
      />
    </>
  );
};

export default PostingsPortalsEdit;

const listOfPlatform = [
  {
    id: 1,
    name: "CSPortal",
    title: "Cloud Sourcing Platform",
    checked: false,
    value: 0,
  },
  {
    id: 2,
    name: "VSPortal",
    title: "Vendor Sourcing Platform",
    checked: false,
    value: 0,
  },
  {
    id: 3,
    name: "EPortal",
    title: "Employee Platform",
    checked: false,
    value: 0,
  },
  {
    id: 4,
    name: "JBPortal",
    title: "Job Board Platform",
    checked: false,
    value: 0,
  },
];
