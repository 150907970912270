import { createSlice } from "@reduxjs/toolkit";
import * as _msg from "../../_utilities/message";
import { updateLoader } from "../LoaderSlice";
import { updateSnackbar } from "../SnackbarSlice";
import * as _ser from '../_apiHelpers/ApplicantsApihelper';

const initalState = { data: null, updatedata:null, message: '', id: null, code: null, oldApplicantName: null, isDuplicats:null }
const initialSnackbar = { type: "", message: "" };

export const uploadResumeSlice = createSlice({
    name: "UploadResume",
    initialState: initalState,
    reducers: {
        uploadResumeStartReducer: (state, action) => {
            return { ...state, data: null, code: null, updatedata: null, oldApplicantName:null, isDuplicats:null }
        },
        uploadResumeReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data && action.payload.data.code !== 500 && action.payload.data.code !== 400) {
                    return { ...state, data: [action.payload.data], updatedata: null, message: action.payload.data.message, oldApplicantName: null, isDuplicats:null };
                }
                else if (action.payload.data && action.payload.data.code === 400) {
                    return { ...state, message: action.payload.data.message, data: null, code: action.payload.data.code, id: action.payload.data.id, updatedata:null, isDuplicats:null }
                }
                else if (action.payload.data && action.payload.data.code === 500) {
                    if( action.payload.data.message === "Applicant Already Exists in DB"){
                        return { ...state, message: action.payload.data.message,
                            updatedata: [{
                                    Applicants : action.payload.data.Applicants,
                                    certifications : action.payload.data.certifications,
                                    educationDetails : action.payload.data.educationDetails,
                                    identifier : action.payload.data.identifier,
                                    skillDetails : action.payload.data.skillDetails,
                                    totalExperience : action.payload.data.totalExperience,
                                    workExperiences : action.payload.data.workExperiences,
                                }],
                            code: action.payload.data.code,
                            id: action.payload.data.id,
                            oldApplicantName: action.payload.data.oldApplicantName,
                            isDuplicats:null
                        }
                    } else if(action.payload.data.message.includes("Applicant Already Exists In Database With Applicant Code") ){
                        return { ...state,
                                message: action.payload.data.message,
                                data: null,
                                id: null,
                                code: null,
                                updatedata:null,
                                isDuplicats: action.payload.data.duplicateObj
                            }
                    }
                     else {
                        return { ...state, message: action.payload.data.message, data: null, code: action.payload.data.code, updatedata:null }
                    }
                }
            } else {
                state = { ...state, updatedata:null, message: action.payload.data.message, data: null, oldApplicantName: null, code:null }
            }
        }
    }
});
export const { uploadResumeReducer, uploadResumeStartReducer } = uploadResumeSlice.actions;
export const uploadResumeAsync = (data, isFormData) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.UploadResume(data, isFormData);
    dispatch(uploadResumeReducer(res));
    dispatch(updateLoader(false));
    {
        res.data.code === 500 && (
            dispatch(updateSnackbar({ type: "error",
                message: (res.data.message.includes("Applicant Already Exists In Database With Applicant Code")) ? "" : res.data.message
            }))
        )
    }
}

export const BulkUploadResumeSlice = createSlice({
    name: "BulkUploadResume",
    initialState: initalState,
    reducers: {
        bulkUploadResumeStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        bulkUploadResumeReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data, code: action.payload.data.code, message: action.payload.data.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { bulkUploadResumeReducer, bulkUploadResumeStartReducer } = BulkUploadResumeSlice.actions;
export const bulkUploadResumeAsync = (data, isFormData, jobObj) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.BulkUploadResume(data, isFormData, jobObj);
    dispatch(bulkUploadResumeReducer(res));
    dispatch(updateLoader(false));

    let msgArray = res.data.data;
    // let successArray = msgArray.filter(msg =>
    //     msg.includes("Added Successfully")
    // )
    // let duplicateArray = msgArray.filter(msg =>
    //     msg.includes("Duplicate Resume with Parser")
    // )
    // console.log(successArray.length, duplicateArray.length);
    // let alertMessage = `${successArray.length} successfully parsed and ${duplicateArray.length} duplicate profile`
    // msgArray.forEach((msg)=>{
            dispatch(updateSnackbar({
                type: res.status === 200 ? "success" : "error",
                message: res.status === 200 ? "" : res.data.message
            }));
    // })
}


export const duplicateResumeSlice = createSlice({
    name: "DuplicateResume",
    initialState: initalState,
        reducers: {
        duplicateResumeStartReducer: (state, action) => {
            return { ...state, data: null, code: null, updatedata:null, }
        },
        duplicateResumeReducer: (state, action) => {
            if (action.payload.status === 200) {
                return { ...state,
                            code: action.payload.data.code,
                            id: action.payload.data.insertId,
                            message: action.payload.data.message,
                            data: {
                                code : action.payload.data.code,
                                insertId : action.payload.data.insertId,
                                latestapplicantcode : action.payload.data.latestapplicantcode,
                            }
                        };
            } else {
                state = { ...state, updatedata:null, message: action.payload.data.message, data: null, oldApplicantName: null }
            }
        }
    }
});
export const { duplicateResumeReducer, duplicateResumeStartReducer } = duplicateResumeSlice.actions;
export const duplicateResumeAsync = (data, isFormData) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.DuplicateResume(data, isFormData);
    dispatch(duplicateResumeReducer(res));
    dispatch(updateLoader(false));
    {
        res.data.code === 500 && (
            dispatch(updateSnackbar({ type: "error", message: res.data.message }))
        )
    }
}

export const addApplicantsSlice = createSlice({
    name: "AddApplicants",
    initialState: initalState,
    reducers: {
        addApplicantsStartReducer: (state, action) => {
            return { ...state, data: null, code: null, updatedata:null }
        },
        addApplicantsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data && action.payload.data.message !== "Applicant Already Exists in DB") {
                    return { ...state,
                            data: {
                                insertedid : action.payload.data?.insertId,
                                jobCode: '',
                                latestapplicantcode: action.payload.data?.latestapplicantcode
                            },
                            code: action.payload.data?.code,
                            message: action.payload.message, updatedata: null };
                } else {
                    return { ...state,
                            updatedata : {
                                code: action.payload.data.code,
                                id: action.payload.data.id,
                                applicantCode: action.payload.data?.applicantCode,
                                data: null,
                                message: action.payload.data.message
                            }
                        };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null, updatedata: null }
            }
        }
    }
});
export const { addApplicantsReducer, addApplicantsStartReducer } = addApplicantsSlice.actions;
export const addApplicantsAsync = (data, isFormData) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.AddApplicants(data, isFormData);
    dispatch(addApplicantsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 && res.data.message !== "Applicant Already Exists in DB" ? "success" : "error",
            message: res.status === 200 && res.data.message !== "Applicant Already Exists in DB" ? _msg.ADDNEWAPPLICANT_SUCCESS_MSG :
                    res.data.message === "Applicant Already Exists in DB" ? res.data.message : _msg.ADDNEWAPPLICANT_ERROR_MSG
        }));
    }, 1000);
}


export const addApplicantWithAssocatedSlice = createSlice({
    name: "AddApplicantWithAssocated",
    initialState: initalState,
    reducers: {
        addApplicantWithAssocatedStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        addApplicantWithAssocatedReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: { insertedid : action.payload.data?.insertedid, jobCode : action.payload.data?.jobCode, latestapplicantcode: action.payload.data?.latestapplicantcode}, code: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { addApplicantWithAssocatedReducer, addApplicantWithAssocatedStartReducer } = addApplicantWithAssocatedSlice.actions;
export const addApplicantWithAssocatedAsync = (data, id, isFormData) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.AddapplicantsForAssociatedJobs(data, id, isFormData);
    dispatch(addApplicantWithAssocatedReducer(res));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.ASSOCIATE_APPLICANT_SUCCESS_MSG : _msg.ADDNEWAPPLICANT_ERROR_MSG
        }));
    }, 1000);
}


export const applicantsByIdSlice = createSlice({
    name: "ApplicantsById",
    initialState: initalState,
    reducers: {
        applicantsByIdStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        applicantsByIdReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data[0]) {
                    return { ...state, data: action.payload.data?.data[0], message: action.payload.message };
                }
                else if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { applicantsByIdReducer, applicantsByIdStartReducer } = applicantsByIdSlice.actions;
export const applicantsByIdAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.ApplicantsById(data);
    dispatch(applicantsByIdReducer(res));
    dispatch(updateLoader(false));
}


export const editApplicantsSlice = createSlice({
    name: "EditApplicants",
    initialState: initalState,
    reducers: {
        editApplicantsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        editApplicantsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { editApplicantsReducer, editApplicantsStartReducer } = editApplicantsSlice.actions;
export const editApplicantsAsync = (id, data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.EditApplicants(id, data);
    dispatch(editApplicantsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.UPDATEAPPLICANT_SUCCESS_MSG : _msg.UPDATEAPPLICANT_ERROR_MSG
        }));
    }, 500);
}


export const getApplicantJobsSlice = createSlice({
    name: "GetApplicantJobs",
    initialState: initalState,
    reducers: {
        getApplicantJobsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        getApplicantJobsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data.associatedjobs) {
                    return { ...state, data: action.payload.data.associatedjobs, message: action.payload.message };
                } else {
                    return { ...state, data: null, message: action.payload.message, code: action.payload.data.code };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getApplicantJobsReducer, getApplicantJobsStartReducer } = getApplicantJobsSlice.actions;
export const getApplicantJobsAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetApplicantJobs(id);
    dispatch(getApplicantJobsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 && res.data?.associatedjobs ? "" : res.data.code ? "success" : "error",
            message: res.data?.associatedjobs?.length > 0 ? "" : ""
        }));
    }, 500);
}


export const deleteApplicantJobsSlice = createSlice({
    name: "DeleteApplicantJobs",
    initialState: initalState,
    reducers: {
        deleteApplicantJobsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        deleteApplicantJobsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { deleteApplicantJobsReducer, deleteApplicantJobsStartReducer } = deleteApplicantJobsSlice.actions;
export const deleteApplicantJobsAsync = (id, data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.DeleteApplicantJobs(id, data);
    dispatch(deleteApplicantJobsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.ASSOCIATEJOBREMOVE_SUCCESS_MSG : _msg.ASSOCIATEJOBREMOVE_ERROR_MSG
        }));
    }, 1000);
}

export const getMatchAssociatedJobsSlice = createSlice({
    name: "GetMatchAssociatedJobs",
    initialState: initalState,
    reducers: {
        getMatchAssociatedJobsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        getMatchAssociatedJobsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.AssociatedJobs, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getMatchAssociatedJobsReducer, getMatchAssociatedJobsStartReducer } = getMatchAssociatedJobsSlice.actions;
export const getMatchAssociatedJobsAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetMatchAssociatedJobs(id);
    dispatch(getMatchAssociatedJobsReducer(res));
    dispatch(updateLoader(false));
}

export const deleteAssociatedjobsSlice = createSlice({
    name: "DeleteAssociatedjobs",
    initialState: initalState,
    reducers: {
        deleteAssociatedjobsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        deleteAssociatedjobsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { deleteAssociatedjobsReducer, deleteAssociatedjobsStartReducer } = deleteAssociatedjobsSlice.actions;
export const deleteAssociatedjobsAsync = (jobid, obj) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.DeleteAssociatedjobs(jobid, obj);
    dispatch(deleteAssociatedjobsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", 
        message: res.status === 200 ? _msg.ASSOCIATEJOBDESELECT_SUCCESS_MSG : res.data.message
        // message: res.data.message 
    }));
    }, 1000);
}


export const addAssociatedjobsSlice = createSlice({
    name: "AddAssociatedjobs",
    initialState: initalState,
    reducers: {
        addAssociatedjobsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        addAssociatedjobsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { addAssociatedjobsReducer, addAssociatedjobsStartReducer } = addAssociatedjobsSlice.actions;
export const addAssociatedjobsAsync = (JobId, obj, type) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.AddAssociatedjobs(JobId, obj);
    dispatch(addAssociatedjobsReducer(res));
    dispatch(updateLoader(false));
    if(type === "posting"){
        setTimeout(() => {
            dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", 
            message: res.status === 200 ? _msg.ASSOCIATEJOB_SUCCESS_MSG : res.data.message
        }));  //res.data.message 
        }, 1200);
    }
}


export const getDocumentsSlice = createSlice({
    name: "GetDocuments",
    initialState: initalState,
    reducers: {
        getDocumentsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        getDocumentsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data, code: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getDocumentsReducer, getDocumentsStartReducer } = getDocumentsSlice.actions;
export const getDocumentsAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetDocuments(id);
    dispatch(getDocumentsReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const deleteDocumentsSlice = createSlice({
    name: "DeleteDocuments",
    initialState: initalState,
    reducers: {
        deleteDocumentsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        deleteDocumentsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { deleteDocumentsReducer, deleteDocumentsStartReducer } = deleteDocumentsSlice.actions;
export const deleteDocumentsAsync = (jobid, id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.DeleteDocuments(jobid, id);
    dispatch(deleteDocumentsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {

        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.DELETEDOCUMENT_SUCCESS_MSG : _msg.DELETEDOCUMENT_ERROR_MSG
        }));
    }, 0);
}


export const downloaddocumentSlice = createSlice({
    name: "Downloaddocument",
    initialState: initalState,
    reducers: {
        downloaddocumentStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        downloaddocumentReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.path, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { downloaddocumentReducer, downloaddocumentStartReducer } = downloaddocumentSlice.actions;
export const downloaddocumentAsync = (id, url,type) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.Downloaddocument(id, url,type);
    dispatch(downloaddocumentReducer(res));
    dispatch(updateLoader(false));
    if(type==="download"){

        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.DOWNLOADDOCUMENT_SUCCESS_MSG : _msg.DOWNLOADDOCUMENT_ERROR_MSG
        }));
    }
}


export const uploadDocumentsSlice = createSlice({
    name: "UploadDocuments",
    initialState: initalState,
    reducers: {
        uploadDocumentsStartReducer: (state, action) => {
            return { ...state, data: null, updatedata:null, message: '', id: null, code: null, oldApplicantName: null }
        },
        uploadDocumentsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data.message, updatedata:null, id: null, code: null, oldApplicantName: null };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null, updatedata:null, id: null, code: null, oldApplicantName: null }
            }
        }
    }
});
export const { uploadDocumentsReducer, uploadDocumentsStartReducer } = uploadDocumentsSlice.actions;
export const uploadDocumentsAsync = (id, data, isFormData) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.UploadDocuments(id, data, isFormData);
    dispatch(uploadDocumentsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.UPLOADDOCUMENT_SUCCESS_MSG : _msg.UPLOADDOCUMENT_ERROR_MSG
        }));
    }, 1000);
}


export const listNotesSlice = createSlice({
    name: "ListNotes",
    initialState: initalState,
    reducers: {
        listNotesStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        listNotesReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data, code: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { listNotesReducer, listNotesStartReducer } = listNotesSlice.actions;
export const listNotesAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.ListNotes(id);
    dispatch(listNotesReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {

    }, 0);
    // dispatch(updateSnackbar({
    //     type: res.status === 200 ? "success" : "error",
    //     message: res.status === 200 ? '' : res.data.message
    // }));
}


export const addNotesSlice = createSlice({
    name: "AddApplicantNotes",
    initialState: initalState,
    reducers: {
        addNotesStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        addNotesReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { addNotesReducer, addNotesStartReducer } = addNotesSlice.actions;
export const addNotesAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.AddNotes(data);
    dispatch(addNotesReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {

        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.ADDNOTE_SUCCESS_MSG : _msg.ADDNOTE_ERROR_MSG
        }));
    }, 0);
}

// edit notes
export const editNotesSlice = createSlice({
    name: "EditApplicantNotes",
    initialState: initalState,
    reducers: {
        editNotesStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        editNotesReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { editNotesReducer, editNotesStartReducer } = editNotesSlice.actions;
export const editNotesAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.EditNotes(data);
    dispatch(editNotesReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", 
        message: res.status === 200 ? _msg.EDITNOTE_SUCCESS_MSG : _msg.EDITNOTE_ERROR_MSG 
    }));
    }, 0);
}

export const getListApplicantsSlice = createSlice({
    name: "GetListApplicants",
    initialState: initalState,
    reducers: {
        getListApplicantsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        getListApplicantsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getListApplicantsReducer, getListApplicantsStartReducer } = getListApplicantsSlice.actions;
export const getListApplicantsAsync = (limit, offset) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetListApplicants(limit, offset);
    if(res.data?.data === undefined || res.data?.data.length === 0){
        res.data["data"] = null
    }
    dispatch(getListApplicantsReducer(res));
    dispatch(updateLoader(false));
}


export const deleteApplicantsSlice = createSlice({
    name: "DeleteApplicants",
    initialState: initalState,
    reducers: {
        deleteApplicantsStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        deleteApplicantsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { deleteApplicantsReducer, deleteApplicantsStartReducer } = deleteApplicantsSlice.actions;
export const deleteApplicantsAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.DeleteApplicants(data);
    dispatch(deleteApplicantsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.DELETEAPPLICANT_SUCCESS_MSG : _msg.DELETEAPPLICANT_ERROR_MSG
        }));
    }, 1000);
}


export const searchApplicantsWithoutPagingSlice = createSlice({
    name: "SearchApplicantsWithoutPaging",
    initialState: initalState,
    reducers: {
        searchApplicantsWithoutPagingStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        searchApplicantsWithoutPagingReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    let Data = action.payload.data?.applicants ? action.payload.data?.applicants : action.payload.data?.data ? action.payload.data?.data : action.payload.data;
                    // return { ...state, data: action.payload.data?.data, message: action.payload.message };
                    return { ...state, data: Data, message: action.payload.data?.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { searchApplicantsWithoutPagingReducer, searchApplicantsWithoutPagingStartReducer } = searchApplicantsWithoutPagingSlice.actions;
export const searchApplicantsWithoutPagingAsync = (object, url) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.SearchApplicantsWithoutPaging(object, url);
    dispatch(searchApplicantsWithoutPagingReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const searchApplicantsWithPagingSlice = createSlice({
    name: "SearchApplicantsWithPaging",
    initialState: initalState,
    reducers: {
        searchApplicantsWithPagingStartReducer: (state, action) => {
            return { ...state, data: null, code: null }
        },
        searchApplicantsWithPagingReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { searchApplicantsWithPagingReducer, searchApplicantsWithPagingStartReducer } = searchApplicantsWithPagingSlice.actions;
export const searchApplicantsWithPagingAsync = (limit, offset, keyword, url) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.SearchApplicantsWithPaging(limit, offset, keyword, url);
    dispatch(searchApplicantsWithPagingReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}

export const getApplicantsCommentsSlice = createSlice({
    name: "GetApplicantsComments",
    initialState: initalState,
    reducers: {
        getApplicantsCommentsStartReducer : (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getApplicantsCommentsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data.auditlogs, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, isloader: false }
            }
        }
    }
});
export const { getApplicantsCommentsReducer, getApplicantsCommentsStartReducer } = getApplicantsCommentsSlice.actions;
export const getApplicantsCommentsAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetApplicantsComments(data);
    dispatch(getApplicantsCommentsReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}

// for dashboard
export const getBarChartDataSlice = createSlice({
    name: "GetBarChartData",
    initialState: initalState,
    reducers: {
        getBarChartDataStartReducer : (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getBarChartDataReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data.data, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, isloader: false }
            }
        }
    }
});
export const { getBarChartDataReducer, getBarChartDataStartReducer } = getBarChartDataSlice.actions;
export const getBarChartDataAsync = (date) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetBarChartData(date);
    dispatch(getBarChartDataReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}

export const getPieChartDataSlice = createSlice({
    name: "GetPieChartData",
    initialState: initalState,
    reducers: {
        getPieChartDataStartReducer : (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getPieChartDataReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data.data, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, isloader: false }
            }
        }
    }
});
export const { getPieChartDataReducer, getPieChartDataStartReducer } = getPieChartDataSlice.actions;
export const getPieChartDataAsync = (date) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetPieChartData(date);
    dispatch(getPieChartDataReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}

export const getRequirementAssignedSlice = createSlice({
    name: "GetRequirementAssigned",
    initialState: initalState,
    reducers: {
        getRequirementAssignedStartReducer : (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getRequirementAssignedReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data.data, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, isloader: false }
            }
        }
    }
});
export const { getRequirementAssignedReducer, getRequirementAssignedStartReducer } = getRequirementAssignedSlice.actions;
export const getRequirementAssignedAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetRequirementAssigned(data);
    dispatch(getRequirementAssignedReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const getResumeCountSlice = createSlice({
    name: "GetResumeCount",
    initialState: initalState,
    reducers: {
        getResumeCountStartReducer : (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getResumeCountReducer: (state, action) => {
            if (action.payload?.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data.result, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload?.data.message, data: null, isloader: false }
            }
        }
    }
});
export const { getResumeCountReducer, getResumeCountStartReducer } = getResumeCountSlice.actions;
export const getResumeCountAsync = (Object) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetResumeCount(Object);
    dispatch(getResumeCountReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}