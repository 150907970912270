import React, { useState, useEffect, useRef } from "react";
import { MenuItem, Select, Button, Stack, IconButton } from "@mui/material";
import GetJobId from "../../../_utilities/GetJobId";
import { Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "./PostingsNotes.css";
import RightSideBar from "../../../components/Rightsidebar/RightSideBar";
import CkEditor from "../../../components/CkEditor/CkEditor";
import { getUserDetails } from '../../../_redux/CognitoSlice';
import { GetTimeZone } from '../../../_utilities/GetTimeZone';
import * as _ser from '../../../_redux/_services/JobPostingSlice';
import EditIcon from '@mui/icons-material/Edit';

const initalState = { data: null, message: '', isloader: true }

const PostingsNotes = () => {
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);
  const [isLoadDoc, setIsLoadDoc] = useState(false);
  const [user, setUser] = useState([]);
  const [noteState, setNoteState] = useState('');
  const [selectedNote, setSelectedNote] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isValidUser,setIsValidUser ] = useState(false);

  const dispatch = useDispatch();
  const getJobId = GetJobId();
 
  useEffect(() => {
    if (getJobId !== null) {
      Getdata();
    }
  }, [getJobId]);

  const userData = useSelector((state) => state.Getnotes.data);
  const userDataCode = useSelector((state) => state.Getnotes.code);

  useEffect(() => {
    if(userDataCode === 404){
      setIsValidUser(true)
    }
  }, [userDataCode]);

  const Getdata = async () => {
    const userInfo = await getUserDetails();
    setUserDetails(userInfo);
    dispatch(_ser.getnotesAsync(getJobId));
  };

  useEffect(() => {
    if (userData !== null) {
      let data = JSON.parse(JSON.stringify(userData))
      setUser(data);
      setIsLoadDoc(false);
      dispatch(_ser.getnotesStartReducer(initalState))
    }
  }, [userData])

  const onReceivePropsHandler = (pannel, load) => {
    setIsRightSidePannel(pannel);
    setIsLoadDoc(load);
    if (load === true) {
      Getdata();
    }
    var element = document.getElementsByTagName("body");
    element[0].style.overflow = "";
  };

  const notesHandler = (type, data) => {
    setIsRightSidePannel(true);
    setNoteState(type);
    setSelectedNote(type === "Create" ? null : data);
    var element = document.getElementsByTagName("body");
    element[0].style.overflow = "hidden";
  }

  return (
    <>
    {!isValidUser &&

      <div
        className="addnotessection"
        style={{ display: "flex", gap: "10px" }}
        onClick={() => { notesHandler("Create", null) }}
      >
        <img
          src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"}
          className="adddocumentplusicon"
          alt=""
        />
        <h4 className="addnotetext"> Notes </h4>
      </div>
    }
      <div>
        {user.length > 0 ? (
          <>
            {user.map((elem, i) => {
              return (
                <>
                  <div style={{ position: "relative" }}>
                    <div className="usericon">
                      {elem.noteType === "Call" ? (
                        <img src={process.env.PUBLIC_URL + "/assests/img/Notescall.svg"} alt="" />
                      ) : (
                        <img src={process.env.PUBLIC_URL + "/assests/img/Notesemail.svg"} alt="" />
                      )}
                    </div>
                    <div className="jobnote">
                      <b>{elem.firstName + ' ' + elem.lastName}</b>
                      <div style={{ maxWidth: "calc(100% - 150px)" }} dangerouslySetInnerHTML={{ __html: elem.notes }}></div>
                      <span className="createdDate">{GetTimeZone(elem.createdAt)}</span>
                    </div>
                    <div style={{ position: "absolute", top: "30px", right: "20px" }}>
                      {elem.userId === userDetails.userId ? (
                      <IconButton color="primary" onClick={() => { notesHandler("Update", elem) }} component="label">
                        <EditIcon style={{ color: "#8e8e8e" }} className="appediticon" />
                      </IconButton>
                         ) : ("")}
                    </div>
                  </div>
                  <Divider className="secline" />
                </>
              );
            })}
          </>
        ) : (
          <h1 style={{ fontWeight: 300, lineHeight: 1.2, textAlign: "center", margin: "60px 0", fontSize: "3rem" }}>No notes found</h1>
        )}
      </div>
      {/* popup */}
      <div className="contentSection">
        {isRightSidePannel ? (
          <RightSideBar
            componentData={
              <ComponentRenderData
                noteState={noteState}
                selectedNote={selectedNote}
                onReceivechildProps={onReceivePropsHandler}
              />
            }
            componentLayout={noteState === "Create" ? pannelAddNotesobj : pannelEditNotesobj}
            onReceiveProps={onReceivePropsHandler}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const ComponentRenderData = (props) => {
  const dispatch = useDispatch();
  const [noteType, setnoteType] = useState("Call");
  const [textValue, setTextValue] = useState(" ");
  const [disableSubmitBtn, setDisableSubmitBtn] = React.useState(false);
  const getJobId = GetJobId();
  const [showEditor, setShowEditor] = useState(false);

  const code = useSelector((state) => state.AddNotes.data);
  const updateCode = useSelector((state) => state.EditNotes.data);

  useEffect(() => {
    if (props.selectedNote !== null && props.noteState !== "Create") {
      setnoteType(props.selectedNote.noteType);
      setTextValue(props.selectedNote.notes);
      setShowEditor(true);
    } else {
      setnoteType("Call");
      setTextValue(" ");
      setShowEditor(true);
    }
  }, [props.noteState])

  useEffect(() => {
    console.log("textValue +++", textValue)
  }, [textValue])

  const HandleSubmit = async (event) => {
    setDisableSubmitBtn(true)
    event.preventDefault();
    const userInfo = await getUserDetails()
    let obj = {
      notes: textValue,
      noteType: noteType,
      moduleType: "jobopening",
      moduleId: getJobId,
      userId: userInfo.userId,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
    };
    if (props.noteState === "Create") {
      dispatch(_ser.addNotesAsync(obj)).then(() => {
        setDisableSubmitBtn(false)
      }).catch(() => {
        setDisableSubmitBtn(false)
      });
    } else {
      obj["id"] = props.selectedNote.id;
      dispatch(_ser.editNotesAsync(obj)).then(() => {
        setDisableSubmitBtn(false)
      }).catch(() => {
        setDisableSubmitBtn(false)
      });
    }
  };

  useEffect(() => {
    if (code === 200) {
      setTextValue("");
      handleReset();
      dispatch(_ser.addNotesStartReducer(initalState))
      props.onReceivechildProps(false, true)
    }
  }, [code])

  useEffect(() => {
    if (updateCode === 200) {
      setTextValue("");
      handleReset();
      dispatch(_ser.editNotesStartReducer(initalState))
      props.onReceivechildProps(false, true)
    }
  }, [updateCode])

  const handleChange = (value, type) => {
    setTextValue(value.data);
  };

  const handleChange1 = (event) => {
    setnoteType(event.target.value);
  };

  const formRef = useRef(null);

  const handleReset = () => {
    formRef.current.reset();
    setnoteType("Call");
    setTextValue("");
    props.onReceivechildProps(false, false);
  };


  return (
    <>
      <form ref={formRef}>
        <div className="type">
          Note Type
          <Select
            className="typeselectbutton"
            value={noteType}
            autoComplete="off"
            onChange={handleChange1}
          >
            <MenuItem value={"Call"}>Call</MenuItem>
            <MenuItem value={"Email"}>Email</MenuItem>
          </Select>
        </div>

        <div>
          <div id="jobDescForSMnew">
            {showEditor === true && (
              <CkEditor
                data={textValue}
                initRawData={textValue}
                autoComplete="off"
                name="note"
                isReadonly={false}
                onUpdatehandler={(update) => handleChange(update, "note")}
              />
            )}
          </div>
        </div>

        <div className="cancelsavebutton">
          <Stack spacing={2} direction="row">
            <Button
              className="notecancelbtn"
              variant="text"
              sx={{ color: "#FF0000" }}
              type="reset"
              onClick={handleReset}
            >
              Cancel
            </Button>
            <Button
              className="savebtn"
              variant="contained"
              type="submit"
              disabled={textValue.trim().length === 0 || disableSubmitBtn}
              onClick={HandleSubmit}
            >
              {props.noteState === "Create" ? "Save" : "Update"}
            </Button>
          </Stack>
        </div>
      </form>
    </>
  );
};

export default PostingsNotes;

const pannelAddNotesobj = {
  title: "Add Notes",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/addnotesimg.svg`,
};

const pannelEditNotesobj = {
  title: "Edit Notes",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/addnotesimg.svg`,
};