import { getApi, postApi, putApi, patchApi ,deleteApi } from "./_apihelper";


export const GetLatestjobcode = (data) => (
    getApi(process.env.REACT_APP_LATEST_JOBCODE, process.env.REACT_APP_UTILS)
)

export const GetCountries = () => (
    getApi(process.env.REACT_APP_GET_COUNTRIES, process.env.REACT_APP_UTILS)
)

export const GetStatebyCountry = (id) => (
    getApi(`${process.env.REACT_APP_GET_COUNTRIES}/${id}/states`, process.env.REACT_APP_UTILS)
)

export const GetClients = (data) => (
    getApi(process.env.REACT_APP_ALL_CLIENTS, process.env.REACT_APP_CLIENTS)  //process.env.REACT_APP_UTILS
)
    
export const GetManagerTeamlead = (keyword,id) => (
    getApi(`${process.env.REACT_APP_GET_CUSTOM_FEILDS}/${id}/getuserbyroles?keyword=${keyword}`, process.env.REACT_APP_USE_MANAGEMENT)
)


// Job Opening CURD OPS
export const Addjobpostings = (data, isFormData) => (
    postApi(process.env.REACT_APP_JOB_POSTINGS, data, process.env.REACT_APP_JOBOPENING, isFormData)
)

export const Editjobpostings = (id, data) => (
    putApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}`, data, process.env.REACT_APP_JOBOPENING)
)

export const Listjobpostings = (data) => (
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}?limit=${data.limit}&offset=${data.offset}`, process.env.REACT_APP_JOBOPENING)
)

export const Filterjobpostings = (data) => (
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}?keyword=${encodeURIComponent(data.keyword)}`, process.env.REACT_APP_JOBOPENING)
)

export const FilterjobpostingsWithPaging = (data) => (
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}?limit=${data.limit}&offset=${data.offset}&keyword=${encodeURIComponent(data.keyword)}`, process.env.REACT_APP_JOBOPENING)
)

export const Deletejobpostings = (data) => (
    deleteApi(process.env.REACT_APP_JOB_POSTINGS, data, process.env.REACT_APP_JOBOPENING)
)


export const Jobpostingsbyid = (id) => (
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}`, process.env.REACT_APP_JOBOPENING)
)

export const UploadFiles = (data) => (
    postApi(process.env.REACT_APP_UPLOAD_FLIES, {}, process.env.REACT_APP_JOBOPENING)
)

// Documents
export const Getdocuments = (id) => (
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}/document`, process.env.REACT_APP_JOBOPENING)
)

export const Downloaddocuments = (data) => (
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}/${data.id}/document`, process.env.REACT_APP_JOBOPENING)
)

export const Deletedocuments = (id, data) => (
    deleteApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}/document`, data, process.env.REACT_APP_JOBOPENING)
)

export const downloadFileFromS3 = (id, docUrl ,type) => (
    postApi(`${process.env.REACT_APP_JOB_POSTINGS}/documentdownload`, { path:docUrl, id:id, action:type }, process.env.REACT_APP_JOBOPENING)
)

export const UploadDocuments = (id, data, isFormData) => (
    postApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}/document`, data, process.env.REACT_APP_JOBOPENING, isFormData)
)

// Notes
export const AddNotes = (data) => (
    postApi(process.env.REACT_APP_NOTES, data, process.env.REACT_APP_JOBOPENING)
)

export const EditNotes = (data) => (
    putApi(`${process.env.REACT_APP_NOTES}/${data.id}`, data, process.env.REACT_APP_JOBOPENING)
)

export const Getnotes = (id) => (
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}/notes`, process.env.REACT_APP_JOBOPENING)
)

// Associated Applicants
export const GetAssociatedApplicants = (id) => (
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}/jobseeker`, process.env.REACT_APP_JOBOPENING)
)

export const DeleteAssociatedJobseeker = (id,applicantID, deleteIds) => (
    deleteApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}/jobseeker/${applicantID}`, deleteIds, process.env.REACT_APP_JOBOPENING)
)
        
export const SearchAffinda = (url) => (
    // getApi(`${process.env.REACT_APP_JOB_POSTINGS}/affinda/search?${url}&limit=${100}&offset=${1}`, process.env.REACT_APP_JOBOPENING)
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}/${url}/aimatched?&limit=${50}&offset=${1}`, process.env.REACT_APP_JOBOPENING)
    //as per rahul bro suggestion I add direct values in limit and offset
)

export const DeleteAssociatedJob = (jobId, obj, data) => (
    deleteApi(`${process.env.REACT_APP_JOB_POSTINGS}/${jobId}/jobseeker/${obj.id}`, data, process.env.REACT_APP_JOBOPENING)
)

export const AddAssociatedjobs = (jobId, obj) => (
    postApi(`${process.env.REACT_APP_JOB_POSTINGS}/${jobId}/jobseeker/${obj.id}`, obj, process.env.REACT_APP_JOBOPENING)
)

export const UpdateCandidateStatus = (id, data) => (
    patchApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}/jobseeker`, data, process.env.REACT_APP_JOBOPENING)
)

// vendor portal
export const GetQuestionsList = (id) => (
    getApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}/vendorportal`, process.env.REACT_APP_JOBOPENING)
)

export const AddQuestions = (data) => (
    postApi(process.env.REACT_APP_VENDOR_PORTAL, data, process.env.REACT_APP_JOBOPENING)
)

export const UpdateQuestions = (id, data) => (
    putApi(`${process.env.REACT_APP_VENDOR_PORTAL}/${id}`, data, process.env.REACT_APP_JOBOPENING)
)

export const RemoveQuestions = (id) => (
    deleteApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}`, {}, process.env.REACT_APP_JOBOPENING)
)


// not uses this
export const DeleteQuestions = (id) => (
    deleteApi(`${process.env.REACT_APP_JOB_POSTINGS}/${id}/vendorportal`, {}, process.env.REACT_APP_JOBOPENING)
)

// this for vendorsoursing
export const AddAnswers = (data) => (
    postApi(`${process.env.REACT_APP_VENDOR_PORTAL}/answers`, {}, process.env.REACT_APP_JOBOPENING)
)

//auditlog
export const GetPostingsComments = (data) => (
    getApi(`${process.env.REACT_APP_JOBOPENING_COMMENTS}?type=${data.type}&typeId=${data.typeId}`, process.env.REACT_APP_JOBOPENING)
)