import { configureStore } from "@reduxjs/toolkit";

import AuthSliceReducer from "./_redux/AuthSlice";
import LoaderSliceReducer from "./_redux/LoaderSlice";
import SeletedNextPathSliceReducer from "./_redux/SelectedNextPath";
import SeletedJobCodeReducer from "./_redux/SeletedJobCodeSlice";
import SnackbarSliceReducer from "./_redux/SnackbarSlice";

//service calls
import * as _applicantslice from "./_redux/_services/ApplicantsSlice";
import * as _clientslice from "./_redux/_services/ClientsSlice";
import * as _cloudslice from "./_redux/_services/CloudSourcingSlice";
import * as _postingslice from "./_redux/_services/JobPostingSlice";
import * as _userslice from "./_redux/_services/UserManagerSlice";


export const store = configureStore({
  reducer: {
    SeletedJobCode: SeletedJobCodeReducer,
    Loader: LoaderSliceReducer,
    Snackbar: SnackbarSliceReducer,
    Auth: AuthSliceReducer,
    SeletedNextPath: SeletedNextPathSliceReducer,
    //posting service calls 
    Addjobpostings: _postingslice.addjobpostingsSlice.reducer,
    Listjobpostings: _postingslice.listjobpostingsSlice.reducer,
    Deletejobpostings: _postingslice.deletejobpostingsSlice.reducer,
    Jobpostingsbyid: _postingslice.jobpostingsbyidSlice.reducer,
    GetCountries: _postingslice.getCountriesSlice.reducer,
    GetStatebyCountry: _postingslice.getStatebyCountrySlice.reducer,
    Editjobpostings: _postingslice.editjobpostingsSlice.reducer,
    Getdocuments: _postingslice.getdocumentsSlice.reducer,
    Deletedocuments: _postingslice.deletedocumentsSlice.reducer,
    DownloadFileFromS3: _postingslice.downloadFileFromS3Slice.reducer,
    UploadDocuments: _postingslice.uploadDocumentsSlice.reducer,
    GetAssociatedApplicants: _postingslice.getAssociatedApplicantsSlice.reducer,
    DeleteAssociatedJobseeker: _postingslice.deleteAssociatedJobseekerSlice.reducer,
    SearchAffinda: _postingslice.searchAffindaSlice.reducer,
    DeleteAssociatedJob: _postingslice.deleteAssociatedJobSlice.reducer,
    AddAssociatedjobs: _postingslice.addAssociatedjobsSlice.reducer,
    Getnotes: _postingslice.getnotesSlice.reducer,
    AddNotes: _postingslice.addNotesSlice.reducer,
    EditNotes: _postingslice.editNotesSlice.reducer,
    GetQuestionsList: _postingslice.getQuestionsListSlice.reducer,
    RemoveQuestions: _postingslice.removeQuestionsSlice.reducer,
    UpdateQuestions: _postingslice.updateQuestionsSlice.reducer,
    AddQuestions: _postingslice.addQuestionsSlice.reducer,
    GetManagerTeamlead : _postingslice.getManagerTeamleadSlice.reducer,
    GetPostingsComments : _postingslice.getPostingsCommentsSlice.reducer,
    UpdateCandidateStatus : _postingslice.updateCandidateStatusSlice.reducer,
    
    //applicants service calls 
    UploadResume: _applicantslice.uploadResumeSlice.reducer,
    DuplicateResume: _applicantslice.duplicateResumeSlice.reducer,
    AddApplicants: _applicantslice.addApplicantsSlice.reducer,
    ApplicantsById: _applicantslice.applicantsByIdSlice.reducer,
    EditApplicants: _applicantslice.editApplicantsSlice.reducer,
    GetApplicantJobs: _applicantslice.getApplicantJobsSlice.reducer,
    DeleteApplicantJobs: _applicantslice.deleteApplicantJobsSlice.reducer,
    GetMatchAssociatedJobs: _applicantslice.getMatchAssociatedJobsSlice.reducer,
    DeleteAssociatedjobs: _applicantslice.deleteAssociatedjobsSlice.reducer,
    AddAssociatedjobs: _applicantslice.addAssociatedjobsSlice.reducer,
    GetDocuments: _applicantslice.getDocumentsSlice.reducer,
    DeleteDocuments: _applicantslice.deleteDocumentsSlice.reducer,
    Downloaddocument: _applicantslice.downloaddocumentSlice.reducer,
    AddApplicantWithAssocated: _applicantslice.addApplicantWithAssocatedSlice.reducer,
    UploadDocuments: _applicantslice.uploadDocumentsSlice.reducer,
    ListNotes: _applicantslice.listNotesSlice.reducer,
    AddApplicantNotes: _applicantslice.addNotesSlice.reducer,
    EditApplicantNotes: _applicantslice.editNotesSlice.reducer,
    GetListApplicants: _applicantslice.getListApplicantsSlice.reducer,
    DeleteApplicants: _applicantslice.deleteApplicantsSlice.reducer,
    SearchApplicantsWithoutPaging: _applicantslice.searchApplicantsWithoutPagingSlice.reducer,
    SearchApplicantsWithPaging: _applicantslice.searchApplicantsWithPagingSlice.reducer,
    GetApplicantsComments : _applicantslice.getApplicantsCommentsSlice.reducer,
    GetBarChartData: _applicantslice.getBarChartDataSlice.reducer,
    GetPieChartData: _applicantslice.getPieChartDataSlice.reducer,
    GetRequirementAssigned: _applicantslice.getRequirementAssignedSlice.reducer,
    GetResumeCount: _applicantslice.getResumeCountSlice.reducer,
    BulkUploadResume: _applicantslice.BulkUploadResumeSlice.reducer,

    //clients service calls 
    FilterClients: _clientslice.filterClientsSlice.reducer,
    GetClients: _clientslice.getClientsSlice.reducer,
    AddClients: _clientslice.addClientsSlice.reducer,
    EditClients: _clientslice.editClientsSlice.reducer,
    GetClientswithPaging: _clientslice.getClientswithPagingSlice.reducer,
    DeleteClients: _clientslice.deleteClientsSlice.reducer,
    FilterClientsWithPaging: _clientslice.filterClientsWithPagingSlice.reducer,
    GetClientsComments : _clientslice.getClientsCommentsSlice.reducer,

    //User Manager Service Calls
    UserManager: _userslice.userManagerSlice.reducer,
    GetCustDetails: _userslice.getCustDetailsSlice.reducer,
    LoginPage: _userslice.loginPageSlice.reducer,
    CustomFields: _userslice.customFieldsSlice.reducer,
    GetEmailTemplates: _userslice.getEmailTemplatesSlice.reducer,
    GetEmailTemplatebyId: _userslice.getEmailTemplatebyIdSlice.reducer,
    SendEmail: _userslice.SendEmailSlice.reducer,
    BulkEmail: _userslice.BulkEmailSlice.reducer,

    // Cloud Sourcing Service Calls
    GetQuestionsAnswers: _cloudslice.getQuestionsAnswersSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});